import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

const ResetPassword = (props) => {
    let history = useHistory();
    const [code, setCode] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [invitationLoading, setInvitationLoading] = useState(false);

    useEffect(() => {
        document.title = 'ForgetPassword';
        if (props && props.match && props.match.params && props.match.params.username)
            setUsername(props.match.params.username);
        else
            return Swal.fire('Failed to load', 'Something went wrong.', 'error');
    }, []);

    const onSubmitClick = async () => {
        if (!username)
            return Swal.fire('Failed to reset', 'Something went wrong.', 'error');
        if (!password || !repeatPassword || !code)
            return Swal.fire('Failed to reset', 'Please fill fields.', 'error');
        if (password !== repeatPassword)
            return Swal.fire('Failed to reset', 'New password and Repeat New Password is not same.', 'error');
        setInvitationLoading(true);
        try {
            const submitForget = await Auth.forgotPasswordSubmit(username.toLowerCase(), code, password);
            if (submitForget)
            history.push({
                pathname: '/login',
                search: '?reset=success'
            });
        } catch(error) {
            setInvitationLoading(false);
            if (error && error.code == 'ExpiredCodeException')
                return Swal.fire('Failed to reset', 'Invalid code provided.', 'error');
            return Swal.fire('Failed to reset', 'Please fill fields.', 'error');
        }
    }

    return (
        <Container fluid className='tulu-login-page'>
            <Row>
                <Col md={12} className='login-page'>
                    <div className='custom-navbar'>
                        <div className='top-nav-wrap'>
                            <a className='w-nav-brand' href='/'>
                                <img src='/images/620eb3644f56c0aabe694e09_Tulu_logo.svg' className='image-brand' />
                            </a>
                        </div>
                    </div>

                    <div className='login-body'>
                        <div className='body-box'>
                            <h6 className='title'>Reset your password</h6>
                            <Form>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Control type="text" placeholder="Code" className='tulu-input'
                                        value={code}
                                        onChange={(e) => setCode(e.target.value)} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Control type="password" placeholder="New Password" className='tulu-input'
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Control type="password" placeholder="Repeat New Password" className='tulu-input'
                                        value={repeatPassword}
                                        onChange={(e) => setRepeatPassword(e.target.value)} />
                                </Form.Group>
                                <Button variant="primary" type="button" className='login-btn' disabled={invitationLoading}
                                    onClick={onSubmitClick}>
                                    {invitationLoading ? 'Wating...' : 'Log In'}
                                </Button>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default ResetPassword;