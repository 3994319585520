import React, { Fragment, useEffect, useState } from "react";
import { Card, Row, Col, Form } from 'react-bootstrap';
import lottieWeb from 'lottie-web';
import TimeLine from "./TimeLine";
import appConfig from '../../config/app.json'
import { timeDiff, timeDiffSec } from "../utils/utilities";

export const VideoJS = (props) => {
  const { streamData, type, commentData } = props;
  const [isLoading, setLoading] = useState(false);
  const [duration, setDuration] = useState();
  const [playing, setPlaying] = useState();
  const [timeupdate, setTimeUpdate] = useState();
  const url = streamData.type === appConfig.MEDIA_TYPE.STREAM ?
    appConfig.RTSP_SERVER.HOST + ((streamData.detail && streamData.detail.camera_data && streamData.detail.camera_data.stream) ? streamData.detail.camera_data.stream : streamData.stream_data.name) + appConfig.RTSP_SERVER.FORMAT :
    streamData.detail.url;
  
  var showOperateBtns = false;
  var forceNoOffscreen = true;
  var player = null;
  let videoContainer = null;

  function create() {
    player = new Jessibuca({
      container: videoContainer,
      hasAudio: false,
      videoBuffer: 3,
      isResize: true,
      text: "",
      loadingText: "",
      debug: false,
      showBandwidth: false,
      operateBtns: {
        fullscreen: showOperateBtns,
        screenshot: showOperateBtns,
        play: showOperateBtns,
        audio: showOperateBtns,
      },
      forceNoOffscreen: forceNoOffscreen
    });
    // player.onPause = () => {
    //   player.play(
    //     streamData.type === appConfig.MEDIA_TYPE.STREAM ?
    //       appConfig.RTSP_SERVER.HOST + streamData.stream_data.name + appConfig.RTSP_SERVER.FORMAT :
    //       appConfig.PLAY_SERVER.HOST + streamData.detail.url
    //   );
    // };
    player.onPlay = onPlaying;
    player.onFullscreen = msg => console.log('onFullscreen', msg);
    player.onMute = msg => console.log('onMute', msg);
  }

  useEffect(() => {
    if (!props.commentTimeChange) return;
    timeChange(props.commentTimeChange);
  }, [props.commentTimeChange]);

  useEffect(() => {
    lottieWeb.loadAnimation({
      container: document.getElementById('live_' + streamData.id),
      path: '/documents/connecting.json',
      renderer: 'svg',
      loop: true,
      autoplay: true,
      name: "Demo Animation",
    });

    if (streamData.type === appConfig.MEDIA_TYPE.STREAM) {
      create();
      if (player.hasLoaded()) {
        player.play(url);
      }
      else {
        player.on('load', function () {
          player.play(url);
        })
      }
      setInterval(() => {
          setDuration(timeDiffSec(streamData.detail.time_data.start_date));
          setTimeUpdate(timeDiffSec(streamData.detail.time_data.start_date));
      }, 1000);
    }

    return () => {
      if (videoContainer) {
        videoContainer.destroy();
        videoContainer.close();
      }
      if (player) {
        player.destroy();
        player.close();
        player = null;
      }
    };
  }, []);

  const onDurationChange = (event) => {
    if (!event || !event.target) return;
    setDuration(event.target.duration);
  }

  const onPlaying = () => {
    setPlaying(true);
    setLoading(true);
  }

  function onTimeUpdate(event) {
    if (!event || !event.target) return;
    if (parseInt(event.target.currentTime) >= parseFloat(streamData.detail.time_len)) {
      videoContainer.pause();
      setPlaying(false);
      setTimeUpdate(streamData.detail.time_len);
      return;
    }
    setPlaying(true);
    setTimeUpdate(event.target.currentTime);
  }

  function playerPlay() {
    if (parseInt(videoContainer.currentTime) >= parseFloat(streamData.detail.time_len)) {
      videoContainer.currentTime = 0;
    }
    videoContainer.play();
    setLoading(true);
  }

  function playerPause() {
    videoContainer.pause();
  }

  function timeChange(time) {
    if (!time) return;
    videoContainer.currentTime = time;
    setPlaying(true);
    videoContainer.play();
  }


  return (
    <Fragment>
      <div id="player-container" style={{ width: '100%', height: '100%', textAlign: 'center', backgroundColor: '#111' }}>
        {(streamData.type === appConfig.MEDIA_TYPE.STREAM) &&
          <div
            ref={ref => videoContainer = ref}
            id={type && type === "thumbnail" ? streamData.id : "video-container"}
            className={type && type === "thumbnail" ? "stream-video-container card-img" : "video-container"}
          >
          </div>
        }
        {(streamData.type !== appConfig.MEDIA_TYPE.STREAM) &&
          (type && type === "thumbnail" ? 
          <video
            ref={ref => videoContainer = ref}
            id={type && type === "thumbnail" ? streamData.id : "video-container"}
            className={type && type === "thumbnail" ? "stream-video-container card-img" : "video-container"}
            onLoadedData={onPlaying}
            onDurationChange={onDurationChange}
            onTimeUpdate={onTimeUpdate}
            muted
          >
            <source src={url+'#t=1.10'} type="video/mp4" autostart="false" />
          </video>: 
          <video
            ref={ref => videoContainer = ref}
            id={type && type === "thumbnail" ? streamData.id : "video-container"}
            className={type && type === "thumbnail" ? "stream-video-container card-img" : "video-container"}
            onLoadedData={onPlaying}
            onDurationChange={onDurationChange}
            onTimeUpdate={onTimeUpdate}
            autoPlay
            muted
          >
            <source src={url} type="video/mp4" />
          </video>)
        }
        <div id="controller" className="controller" style={{ display: type && type === "thumbnail" ? 'none' : 'none' }}>
          <div id="progress-contaniner" className="progress-common progress-contaniner">
            <div id="cachePts" className="progress-common cachePts"></div>
            <div id="progressPts" className="progress-common progressPts"></div>
          </div>
          <div id="operate-container" className="operate-container">
            <li id="playBar" className="playBtn">
              <a title="start">Start</a>
            </li>
            <span id="ptsLabel" className="ptsLabel">00:00:00/00:00:00</span>
            <div className="voice-div" style={{ display: 'none' }}>
              <span>
                <a id="muteBtn"
                  className="muteBtn">
                  <div>mute</div>
                </a>
              </span>
              <progress id="progressVoice" className="progressVoice" value="50" max="100"></progress>
            </div>
            <a id="fullScreenBtn" style={{ display: 'none' }}
              className="fullScreenBtn">
              <div>full</div>
            </a>
            <span id="showLabel" className="showLabel"></span>
          </div>
        </div>
      </div>
      {isLoading === true ? "" :
        <Col md={12} sm={12} xs={12} className={type && type === "thumbnail" ? "card-img-expand" : "video-img-expand"}>
          <div id={'live_' + streamData.id} className="connecting-indicator">
          </div>
          <Form.Label className="card-connecting-label">{streamData.type === appConfig.MEDIA_TYPE.STREAM ? "Connecting" : "Loading"}</Form.Label>
        </Col>}
      {(streamData && type && type === "thumbnail") ? null :
        <div className="timeline-container">
          <TimeLine
            playing={playing}
            duration={streamData && streamData.type === appConfig.MEDIA_TYPE.STREAM ? duration : streamData.detail.time_len}
            timeupdate={timeupdate}
            key={duration}
            pauseVideo={() => playerPause()}
            playVideo={() => playerPlay()}
            videoWidth={100}
            streamData={streamData}
            playerData={streamData}
            commentData={commentData}
            timeChange={(time) => timeChange(time)} />
        </div>}
    </Fragment>
  );
}

export default VideoJS;