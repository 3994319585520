import React, { useEffect, useState, useContext } from 'react'
import { Container, Row, Col, Navbar, Button, Form, Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Auth } from 'aws-amplify';
import config from '../../../config/aws.json';
import { Context } from '../../utils/state/Store';
import { GET } from '../../utils/api';


const Activate = () => {
  let history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [activationCode, setActivationCode] = useState('');
  const [hasRendered, setHasRendered] = useState(false);
  const [globalState, dispatch] = useContext(Context);

  useEffect(() => {
    document.title = 'Activate';
  }, []);

  useEffect(() => {
    setHasRendered(true);
    if (!hasRendered) {
      const user = localStorage.getItem('accountData');
      const owner = localStorage.getItem('owner');
      if (user && owner) {
        history.push("/home");
      }
    }
  }, [hasRendered]);


  const handleClick = async () => {
    setLoading(true);
    if (!activationCode || activationCode.length < 0) {
      Swal.fire('Pin Code', 'Please Enter Valid PIN Code', 'warning');
      setLoading(false);
      return;
    }

    GET(config.AWS_FUNCTION.DEVICE_ACTIVATE, config.AWS_API.DEVICE_ACTIVATE + activationCode).then((response) => {
      if (!response || !response.data)
        throw (new Error('error in response'));
      const resData = response.data;

      localStorage.setItem("accountData", JSON.stringify(resData));
      localStorage.setItem("owner", resData.id);
      signIn(resData.username, resData.password);
    }).catch((err) => {
      console.log(err)
      Swal.fire('Pin code Error', 'Camera with this PIN code does not exists', 'error');
      setLoading(false);
    });
  };

  async function signUp(username, password, email, phoneNumber) {
    try {
      const { user } = await Auth.signUp({
        username,
        password,
        attributes: {
          email,          // optional
          phone_number: phoneNumber,   // optional - E.164 number convention
          // 'custom:user_type': 'client'
        }
      });
    } catch (error) {
      console.log('error signing up:', error);
    }
    signIn(username, password);
  }

  function signIn(username, password) {
    Auth.signIn(username, password).then(() => {
      setLoading(false);
      dispatch({
        type: 'SET_AUTHENTICATION', payload: true
      });
      history.push("/home");
    }).catch((error) => {
      console.log('error signing in', error);
      Swal.fire('Pin Code Error', 'Camera with this PIN code does not exists', 'danger');
      setLoading(false);
    });
  }

  return (
    <Row className="app-body dark-mode">
      <Navbar bg="dark" className="top-nav-wrap navbar navbar-expand-lg navbar-dark bg-dark">
        <Col xs={4}>
          <Navbar.Brand href="/home" className=" mt-0 mb-0 text-white">
            <img
              src="/images/Tulu_logo.svg"
              width="60"
              height="30"
              className="d-inline-block align-top"
              alt="Tulu Logo"
            />
          </Navbar.Brand>
        </Col>
        <Col xs={8} className="text-end">
          <div className="mt-0 mb-0 text-white">
            <Form.Label onClick={() => setModalShow(true)} variant="link" className="top-nav-link w-nav-link">Cant Find Activation Code?</Form.Label>
          </div>
        </Col>
      </Navbar>
      <Container className="align-items-center justify-content-center activate-content">
        <Row>
          <Col>
            <img
              src="/images/Camera-logo.jpeg"
              className="activate-content-image"
              alt="Camera Logo"
            />
          </Col>
        </Row>
        <Row className="activate-content-row">
          <Form.Control type="text"
            className="w-input activate-content-pinCode"
            placeholder="Type Pin Code"
            onChange={(e) => setActivationCode(e.target.value)}
            value={activationCode}
          />
        </Row>
        <Row>
          <Button
            variant="primary"
            disabled={isLoading}
            onClick={!isLoading ? handleClick : null}
            className="activate-content-button"
          >
            {isLoading ? 'Connecting...' : 'Activate'}
          </Button>
        </Row>
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow}
          dialogClassName="activate-content-modal"
        >
          <Modal.Header>
            <Modal.Title className="activate-content-modal-title" id="contained-modal-title-vcenter">
              Camera Activation Code
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Your camera&#x27;s activation code is 6 digits and consists of both letters and numbers. The activation code
              can be found at the bottom of the &quot;Activate the Camera&quot; instruction card (included in the camera box.)
              If you do not have the activation instruction card please contact <a target="_blank"
                href="mailto:help@trytulu.com">help@trytulu.com</a> to reach a specialist for assistance. </p>
          </Modal.Body>
          <Modal.Footer className="text-center">
            <Button className="activate-content-modal-close" onClick={() => setModalShow(false)} variant="light">Close</Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </Row>
  )
}

export default Activate
