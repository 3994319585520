import React, { useEffect, useState, useContext } from 'react'
import { Row, Col } from 'react-bootstrap';
import ContentLoader from "react-content-loader";
import InfiniteScroll from 'react-infinite-scroller';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import Store, { Context } from '../../utils/state/Store';
import config from '../../../config/aws.json';
import typeConfig from '../../../config/app.json';
import Stream from '../../components/Stream';
import Media from '../../components/Media'
import { POST, GET } from '../../utils/api';

const Dashboard = () => {
  let history = useHistory();
  const [streams, setStreams] = useState([]);
  const [pageSize, setPageSize] = useState(12);
  const [hasMore, setHasMore] = useState(true);
  const [recordCount, setRecordCount] = useState(0);
  const [noCamera, setNoCamera] = useState(false);
  const [noRecord, setNoRecord] = useState(false);
  const [globalState, dispatch] = useContext(Context);

  useEffect(() => {
    document.title = 'Dashboard';
    getStreams(1);
  }, []);

  useEffect(() => {
    const socketMessage = globalState.MESSAGE;
    if (!socketMessage) return;
    switch (socketMessage.data) {
      case 'stream_update':
        getStreams(1);
        break;
      case 'media_update':
        getStreams(1);
        break;
      default:
        break;
    }
  }, [globalState.MESSAGE]);

  const getStreams = (value) => {
    if (recordCount > 0 && recordCount < value + pageSize && streams && streams.length > 0) return;
    var clientId = localStorage.getItem('owner');
    const params = "?client_id=" + clientId;
    GET(config.AWS_FUNCTION.GET_CLIENT_MEDIA, config.AWS_API.GET_CLIENT_MEDIA + params)
      .then(response => {
          if (!response || response.stream_count === "0") {
            setNoCamera(true);
          }
          if (!response || response.record_count === "0") {
            setNoRecord(true);
          }
          let lastStream = [];
          response.streams && response.streams.length > 0 && response.streams.map((item)=>{
            item.type = typeConfig.MEDIA_TYPE.STREAM;
            lastStream.push(item)
            setNoCamera(false);
          });
          response.records && response.records.length > 0 && response.records.map((item)=>{
            item.type = typeConfig.MEDIA_TYPE.RECORD;
            lastStream.push(item)
            setNoRecord(false);
          });
          setStreams(lastStream);
          setRecordCount(parseInt(response.stream_count)+parseInt(response.record_count));
          setHasMore(false)
      })
      .catch(err => {
        Swal.fire('Data Loading Error', 'Error in data loading', 'error');
      });
  }

  const getSkeleton = () => {
    return [...Array(8)].map((skel) => {
      return (
        <ContentLoader
          key={skel}
          speed={3}
          viewBox="0 0 400 400"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          className="video-card-video-wrap"
        >
          <rect x="0" y="0" rx="2" ry="2" width="400" height="260" />
          <rect x="0" y="274" rx="2" ry="2" width="300" height="14" />
          <rect x="0" y="298" rx="2" ry="2" width="220" height="10" />
          <rect x="0" y="318" rx="2" ry="2" width="220" height="10" />
        </ContentLoader>
      )
    });
  }

  return (
    <>
      {noCamera === true ?
        <div className="w-layout-grid activity-grid">
          <div className="video-card-empty">
            <div className="video-card-thumbnail-wrap-empty">
              <div className="no-cameras-image">
                <img src="/images/no-camera.png" loading="lazy" sizes="(max-width: 479px) 46vw, (max-width: 767px) 44vw, (max-width: 991px) 21vw, 12vw" alt="" className="image-10" />
              </div>
            </div>
            <div className="no-cameras">No active cameras</div>
            <div className="please-turn-on">Please turn on camera to start streaming</div>
          </div>
          {streams && streams.length > 0 && streams.map((stream, index) => {
            return stream.type == typeConfig.MEDIA_TYPE.RECORD &&
              <Media key={'item_' + index} mediaData={stream} keyIndex={'item_' + index} />
          })}
          {streams && streams.length > 0 && streams.length < 8 && [...Array(7 - parseInt(streams.length))].map((empty, index) => {
            return (
              <div className="video-card-empty">
                <div className="video-card-thumbnail-wrap-empty">
                  <div className="no-cameras-image">
                  </div>
                </div>
                <div className="no-cameras"></div>
                <div className="please-turn-on"></div>
              </div>
            )
          })}
        </div>
        :
        <InfiniteScroll
          pageStart={0}
          loadMore={(value) => getStreams(value)}
          hasMore={hasMore}
          element="div"
          className="activity-grid"
          loader={getSkeleton()}
          initialLoad={false}
          threshold={350}
        >
          {streams && streams.length > 0 && streams.map((stream, index) => {
            return stream.type == typeConfig.MEDIA_TYPE.STREAM ?
              <Stream ream key={'item_' + index} streamData={stream} keyIndex={'item_' + index} />
              :
              <Media key={'item_' + index} mediaData={stream} keyIndex={'item_' + index} />
          })}
          {streams && streams.length > 0 && streams.length < 8 && [...Array(8 - parseInt(streams.length))].map((empty, index)=>{
            return (
            <div className="video-card-empty">
              <div className="video-card-thumbnail-wrap-empty">
                <div className="no-cameras-image">
                  
                </div>
              </div>
              <div className="no-cameras"></div>
              <div className="please-turn-on"></div>
            </div>
            )
          })}
        </InfiniteScroll>}
    </>
  )
}

export default Dashboard;
