import { Auth } from 'aws-amplify';
import moment from 'moment';

export const isLogin = () => {

  const account = localStorage.getItem('accountData');
  const owner = localStorage.getItem('owner');
  return Auth.currentAuthenticatedUser()
    .then((user) => {
      if (user && account && owner) {
        return true;
      }
      throw (new Error('Not authenticated'));
    }).catch((err) => {
      console.log('Error in login: ', err);
      Auth.signOut();
      return false;
    });
};

export function timeDiff(adsDate) {
  const now = moment(new Date());
  const startDate = moment(adsDate);
  const diff = now.diff(startDate);
  const diffDuration = moment.duration(diff);
  if (diffDuration.hours() > 0) {
    return diffDuration.hours().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) + ":" +
      diffDuration.minutes().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) + ":" +
      diffDuration.seconds().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
  } else {
    return diffDuration.minutes().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) + ":" +
      diffDuration.seconds().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
  }
};

export function timeDiffSec(adsDate) {
  const now = moment(new Date());
  const startDate = moment(adsDate);
  const diff = now.diff(startDate, 'seconds');
  return diff;
};

export function videoTimeCalculator(videoData, isMobile) {
  switch (videoData.videoType) {
    case "vod":
      let videoDuration = parseInt(videoData.meta.durationMs);
      let videoDurationSeconds = parseFloat(videoDuration / 1000);
      let videoFrameCount = Math.ceil(videoDurationSeconds / (isMobile ? 6: 12));
      if (videoDurationSeconds % (isMobile ? 6 :12) === 0) {
        videoFrameCount++;
      }
      let timeWidth =((videoDurationSeconds) / ((videoFrameCount) * (isMobile ? 6 :12)))* 100;
      const returnValue = {
        ms: videoDuration,
        second: videoDurationSeconds,
        timeFormat: moment.utc(videoDurationSeconds * 1000).format("HH:mm:ss"),
        frameCount:videoFrameCount,
        timeLineWidth: timeWidth
      }
      return returnValue;
      break;
    case "live":
      let videoDurationLive = parseInt(videoData.meta.durationMs);
      let videoDurationSecondsLive = parseFloat(videoDuration / 1000);
      let videoFrameCountLive = Math.ceil(videoDurationSecondsLive / (isMobile ? 6 :12));
      if (videoDurationSecondsLive % (isMobile ? 6 :12) === 0) {
        videoFrameCountLive++;
      }
      let timeWidthLive =((videoDurationSecondsLive) / ((videoFrameCountLive) * (isMobile ? 6 :12)))* 100;
      const returnValueLive = {
        ms: videoDurationLive,
        second: videoDurationSecondsLive,
        timeFormat: moment.utc(videoDurationSecondsLive * 1000).format("HH:mm:ss"),
        frameCount:videoFrameCountLive,
        timeLineWidth: timeWidthLive - 2
      }
      return returnValueLive;
      break;
    default:
      return null
      break;
  }
}

export function validateEmail(email) {
  let tester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-?\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  if (!email) return false;
  if (email.length > 254) return false;

  let valid = tester.test(email);
  if (!valid) return false;

  let parts = email.split("@");
  if (parts[0].length > 64) return false;

  let domainParts = parts[1].split(".");
  if (domainParts.some(function (part) { return part.length > 63; })) return false;

  return true;
}


