import React, { useEffect, Fragment, useState, forwardRef, useImperativeHandle } from 'react';
import { Button, ButtonGroup, Col, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { BiSave } from "react-icons/bi";
import { MdOutlineCancel } from "react-icons/md";
import ContentLoader from 'react-content-loader'
import { PUT, POST, GET, DELETE } from '../../../utils/api';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

const PlacesTable = forwardRef((props, ref) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [newObj, setNewObj] = useState({ address: "" });
    const [datas, setDatas] = useState([]);
    const [changedFileds, setChangedFileds] = useState([]);

    useEffect(() => {
        fetchPlaces();
    }, []);

    useEffect(() => {
        refreshPage();
    }, [props.clientId]);

    const refreshPage = () => {
        setDatas([]);
        setNewObj({ address: "" });
        setIsAdd(false);
        setChangedFileds([]);
        fetchPlaces();
    }

    const fetchPlaces = () => {
        setIsLoading(true);
        let url = `/places/admin/get?clientId=` + props.clientId;
        GET('streamPlacesREST', url).then(response => {
            if (response && response.result) {
                const result = response.result;
                setDatas(result);
            }
        }).catch(() => {
            Swal.fire('Fetch Data Error', 'Oops, Error occurred while fetching places.', 'error');
        }).finally(() => setIsLoading(false))
    }

    const onEditColumn = (id, name) => {
        let result = JSON.parse(JSON.stringify(datas));
        result = result.map((res) => {
            let keys = Object.keys(res);
            keys.forEach(key => {
                if (key.includes('Edit'))
                    delete res[key];
            });

            return res;
        })

        let data = result.find(z => z.id === id);
        if (!data) return;
        data[name + "Edit"] = true;
        setDatas(result);
    }

    const changedRow = (id) => {
        let result = JSON.parse(JSON.stringify(changedFileds));
        if (!result.find(z => z === id)) {
            result.push(id);
            setChangedFileds(result);
        }
    }

    const onBlur = (id, objName) => {
        let result = JSON.parse(JSON.stringify(datas));
        let data = result.find(z => z.id === id);
        if (!data) return;
        delete data[objName];
        setDatas(result);
    }

    const onNewFiledsChnage = (e) => {
        const target = e.target.name;
        let value = e.target.value

        let result = JSON.parse(JSON.stringify(newObj));
        result[target] = value;
        setNewObj(result);
    }

    const validateNew = () => {
        if (!newObj || !newObj.name || !newObj.address || !newObj.lat || !newObj.long || !newObj.companyName ||
            !newObj.contactName || !newObj.contactPhone) return false;
        return true;
    }

    const onSaveChange = () => {
        let result = [];
        if (!props.clientId) return;
        changedFileds.forEach(id => {
            let findChanges = datas.find(z => z.id === id);
            if (findChanges)
                result.push({
                    id: findChanges.id,
                    clientId: props.clientId,
                    name: findChanges.name,
                    address: {
                        address: findChanges.address,
                        lat: findChanges.lat,
                        long: findChanges.long,
                        address_complement: findChanges.address_complement,
                        zip: findChanges.zip,
                        country: findChanges.country,
                        city: findChanges.city,
                        state: findChanges.state
                    },
                    companyName: findChanges.companyName,
                    contactName: findChanges.contactName,
                    detail: {
                        contact_phone: findChanges.contactPhone
                    }
                })
        });

        let promises = [];
        if (isAdd && !validateNew())
            return Swal.fire('Progress failed', 'Please fill all fields.', 'error');

        if (isAdd)
            promises.push(addNew());
        if (result && result.length)
            promises.push(updateRows(result));
        setIsLoading(true);
        const allResults = Promise.all(promises).then(() => {
            Swal.fire('Successfully add/update', 'People successfully added/udpated.', 'success');
        }).catch((err) => {
            Swal.fire('Progress failed', 'Oops, Error occurred while adding/updating People.', 'error');
        }).finally(() => {
            refreshPage();
            setIsLoading(false);
        });
    }

    const addNew = () => {
        const params = {
            body: {
                clientId: props.clientId,
                name: newObj.name,
                address: {
                    address: newObj.address,
                    lat: newObj.lat,
                    long: newObj.long,
                    address_complement: newObj.address_complement,
                    zip: newObj.zip,
                    country: newObj.country,
                    city: newObj.city,
                    state: newObj.state
                },
                companyName: newObj.companyName,
                contactName: newObj.contactName,
                detail: {
                    contact_phone: newObj.contactPhone
                }
            }
        }
        return POST('streamPlacesREST', '/places', params);
    }

    const updateRows = (rows) => {
        const params = { body: { places: rows } }
        return PUT('streamPlacesREST', '/places/bulk', params);
    }

    const handleNewMapChange = (address) => {
        let result = JSON.parse(JSON.stringify(newObj));
        result["address"] = address;
        setNewObj(result);
    };

    const handleNewMapSelect = (address) => {
        let result = JSON.parse(JSON.stringify(newObj));
        let completedAddress;
        geocodeByAddress(address).then((results) => {
            completedAddress = results[0];
            return getLatLng(results[0])
        }).then((latLng) => {
            result.lat = latLng.lat;
            result.long = latLng.lng;
            result.address = address;
            result.address_complement = address,
                result.zip = '',
                result.country = '',
                result.city = '',
                result.state = '';
            for (const component of completedAddress.address_components) {
                if (component.types.includes('postal_code'))
                    result.zip = component.long_name;

                if (component.types.includes('administrative_area_level_1'))
                    result.state = component.long_name;

                if (component.types.includes('country'))
                    result.country = component.long_name;

                if (component.types.includes('locality') || component.types.includes('sublocality'))
                    result.city = component.long_name;
            }
            setNewObj(result);
        }).catch(error => console.error('Error', error));
    };

    const handleMapChange = (address, id) => {
        let result = JSON.parse(JSON.stringify(datas));
        let data = result.find(z => z.id === id);
        if (!data) return;
        data.address = address;
        setDatas(result);
    };

    const handleMapSelect = (address, id) => {
        let result = JSON.parse(JSON.stringify(datas));
        let data = result.find(z => z.id === id);
        if (!data) return;

        let completedAddress;
        geocodeByAddress(address)
            .then((results) => {
                completedAddress = results[0];
                return getLatLng(results[0])
            }).then((latLng) => {
                data.lat = latLng.lat;
                data.long = latLng.lng;
                data.address = address;
                result.address_complement = address,
                    result.zip = '',
                    result.country = '',
                    result.city = '',
                    result.state = '';
                for (const component of completedAddress.address_components) {
                    if (component.types.includes('postal_code'))
                        result.zip = component.long_name;

                    if (component.types.includes('administrative_area_level_1'))
                        result.state = component.long_name;

                    if (component.types.includes('country'))
                        result.country = component.long_name;

                    if (component.types.includes('locality') || component.types.includes('sublocality'))
                        result.city = component.long_name;
                }
                setDatas(result);
                changedRow(id)
            }).catch(error => console.error('Error', error));
    };

    const onEditFieldChange = (e, id) => {
        let result = JSON.parse(JSON.stringify(datas));
        let data = result.find(z => z.id === id);
        const value = e.target.value;
        const target = e.target.name;
        if (!data) return;
        data[target] = value;
        setDatas(result);
        changedRow(id)
    }

    const onRowSelcted = (e, id) => {
        let result = JSON.parse(JSON.stringify(datas));
        let data = result.find(z => z.id === id);
        if (!data) return;
        data.selected = !data.selected;
        setDatas(result);
    }

    const onRowSelctedAll = (value) => {
        let result = JSON.parse(JSON.stringify(datas));
        result.forEach(res => {
            res.selected = value;
        });
        setDatas(result);
    }

    const onDeleteSelected = () => {
        if (!datas || !datas.find(z => z.selected)) return;
        let ids = [];
        datas.forEach(dt => {
            if (dt.selected && dt.id)
                ids.push(dt.id)
        });

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setIsLoading(true)
                const params = { body: { ids, clientId: props.clientId } }
                DELETE('streamPlacesREST', '/places', params).then(() => {
                    refreshPage();
                    Swal.fire('Deleted!', 'Your selected rows has been deleted.', 'success');
                }).catch(() => {
                    Swal.fire('Fetch Data Error', 'Oops, Error occurred while fetching places.', 'error');
                }).finally(() => setIsLoading(false))
            }
        })
    }

    return (
        <Fragment>
            <Row>
                <Col md={12}>
                    <ButtonGroup size="sm" style={{ float: "right", marginBottom: 50 }}>
                        <Button disabled={isLoading} onClick={() => {
                            if (datas && datas.every(z => z.selected))
                                onRowSelctedAll(false);
                            else
                                onRowSelctedAll(true);
                        }} variant="light tulu-btn-light" style={{
                            marginRight:
                                datas && datas.find(z => z.selected) ? 20 : 0
                        }}>
                            {datas && datas.every(z => z.selected) ?
                                'Deselect All' : 'Select all'}
                        </Button>
                        {datas && datas.find(z => z.selected) ? <Button disabled={isLoading} variant='light tulu-btn-light' onClick={onDeleteSelected}>Delete Selected</Button> : ""}
                        {((changedFileds && changedFileds.length) || isAdd) ?
                            <Fragment>
                                <Button disabled={isLoading} style={{ marginLeft: 20, marginRight: 20 }} variant='warning' onClick={refreshPage}>Discard Changes</Button>
                                <Button disabled={isLoading} variant='primary tulu-primary' onClick={onSaveChange}>Save Changes</Button>
                            </Fragment>
                            : ""}
                    </ButtonGroup>
                </Col>
                <Col md={12}>
                    <div className='className'>
                        <table className="responsive">
                            <thead>
                                <tr>
                                    <th width="10px"></th>
                                    <th width="10px">#</th>
                                    <th>Name</th>
                                    <th>Address</th>
                                    <th>Contact Name</th>
                                    <th>Contact Phone</th>
                                    <th>Company Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isLoading && !isAdd &&
                                    <tr>
                                        <td></td>
                                        <td className='add-icon'>
                                            +
                                        </td>
                                        <td>
                                            <button className='btn btn-link btn-add' onClick={() => setIsAdd(true)}>Add a place</button>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                }
                                {!isLoading && isAdd &&
                                    <tr className='active'>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <input className='form-control' placeholder='Enter Name' value={newObj.name}
                                                onChange={(e) => onNewFiledsChnage(e)} name='name' />
                                        </td>
                                        <td>
                                            <PlacesAutocomplete
                                                value={newObj.address}
                                                onChange={(e) => handleNewMapChange(e)}
                                                onSelect={(e) => handleNewMapSelect(e)}
                                                searchOptions={{ componentRestrictions: { country: ["aus", "usa"] } }}>
                                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                    <div className="w-100 h-100" style={{ position: "relative" }}>
                                                        <input
                                                            {...getInputProps({
                                                                placeholder: 'Search Places ...',
                                                                className: 'form-control',
                                                            })}
                                                        />
                                                        <div className={"suggestions-box " + (suggestions.length > 0 ? "suggestions" : "")}>
                                                            {loading && <div>Loading...</div>}
                                                            {suggestions.map(suggestion => {
                                                                const className = 'suggestion';
                                                                // inline style for demonstration purpose
                                                                const style = suggestion.active
                                                                    ? { backgroundColor: '#f5f5f5', cursor: 'pointer', fontSize: 'small' }
                                                                    : { backgroundColor: '#ffffff', cursor: 'pointer', fontSize: 'small' };
                                                                return (
                                                                    <div
                                                                        {...getSuggestionItemProps(suggestion, {
                                                                            className,
                                                                            style,
                                                                        })}>
                                                                        <span>{suggestion.description}</span>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                )}
                                            </PlacesAutocomplete>
                                        </td>
                                        <td>
                                            <input className='form-control' placeholder='Enter Contact Name' value={newObj.contactName}
                                                onChange={(e) => onNewFiledsChnage(e)} name='contactName' />
                                        </td>
                                        <td>
                                            <input className='form-control' placeholder='Enter Contact Phone' value={newObj.contactPhone}
                                                onChange={(e) => onNewFiledsChnage(e)} name='contactPhone' />
                                        </td>
                                        <td>
                                            <input className='form-control' placeholder='Enter Company Name' value={newObj.companyName}
                                                onChange={(e) => onNewFiledsChnage(e)} name='companyName' />
                                        </td>
                                    </tr>}
                                {isLoading &&
                                    <tr style={{ backgroundColor: '#ffffff' }}>
                                        {[...Array(7)].map((item, index) => {
                                            return (<td style={{ backgroundColor: '#ffffff' }} key={(index + 1).toString()}>
                                                <ContentLoader viewBox="0 0 20 3">
                                                    <rect x="0" y="0" rx="0" ry="0" width="20" height="3" />
                                                </ContentLoader>
                                            </td>)
                                        })}
                                    </tr>
                                }
                                {!isLoading && datas && datas.map((dt, index) => {
                                    return (
                                        <tr key={index + 1}>
                                            <td>
                                                <input type={'checkbox'} checked={dt.selected} onChange={(e) => onRowSelcted(e, dt.id)} />
                                            </td>
                                            <td>
                                                <span className='row-num' >{index + 1}</span>
                                            </td>
                                            <td onClick={() => onEditColumn(dt.id, 'name')}
                                                className={dt.nameEdit ? 'active' : ''}>
                                                {dt.nameEdit ?
                                                    <div className='d-flex align-items-center'>
                                                        <input className='form-control' name='name' placeholder='Name' value={dt.name}
                                                            onChange={(e) => onEditFieldChange(e, dt.id)}
                                                            onBlur={() => onBlur(dt.id, 'nameEdit')} />
                                                    </div> :
                                                    <span>{dt.name}</span>
                                                }
                                            </td>
                                            <td style={{maxWidth: '30rem', width: '30rem', whiteSpace: 'pre-wrap'}} onClick={() => onEditColumn(dt.id, 'address')}
                                                className={dt.addressEdit ? 'active' : ''}>
                                                {dt.addressEdit ?
                                                    <PlacesAutocomplete
                                                        value={dt.address}
                                                        onChange={(e) => handleMapChange(e, dt.id)}
                                                        onSelect={(e) => handleMapSelect(e, dt.id)}
                                                        searchOptions={{ componentRestrictions: { country: ["aus", "usa"] } }}>
                                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                            <div className="w-100 h-100" style={{ position: "relative" }}>
                                                                <input
                                                                    {...getInputProps({
                                                                        placeholder: 'Search Places ...',
                                                                        className: 'form-control',
                                                                    })}
                                                                />
                                                                <div className={"suggestions-box " + (suggestions.length > 0 ? "suggestions" : "")}>
                                                                    {loading && <div>Loading...</div>}
                                                                    {suggestions.map(suggestion => {
                                                                        const className = 'suggestion';
                                                                        // inline style for demonstration purpose
                                                                        const style = suggestion.active
                                                                            ? { backgroundColor: '#f5f5f5', cursor: 'pointer', fontSize: 'small' }
                                                                            : { backgroundColor: '#ffffff', cursor: 'pointer', fontSize: 'small' };
                                                                        return (
                                                                            <div
                                                                                {...getSuggestionItemProps(suggestion, {
                                                                                    className,
                                                                                    style,
                                                                                })}>
                                                                                <span>{suggestion.description}</span>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </PlacesAutocomplete>
                                                    :
                                                    <span style={{lineHeight: '2rem'}}>{dt.address}</span>
                                                }
                                            </td>
                                            <td onClick={() => onEditColumn(dt.id, 'contactName')}
                                                className={dt.contactNameEdit ? 'active' : ''}>
                                                {dt.contactNameEdit ?
                                                    <div className='d-flex align-items-center'>
                                                        <input className='form-control' name='contactName' placeholder='Contact Name' value={dt.contactName}
                                                            onChange={(e) => onEditFieldChange(e, dt.id)}
                                                            onBlur={() => onBlur(dt.id, 'contactNameEdit')} />
                                                    </div>
                                                    :
                                                    <span>{dt.contactName}</span>
                                                }
                                            </td>
                                            <td onClick={() => onEditColumn(dt.id, 'contactPhone')}
                                                className={dt.contactPhoneEdit ? 'active' : ''}>
                                                {dt.contactPhoneEdit ?
                                                    <div className='d-flex align-items-center'>
                                                        <input className='form-control' name='contactPhone' placeholder='Contact Name' value={dt.contactPhone}
                                                            onChange={(e) => onEditFieldChange(e, dt.id)}
                                                            onBlur={() => onBlur(dt.id, 'contactPhoneEdit')} />
                                                    </div>
                                                    :
                                                    <span>{dt.contactPhone}</span>
                                                }
                                            </td>
                                            <td onClick={() => onEditColumn(dt.id, 'companyName')}
                                                className={dt.companyNameEdit ? 'active' : ''}>
                                                {dt.companyNameEdit ?
                                                    <div className='d-flex align-items-center'>
                                                        <input className='form-control' name='companyName' placeholder='Contact Name' value={dt.companyName}
                                                            onChange={(e) => onEditFieldChange(e, dt.id)}
                                                            onBlur={() => onBlur(dt.id, 'companyNameEdit')} />
                                                    </div>
                                                    :
                                                    <span>{dt.companyName}</span>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
        </Fragment>
    )
})

export default PlacesTable;