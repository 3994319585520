import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PublicLayout from './PublicLayout';
import { isLogin } from '../utils/utilities';

const PublicRoute = ({component: Component, title, theme, darkMode, restricted, ...rest}) => {
    return (
        <Route {...rest} render={props => (
            <PublicLayout title={title} theme={theme} darkMode={darkMode}>
                <Component {...props} />
            </PublicLayout>
        )} />
    );
};

export default PublicRoute; 