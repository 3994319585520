import { Auth } from 'aws-amplify';
import React, { useEffect, useState, useContext } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Context } from '../../utils/state/Store';

const Login = (props) => {
    let history = useHistory();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [isResetSuccess, setIsResetSuccess] = useState(false);
    const [password, setPassword] = useState('');
    const [globalState, dispatch] = useContext(Context);

    useEffect(() => {
        document.title = 'Login';
        if (props && props.location && props.location && props.location.search
            && props.location.search.includes('reset=success')) {
                setIsResetSuccess(true);
        }
        const user = localStorage.getItem('accountData');
        const owner = localStorage.getItem('owner');
        if (user && owner) {
            dispatch({
                type: 'SET_AUTHENTICATION', payload: true
            });
            history.push("/home");
        }
    }, []);

    async function signIn(username, password) {
        if (!username || !password)
            return Swal.fire('Failed to login', 'Please fill the fields', 'error');
        if (loading) return;
        setIsResetSuccess(false);
        setLoading(true);
        try {
            const user = await Auth.signIn(username.toLowerCase(), password);
            const accountData = {
                id : user.attributes['custom:clientid'],
                type : user.attributes['custom:type']
            }

            localStorage.setItem("accountData", JSON.stringify(accountData));
            localStorage.setItem("owner", accountData.id);
            dispatch({
                type: 'SET_AUTHENTICATION', payload: true
              });
            setLoading(false);
            if (user)
                history.push("/home");
        } catch (error) {
            setLoading(false);
            Swal.fire('Failed to login', 'Username or password is wrong', 'error');
        }
    }

    return (
        <Container fluid className='tulu-login-page'>
            <Row>
                <Col md={12} className='login-page'>
                    {isResetSuccess ?
                        <div className='success-header'>
                            Password successfully reset! Please log in with your new password.
                        </div> : ''
                    }
                    <div className='custom-navbar'>
                        <div className='top-nav-wrap'>
                            <a className='w-nav-brand' href='/'>
                                <img src='/images/620eb3644f56c0aabe694e09_Tulu_logo.svg' className='image-brand' />
                            </a>
                        </div>
                    </div>

                    <div className='login-body'>
                        <div className='body-box'>
                            <h6 className='title'>Log Into My Account</h6>
                            <Form>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control type="email" placeholder="Email" className='tulu-input'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Control type="password" placeholder="Password" className='tulu-input'
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)} />
                                </Form.Group>
                                <Button variant="primary" type="button" className='login-btn'
                                    onClick={() => { signIn(email, password) }} disabled={loading}>
                                    {loading ? 'Waiting...' : 'Submit'}
                                </Button>
                            </Form>
                            <div className='forget-password-link' onClick={() => history.push("/forget-password")}>Forgot Password?</div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Login;