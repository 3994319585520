import React, { useEffect, Fragment, useState, forwardRef, useImperativeHandle } from 'react';
import { Button, ButtonGroup, Col, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import ContentLoader from 'react-content-loader';
import { PUT, POST, GET, DELETE } from '../../../utils/api';
import { validateEmail } from '../../../utils/utilities';

const PeopleTable = forwardRef((props, ref) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [newObj, setNewObj] = useState({});
    const [datas, setDatas] = useState([]);
    const [changedFileds, setChangedFileds] = useState([]);
    const [errors, setErrors] = useState([]);
    const [user, setUser] = useState(null);

    useEffect(() => {
        let usr = localStorage.getItem('accountData');
        try {
            usr = JSON.parse(usr);
            if (usr && usr.type === "0")
                usr.type = 0;
            else {
                usr.type = 1;
            }
            setUser(usr);
        } catch (err) {
            console.error("Failed to get account data.");
        }
        fetchPeople();
    }, []);

    useEffect(() => {
        refreshPage();
    }, [props.clientId]);

    useImperativeHandle(ref, () => ({
        onAddClicked() {
            setIsAdd(true)
        }
    }))

    const refreshPage = () => {
        setDatas([]);
        setNewObj({});
        setIsAdd(false);
        setChangedFileds([]);
        fetchPeople();
    }

    const fetchPeople = () => {
        setIsLoading(true);
        let url = `/people/admin?clientId=` + props.clientId;
        GET('streamPeopleREST', url).then(response => {
            if (response && response.result) {
                const result = response.result;
                setDatas(result);
            }
        }).catch((err) => {
            Swal.fire('Fetch Data Error', 'Oops, Error occurred while fetching People.', 'error');
        }).finally(() => setIsLoading(false));
    }

    const onEditColumn = (id, name) => {
        let result = JSON.parse(JSON.stringify(datas));
        result = result.map((res) => {
            let keys = Object.keys(res);
            keys.forEach(key => {
                if (key.includes('Edit'))
                    delete res[key];
            });

            return res;
        })

        let data = result.find(z => z.id === id);
        if (!data) return;
        data[name + "Edit"] = true;
        setDatas(result);
    }

    const onFullnameChange = (value, id) => {
        let result = JSON.parse(JSON.stringify(datas));
        let data = result.find(z => z.id === id);
        if (!data) return;
        data.fullname = value;
        setDatas(result);
        changedRow(id);
    }

    const onEmailChange = (value, id) => {
        let result = JSON.parse(JSON.stringify(datas));
        let data = result.find(z => z.id === id);
        if (!data) return;
        data.email = value;
        if (!validateEmail(data.email))
            data.emailError = true;
        else
            data.emailError = false;
        setDatas(result);
        changedRow(id);
    }

    const onPhoneChange = (value, id) => {
        let result = JSON.parse(JSON.stringify(datas));
        let data = result.find(z => z.id === id);
        if (!data) return;
        data.phone = value;
        setDatas(result);
        changedRow(id);
    }

    const onLGCEdit = (value, id) => {
        let result = JSON.parse(JSON.stringify(datas));
        let data = result.find(z => z.id === id);
        if (!data) return;
        data.detail.device_id = value;
        setDatas(result);
        changedRow(id);
    }

    const changedRow = (id) => {
        let result = JSON.parse(JSON.stringify(changedFileds));
        if (!result.find(z => z === id)) {
            result.push(id)
            setChangedFileds(result);
        }
    }

    const onBlur = (id, objName) => {
        let result = JSON.parse(JSON.stringify(datas));
        let data = result.find(z => z.id === id);
        if (!data) return;
        delete data[objName];
        setDatas(result);
    }

    const onNewFiledsChnage = (e, id) => {
        const target = e.target.name;
        let value = e.target.value

        let result = JSON.parse(JSON.stringify(newObj));
        console.log(result);
        if (target === 'lgc') {
            result["detail"] = {
                device_id: value
            }
        } else {
            result[target] = value;
        }
        setNewObj(result);
    }

    const getColorClass = () => {
        const randomNumber = Math.floor(Math.random() * 5) + 1;
        let colorClass = '';
        switch (randomNumber) {
            case 1:
                colorClass = "pink";
                break;
            case 2:
                colorClass = "yellow";
                break;
            case 3:
                colorClass = "green";
                break;
            case 4:
                colorClass = "blue";
                break;
            case 5:
                colorClass = "purple";
                break;
            default:
                colorClass = "purple"
        }

        return colorClass;
    }

    const validateNew = () => {
        if (!newObj || !newObj.fullname || !newObj.email || !newObj.phone) return false;
        return true;
    }

    const onSaveChange = () => {
        let result = [];
        if (!props.clientId) return;
        changedFileds.forEach(id => {
            let findChanges = datas.find(z => z.id === id);
            if (findChanges)
                result.push({
                    id: findChanges.id,
                    clientId: findChanges.client_id,
                    fullname: findChanges.fullname,
                    email: findChanges.email,
                    phone: findChanges.phone,
                    detail: findChanges.detail
                })
        });

        let promises = [];
        if (isAdd && !validateNew())
            return Swal.fire('Progress failed', 'Please fill all fields.', 'error');

        if (isAdd)
            promises.push(addNew());
        if (result && result.length)
            promises.push(updateRows(result));
        setIsLoading(true);
        const allResults = Promise.all(promises).then(() => {
            refreshPage();
            Swal.fire('Successfully add/update', 'People successfully added/udpated.', 'success');
        }).catch((err) => {
            setIsLoading(false);
            if (err && err.message && err.message.includes('people_email_key')) {
                setErrors(['newEmailError']);
                return Swal.fire('Email already exist', 'Oops, Error occurred while adding Client.', 'error');
            }
            if (err && err.message && err.message.includes('people_phone_key')) {
                setErrors(['newPhoneError']);
                return Swal.fire('Phone already exist', 'Oops, Error occurred while adding Client.', 'error');
            }
            Swal.fire('Progress failed', 'Oops, Error occurred while adding/updating People.', 'error');
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const addNew = () => {
        const params = {
            body: {
                clientId: props.clientId,
                phone: newObj.phone,
                email: newObj.email,
                fullname: newObj.fullname,
                detail: {
                    "type": "1",
                    "color": getColorClass(),
                    "device_id": newObj.detail && newObj.detail.device_id ? newObj.detail.device_id : "",
                }
            }
        }
        return POST('streamPeopleREST', '/people', params);
    }

    const updateRows = (rows) => {
        const params = { body: { people: rows } }
        return PUT('streamPeopleREST', '/people/bulk', params);
    }

    const onRowSelcted = (e, id) => {
        let result = JSON.parse(JSON.stringify(datas));
        let data = result.find(z => z.id === id);
        if (!data) return;
        data.selected = !data.selected;
        setDatas(result);
    }

    const onRowSelctedAll = (value) => {
        let result = JSON.parse(JSON.stringify(datas));
        result.forEach(res => {
            res.selected = value;
        });
        setDatas(result);
    }

    const onDeleteSelected = () => {
        if (!datas || !datas.find(z => z.selected)) return;
        let ids = [];
        datas.forEach(dt => {
            if (dt.selected && dt.id)
                ids.push(dt.id)
        });

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setIsLoading(true)
                const params = { body: { ids, clientId: props.clientId } }
                DELETE('streamPeopleREST', '/people', params).then(() => {
                    refreshPage();
                    Swal.fire('Deleted!', 'Your selected rows has been deleted.', 'success');
                }).catch(() => {
                    Swal.fire('Fetch Data Error', 'Oops, Error occurred while delete people.', 'error');
                }).finally(() => setIsLoading(false))
            }
        })
    }

    return (
        <Fragment>
            <Row>
                <Col md={12}>
                    <ButtonGroup size="sm" style={{ float: "right", marginBottom: 50 }}>
                        <Button disabled={isLoading} onClick={() => {
                            if (datas && datas.every(z => z.selected))
                                onRowSelctedAll(false);
                            else
                                onRowSelctedAll(true);
                        }} variant="light tulu-btn-light" style={{
                            marginRight:
                                datas && datas.find(z => z.selected) ? 20 : 0
                        }}>
                            {datas && datas.every(z => z.selected) ?
                                'Deselect All' : 'Select all'}
                        </Button>
                        {datas && datas.find(z => z.selected) ? <Button disabled={isLoading} variant='light tulu-btn-light' onClick={onDeleteSelected}>Delete Selected</Button> : ""}
                        {((changedFileds && changedFileds.length) || isAdd) ?
                            <Fragment>
                                <Button disabled={isLoading} style={{ marginLeft: 20, marginRight: 20 }} variant='warning' onClick={refreshPage}>
                                    {isAdd ? 'Discard' : 'Discard Changes'}
                                </Button>
                                <Button disabled={isLoading} variant='primary tulu-primary' onClick={onSaveChange}>
                                    {isAdd ? 'Save' : 'Save Changes'}
                                </Button>
                            </Fragment>
                            : ""}
                    </ButtonGroup>
                </Col>
                <Col md={12}>
                    <div className='className'>
                        <table className="responsive">
                            <thead>
                                <tr>
                                    <th width="3%"></th>
                                    <th width="3%">#</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    {user && user.type === 0 && <th>Legacy Link ID</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {!isLoading && !isAdd &&
                                    <tr>
                                        <td>

                                        </td>
                                        <td className='add-icon'>
                                            +
                                        </td>
                                        <td>
                                            <button className='btn btn-link btn-add' onClick={() => setIsAdd(true)}>Add a people</button>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        {user && user.type === 0 && <td></td>}
                                    </tr>}
                                {!isLoading && isAdd &&
                                    <tr className='active'>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <input className='form-control'
                                                placeholder='Enter Fullname' value={newObj.fullname}
                                                onChange={(e) => onNewFiledsChnage(e)} name='fullname' />
                                        </td>
                                        <td>
                                            <input className={'form-control ' + (errors.find(z => z === 'newEmailError' || z === 'newEmailFormatError') ? "is-invalid" : "")}
                                                placeholder='Enter Email Address' value={newObj.email}
                                                onChange={(e) => onNewFiledsChnage(e)} name='email' />
                                        </td>
                                        <td>
                                            <input className={'form-control ' + (errors.find(z => z === 'newPhoneError') ? "is-invalid" : "")}
                                                placeholder='Enter Phone Number' value={newObj.phone}
                                                onChange={(e) => onNewFiledsChnage(e)} name='phone' />
                                        </td>

                                        {user && user.type === 0 &&
                                            <td>
                                                <input className="form-control"
                                                    placeholder='Enter Legacy Connection ID' value={newObj.lgc}
                                                    onChange={(e) => onNewFiledsChnage(e)} name='lgc' />
                                            </td>
                                        }
                                    </tr>}
                                {isLoading &&
                                    <tr style={{ backgroundColor: '#ffffff' }}>
                                        {user && user.type === 0 ?
                                            [...Array(6)].map((item, index) => {
                                                return (<td style={{ backgroundColor: '#ffffff' }} key={(index + 1).toString()}>
                                                    <ContentLoader viewBox="0 0 25 3">
                                                        <rect x="0" y="0" rx="0" ry="0" width="20" height="3" />
                                                    </ContentLoader>
                                                </td>)
                                            }) :
                                            [...Array(5)].map((item, index) => {
                                                return (<td style={{ backgroundColor: '#ffffff' }} key={(index + 1).toString()}>
                                                    <ContentLoader viewBox="0 0 25 3">
                                                        <rect x="0" y="0" rx="0" ry="0" width="20" height="3" />
                                                    </ContentLoader>
                                                </td>)
                                            })
                                        }
                                    </tr>
                                }
                                {!isLoading && datas && datas.map((dt, index) => {
                                    return (
                                        <tr key={index + 1}>
                                            <td>
                                                <input type={'checkbox'} checked={dt.selected} onChange={(e) => onRowSelcted(e, dt.id)} />
                                            </td>
                                            <td>
                                                <span className='row-num' onClick={(e) => onRowSelcted(e, dt.id)}>{index + 1}</span>
                                            </td>
                                            <td onClick={() => onEditColumn(dt.id, 'fullname')}
                                                className={dt.fullnameEdit ? 'active' : ''}>
                                                {dt.fullnameEdit ?
                                                    <div className='d-flex align-items-center'>
                                                        <input className='form-control' name='fullname' placeholder='Name' value={dt.fullname}
                                                            onChange={(e) => onFullnameChange(e.target.value, dt.id)}
                                                            onBlur={() => onBlur(dt.id, 'fullnameEdit')} />
                                                    </div> :
                                                    <span>{dt.fullname}</span>
                                                }
                                            </td>
                                            <td onClick={() => onEditColumn(dt.id, 'email')}
                                                className={dt.emailEdit ? 'active' : ''}>
                                                {dt.emailEdit ?
                                                    <div className='d-flex align-items-center'>
                                                        <input className={'form-control' + (dt.emailError ? " is-invalid" : "")} name='email' placeholder='Email' value={dt.email}
                                                            onChange={(e) => onEmailChange(e.target.value, dt.id)}
                                                            onBlur={() => onBlur(dt.id, 'emailEdit')} />
                                                    </div>
                                                    :
                                                    <span>{dt.email}</span>
                                                }
                                            </td>
                                            <td onClick={() => onEditColumn(dt.id, 'phone')}
                                                className={dt.phoneEdit ? 'active' : ''}>
                                                {dt.phoneEdit ?
                                                    <div className='d-flex align-items-center'>
                                                        <input className='form-control' name='phone' placeholder='Phone' value={dt.phone}
                                                            onChange={(e) => onPhoneChange(e.target.value, dt.id)}
                                                            onBlur={() => onBlur(dt.id, 'phoneEdit')} />
                                                    </div>
                                                    :
                                                    <span>{dt.phone}</span>
                                                }
                                            </td>
                                            {user && user.type === 0 &&
                                                <td onClick={() => onEditColumn(dt.id, 'lgc')}
                                                    className={dt.lgcEdit ? 'active' : ''}>
                                                    {dt.lgcEdit ?
                                                        <div className='d-flex align-items-center'>
                                                            <input className='form-control' name='lgc' placeholder='LGC ID' value={dt.detail.device_id}
                                                                onChange={(e) => onLGCEdit(e.target.value, dt.id)}
                                                                onBlur={() => onBlur(dt.id, 'lgc')} />
                                                        </div>
                                                        :
                                                        <span>{(dt && dt.detail && dt.detail.device_id) ? dt.detail.device_id : ""}</span>
                                                    }
                                                </td>
                                            }
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
        </Fragment>
    )
})

export default PeopleTable;