import '@babel/polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './src/app'
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import './src/styles/index.scss'
Amplify.configure(awsExports);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('App')
)
