import React, { useEffect, Fragment, useState, useRef } from 'react';
import Swal from 'sweetalert2';
import Select from 'react-select';
import {BsPlusLg} from 'react-icons/bs';
import { GET } from '../../../utils/api';
import PlacesTable from './PlacesTable';
import PeopleTable from './PeopleTable';
import CamerasTable from './CamerasTable';
import UsersTable from './UsersTable';
import ClientsTable from './ClientsTable';

const Manage = () => {
    const peopleRef = useRef();
    const placeRef = useRef();
    const cameraRef = useRef();
    const userRef = useRef();
    const clientRef = useRef();
    const [clients, setClients] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [currentTab, setCurrentTab] = useState(3);
    const [selectedClient, setSelectedClient] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        document.title = 'Manage';
        let usr = localStorage.getItem('accountData');
        try {
            usr = JSON.parse(usr);
            if (usr && usr.type === "0")
                usr.type = 0;
            else {
                usr.type = 1;
                setSelectedClient({ value: usr.id })
            }
            setUser(usr);
            fetchClients(usr.type, usr.id);
        } catch (err) {
            console.error("Failed to get account data.");
        }
    }, []);

    const fetchClients = (userType, clientId) => {
        let url = `/clients`;
        if (userType === 1)
            url += `?userType=1&clientId=${clientId}`
        else if (userType === 0)
            url += `?userType=0`
        GET('streamClientsREST', url, {userType: 0}).then(response => {
            if (response && response.result) {
                const clients = response.result;
                let result = [];
                clients.forEach(client => {
                    result.push({ label: client.fullname, value: client.id, client: client })
                });
                setClients(result);
                setTimeout(() => {
                    setSelectedClient(result[0]);
                }, 1);
            }
        }).catch(() => {
            Swal.fire('Fetch Data Error', 'Oops, Error occurred while fetching data.', 'error');
        }).finally(() => setIsLoading(false))
    }

    const onClientChange = (e) => {
        setSelectedClient(e);
    }

    const refreshClient = (e) => {
        fetchClients(user.type, user.id);
    }

    const loadComponents = () => {
        switch (currentTab) {
            case 1:
                if (selectedClient.value && user && user.type === 0)
                    return (<ClientsTable ref={clientRef} refreshClient={refreshClient} />);
            case 2:
                if (selectedClient.value)
                    return (<UsersTable ref={userRef} clientId={selectedClient.value} />);
            case 3:
                if (selectedClient.value)
                    return (<PlacesTable ref={placeRef} clientId={selectedClient.value} />);
            case 4:
                if (selectedClient.value)
                    return (<PeopleTable ref={peopleRef} clientId={selectedClient.value} />);
            case 5:
                if (selectedClient.value)
                    return (<CamerasTable ref={cameraRef} clientId={selectedClient.value} />);
            default:
                return("");
        }
    }

    return (
        <Fragment>
            <div className='tulu-component-manage'>
                <div className='tabs'>
                    {user && user.type === 0 ? 
                        <button className={'btn btn-tab' + (currentTab === 1 ? " active" : "")}
                            onClick={() => setCurrentTab(1)}>Clients</button> : ""}
                    <button className={'btn btn-tab' + (currentTab === 2? " active" : "")}
                            onClick={() => setCurrentTab(2)}>Users</button>
                    <button className={'btn btn-tab' + (currentTab === 3 ? " active" : "")}
                        onClick={() => setCurrentTab(3)}>Places</button>
                    <button className={'btn btn-tab' + (currentTab === 4 ? " active" : "")}
                        onClick={() => setCurrentTab(4)}>People</button>
                    <button className={'btn btn-tab' + (currentTab === 5 ? " active" : "")}
                        onClick={() => setCurrentTab(5)}>Cameras</button>
                </div>

                <div className='manage-header'>
                    {currentTab !== 1 && user && user.type === 0 ?
                        <div className='select-client'>
                            <Select options={clients}
                                value={selectedClient}
                                isLoading={isLoading}
                                isDisabled={isLoading}
                                className="basic-single"
                                classNamePrefix="select"
                                onChange={onClientChange} />
                        </div> : <div></div>}
                </div>

                {selectedClient && selectedClient.value ?
                    <Fragment>{loadComponents()}</Fragment>
                    : ""}
            </div>
        </Fragment>
    )
}

export default Manage;
