import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Form } from 'react-bootstrap';
import { BsArrowsAngleExpand } from "react-icons/bs";
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import lottieWeb from 'lottie-web';
import VideoJS from '../components/Video';
import { timeDiff } from '../utils/utilities';

const Stream = ({ streamData, keyIndex }) => {
  let history = useHistory();
  const [expand, setExpand] = useState(false);
  const [durationTime, setDurationTime] = useState();

  useEffect(() => {
    setDurationTime(timeDiff(streamData.detail.time_data.start_date));
    setInterval(() => {
      streamData && setDurationTime(timeDiff(streamData.detail.time_data.start_date));
    }, 1000);
    lottieWeb.loadAnimation({
      container: document.getElementById('live_' + keyIndex),
      path: '/documents/Live.json',
      renderer: 'svg',
      loop: true,
      autoplay: true,
      name: "Demo Animation",
    });
  }, []);

  const openVideoPreview = (StreamData) => {
    if (!StreamData) return;
    history.push({
      pathname: '/video-preview',
      data: StreamData,
    })
  }

  const openLocation = (PlaceId) => {
    if (!PlaceId) return;

    history.push({
      pathname: `/places/${PlaceId}`
    });
  }

  const openTechnician = (TechnicianId) => {
    if (!TechnicianId) return;
    history.push({
      pathname: `/people/${TechnicianId}`
    });
  }

  return (
    streamData ?
      <Col className="mb-2">
        <Card className="video-card-video-wrap">
          <div style={{ position: 'relative' }} onClick={() => openVideoPreview(streamData)}>
            <Card.Img variant="top" src={'/images/video-not-working.png'} className="card-img" onMouseEnter={() => setExpand(true)} onMouseLeave={() => setExpand(false)} />
            {streamData && <div onMouseEnter={() => setExpand(true)} onClick={() => openVideoPreview(streamData)} onMouseLeave={() => setExpand(false)}>
              <VideoJS streamData={streamData} key={keyIndex}  keyIndex={keyIndex} type="thumbnail" className="card-img" />
            </div>}
            {expand && <Col md={12} sm={12} xs={12} className="card-img-expand" onMouseEnter={() => setExpand(true)} onMouseLeave={() => setExpand(false)} onClick={() => openVideoPreview(streamData)}>
              {streamData.status < 3 ?
                <>
                  <BsArrowsAngleExpand className="card-expand-icon" />
                  <Form.Label className="card-expand-label">Expand</Form.Label>
                </> :
                <Form.Label className="card-expand-label">View Record</Form.Label>}
            </Col>}
          </div>
          <Card.Body className="video-card-time-wrap" onClick={() => openVideoPreview(streamData)}>
          <Row className="item-row">
              <div className="video-card-duration" style={{ width: 'fit-content'}}>
                <Form.Label className="live">
                  <div id={'live_' + keyIndex} className="live-indicator">
                  </div>
                  {durationTime}
                </Form.Label>
              </div>
              <div className="video-card-times" style={{ width: 'fit-content',  maxWidth: '66%' }}>
                <Form.Label className="time-label">
                  {moment(streamData.detail.time_data.start_date).format('hh:mm A')}
                </Form.Label>
              </div>
            </Row>
            {/* <Row className="item-row">
              <Col md="4" sm="4" xs="4" className="video-card-duration">
                <Form.Label className="live">
                  <div id={'live_' + keyIndex} className="live-indicator">
                  </div>
                  {durationTime}
                </Form.Label>
              </Col>
              <Col md="8" sm="8" xs="8" className="video-card-times">
                <Form.Label className="time-label">
                  {moment(streamData.detail.time_data.start_date).format('hh:mm A')}
                </Form.Label>
              </Col>
            </Row> */}
          </Card.Body>
          <Card.Footer className="Video-card-footer">
          <Form.Label className="video-card-name-wrap video-card-name-link" onClick={() => openLocation(streamData.place_id)}>{streamData.place_name}</Form.Label>
            <Form.Label className="video-card-person" onClick={() => openTechnician(streamData.people_id)}>{streamData.people_name}</Form.Label>
            <Form.Label className="video-card-date">{moment(streamData.detail.time_data.start_date).format('MMM DD, dddd')}</Form.Label>
          </Card.Footer>
        </Card>
      </Col> : <></>
  )
}

export default Stream
