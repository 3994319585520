import React, { useEffect, useContext, useState } from 'react';
import { Router, Route, Switch, useLocation } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import Dashboard from './routes/pages/Dashboard';
import Places from './routes/pages/Places';
import People from './routes/pages/People';
import Activate from './routes/pages/Activate';
import Login from './routes/pages/Login';
import ForgetPassword from './routes/pages/ForgetPassword';
import ResetPassword from './routes/pages/ResetPassword';
import VideoPreview from './routes/pages/VideoPreview';
import Store, { Context } from './utils/state/Store';
import { isLogin } from './utils/utilities';
import Manage from './routes/pages/Manage/Manage';
import config from './config';

export const App = () => {
  return (
    <Store>
      <Router history={createBrowserHistory()}>
        <StreamAppRouter></StreamAppRouter>
      </Router>
    </Store>
  );
}

const StreamAppRouter = () => {
  const location = useLocation();
  const [globalState, dispatch] = useContext(Context);
  const [state, setState] = React.useState({
    isLaoding: true,
    userChecked: false
  });

  useEffect(() => {
    connectSocket();
  }, []);

  const connectSocket = () => {
    let stage = 'dev';
    if (process.env && process.env.NODE_ENV) {
      stage = process.env.NODE_ENV === 'development' ? 'dev' : 'production';
    } else {
      return;
    }
    if (globalState.NETWORK_SOCKET) {
      return;
    } else {
      const owner = localStorage.getItem('owner');
      let client = new W3CWebSocket(`${config.SOCKET_SERVER}/${stage}?userId=` + owner);
      client.onerror = function (event) {
        dispatch({
          type: 'SOCKET', payload: event
        });
      };

      client.onopen = function (event) {
        dispatch({
          type: 'SOCKET', payload: event
        });
      };

      client.onclose = function (event) {
        client = new W3CWebSocket(`${config.SOCKET_SERVER}/${stage}?userId=` + owner);
        dispatch({
          type: 'SOCKET', payload: event
        });
        setTimeout(function() {
          connectSocket();
        }, 1000);
      };

      client.onmessage = function (e) {
        if (e && e.data) {
          dispatch({
            type: 'SOCKET_MESSAGE', payload: e
          });
        }
      };
    }
  }

  if (!state.userChecked) {
    state.userChecked = true;
    isLogin().then((result) => {
      dispatch({
        type: 'SET_AUTHENTICATION', payload: result
      });
      setState({
        isLaoding: false,
        userChecked: true
      });
      let loadingDiv = document.getElementById('main-live-loading');
      if (loadingDiv) loadingDiv.style = "display:none";
    });
  }

  if (state.isLaoding) return (
    <></>
  )

  return (
    <Switch>
      <PublicRoute exact path="/activate" title="Camera Activate" theme="true" darkMode="false" component={Activate} />
      <PublicRoute exact path="/" title="Camera Activate" theme="true" darkMode="false" component={Login} />
      <PublicRoute exact path="/Login" title="Login" theme="true" darkMode="false" component={Login} />
      <PublicRoute exact path="/Forget-Password" title="Forget Password" theme="true" darkMode="false" component={ForgetPassword} />
      <PublicRoute exact path="/Reset-Password/:username" title="Reset Password" theme="true" darkMode="false" component={ResetPassword} />
      <PrivateRoute exact path="/home" title="Home" theme="true" darkMode="false" component={Dashboard} />
      <PrivateRoute exact path="/places" title="Places" theme="false" darkMode="false" component={Places} />
      <PrivateRoute exact path="/places/:id" title="Places" theme="false" darkMode="false" component={Places} />
      <PrivateRoute exact path="/people" title="People" theme="false" darkMode="false" component={People} />
      <PrivateRoute exact path="/people/:id" title="People" theme="false" darkMode="false" component={People} />
      <PrivateRoute exact path="/video-preview" title="Video Preview" theme="false" darkMode="true" component={VideoPreview} />
      <PrivateRoute exact path="/video-preview/:withId" title="Video Preview" theme="false" darkMode="true" component={VideoPreview} />
      <PrivateRoute exact path="/manage" title="Manage" theme="false" darkMode="false" component={Manage} />
      <PublicRoute exact path="/media/:id" title="Video Preview" theme="false" darkMode="true" component={VideoPreview} />
    </Switch>
  )
}