import React, { useEffect, useState } from 'react';
import { Col, Table, OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import moment from 'moment';
import { FaPlay, FaPause, FaCircle } from "react-icons/fa";

import('../styles/shared/_timeLine.scss');
import appConfig from '../../config/app.json';
import { videoTimeCalculator } from '../utils/utilities';

const TimeLine = ({ videoWidth, streamData, playerData, playing, duration, timeupdate, pauseVideo, playVideo, commentData, timeChange }) => {

  const checkForDevice = () => {
    let windowWidth = window.innerWidth;
    if (windowWidth < 767.98) {
      return true;
    } else {
      return false;
    }
  };

  const [videoData, setVideoData] = useState(null);
  const [timeFrame, setTimeFrame] = useState([]);
  const [timeLength, setTimeLength] = useState(1);
  const [timeType, setTimeType] = useState('seconds');
  const [timeLineWidth, setTimeLineWidth] = useState(0);
  const [timeDuration, setTimeDuration] = useState(0);
  const [timeData, setTimeData] = useState();
  const [indicatorPosition, setIndicatorPosition] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [commentIndicators, setCommentIndicators] = useState();
  const [isMobile, setIsMobile] = useState(checkForDevice());
  const [timeIndicator, setTimeIndicator] = useState(0);
  const [timeIndicatorDisplay, setTimeIndicatorDisplay] = useState(false);
  const [indicatorTime, setIndicatorTime] = useState(0);
  const [indicatorTimeLabel, setIndicatorTimeLabel] = useState(0);
  const [isCommentShow, setIsCommentShow] = useState(false);

  useEffect(() => {
    import('../styles/shared/_timeLine.scss');
    const handlePageResized = () => {
      setIsMobile(checkForDevice);
    };

    window.addEventListener('resize', handlePageResized);
    window.addEventListener('orientationchange', handlePageResized);
    window.addEventListener('load', handlePageResized);
    window.addEventListener('reload', handlePageResized);

    return () => {
      window.removeEventListener('resize', handlePageResized);
      window.removeEventListener('orientationchange', handlePageResized);
      window.removeEventListener('load', handlePageResized);
      window.removeEventListener('reload', handlePageResized);
    };
  }, []);

  useEffect(() => {
    timeLineLength();
  }, [timeLength]);

  useEffect(() => {
    if (typeof playing == "undefined") return;
    setIsPlaying(playing);
  }, [playing]);

  let timeLineLength = () => {
    let timeData = [];
    let count = 0;
    [...Array(isMobile ? 6 : 12)].map((time, i) => {
      count++;
      const item = {
        label: moment("2022-01-01 00:00:00").add((parseInt(count) * parseInt(timeLength)), timeType).format('HH:mm:ss'),
        frame: count
      };
      timeData.push(item);
    });
    setTimeFrame(timeData);
  }

  useEffect(() => {
    handleCommentsIndicator(commentData);
  }, [commentData]);

  const handleCommentsIndicator = (commentData) => {
    let CommentIndicatorData = [];
    if (!commentData) return;
    commentData && commentData.length > 0 && commentData.map((item, index) => {
      let seconds;
      if (streamData.type === appConfig.MEDIA_TYPE.STREAM) {
        seconds = moment(item.submited_at).diff(moment(streamData.detail.time_data.start_date), 'seconds');
      }
      if (streamData.type === appConfig.MEDIA_TYPE.RECORD) {
        seconds = moment(item.submited_at).diff(moment(streamData.start_time), 'seconds');
      }
      const element = document.getElementById('live-area');
      const mainTimeAreaWidth = element.getBoundingClientRect().width;
      let timeArea = moment("2022-01-01 00:00:00").add((parseInt(isMobile ? 6 : 12) * parseInt(timeLength)), timeType).format('HH:mm:ss');
      let timeAreaWidth = moment(timeArea, 'HH:mm:ss').diff(moment('00:00:00', 'HH:mm:ss'), 'seconds');
      let width = (seconds * 100) / timeAreaWidth;
      let newPosition = (mainTimeAreaWidth * width) / 100;
      const indicator = {
        position: newPosition,
        hasValue: item.content === null || item.content === "" ? false : true,
        value: item.content,
        showValue: false,
        time: seconds,
        date_time: item.submited_at
      }
      CommentIndicatorData.push(indicator);
    });
    setCommentIndicators([...CommentIndicatorData])
  }

  useEffect(() => {
    if (duration && timeData) return;
    setTimeDuration(duration);
    if (streamData && streamData.type === appConfig.MEDIA_TYPE.RECORD) {
      const videoData = {
        "meta": {
          "durationMs": duration * 1000
        },
        "videoType": "vod" // "live" for live stream
      };
      let timeValue = videoTimeCalculator(videoData, isMobile);
      setTimeData(timeValue);
      setTimeLength(timeValue.frameCount);
      setTimeLineWidth(timeValue.timeLineWidth);
      setVideoData(videoTimeCalculator(videoData, isMobile));
    }
    if (streamData && streamData.type === appConfig.MEDIA_TYPE.STREAM) {
      const videoData = {
        "meta": {
          "durationMs": duration * 1000
        },
        "videoType": "vod" // "vod" for recorded media
      };
      let timeValue = videoTimeCalculator(videoData, isMobile);
      setTimeLength(timeValue.frameCount);
      setTimeLineWidth(timeValue.timeLineWidth);
      setVideoData(videoTimeCalculator(videoData, isMobile));
    }
  }, [duration]);

  useEffect(() => {
    if (!timeupdate) {
      setIndicatorPosition(0);
      return;
    }
    if (streamData.type === appConfig.MEDIA_TYPE.RECORD && (indicatorTimeLabel > duration)) {
      pauseVideo();
      return;
    }
    const indicatorLeft = (timeupdate * 100) / duration;
    setIndicatorTimeLabel(timeupdate);
    setIndicatorPosition(indicatorLeft);
    handleCommentsIndicator(commentData)
  }, [timeupdate])

  const handlePauseVideo = (e) => {
    e.stopPropagation();
    setIsPlaying(false);
    pauseVideo();
  }

  const handlePlayVideo = () => {
    setIsPlaying(true);
    playVideo();
  }

  const ValueHideShow = (index, value) => {
    let updateIndicator = commentIndicators;
    updateIndicator[index].showValue = value;
    setIsCommentShow(value);
    setCommentIndicators([...updateIndicator]);
  }

  const handleMouseMove = (e) => {
    if (isCommentShow === true) return;
    if (e && e.target && e.target.id.startsWith('Indicator_divider')) {
      return;
    }
    const rect = e.target.getBoundingClientRect();
    const mouseX = e.clientX - rect.left;
    const timeLinePercentage = ((mouseX / rect.width) * 100);
    setTimeIndicator(timeLinePercentage);
    let newTime = (duration * timeLinePercentage) / 100;
    setIndicatorTime(newTime);
    setTimeIndicatorDisplay(true);
  }

  const handleMouseLeave = () => {
    setTimeIndicatorDisplay(false);
  }

  const handleMouseClick = () => {
    setIsPlaying(true);
    timeChange(indicatorTime)
  }

  return (
    <div className="time-row">
      <Col md={12} className="time-col"> 
        <Table striped bordered>
          <thead>
            <tr>
              <th><span>00:00:00</span></th>
              {timeFrame && timeFrame.map((time, i) => {
                return (
                  <th key={i}><span>{time.label}</span></th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            <tr style={{ height: '6rem' }}>
              <td>{streamData && streamData.type === appConfig.MEDIA_TYPE.RECORD ? (isPlaying === true ?
                <div className="icon-area" onClick={(e) => handlePauseVideo(e)}><FaPause className="play-icon" /></div> :
                <div className="icon-area" onClick={(e) => handlePlayVideo(e)}><FaPlay className="play-icon" /></div>) :
                <div className="icon-area"><Form.Label className="live live-label">
                  <img className="live-stream-indicator" alt="loading" src="/images/live-white.gif" />
                  LIVE
                </Form.Label></div>}</td>
              {timeFrame && timeFrame.map((time, i) => {
                return (
                  <td key={i}>-</td>
                )
              })}
            </tr>
            <tr className="time-indicator-container">
              <td></td>
              <td id="live-area" colSpan={parseInt(timeFrame.length + 1)} >
                <div className={streamData && streamData.type === appConfig.MEDIA_TYPE.STREAM ? "live-time-indicator" : "time-indicator"} style={{ maxWidth: `${timeLineWidth}%`, display: timeLineWidth === 0 ? 'none' : 'block' }}>
                  <div className="indicator-area">
                    {streamData && streamData.type === appConfig.MEDIA_TYPE.STREAM ? null : <span className="time-badge" style={{ pointerEvents: "none" }}>{moment("2022-01-01 00:00:00").add(indicatorTimeLabel, timeType).format('HH:mm:ss')} / {timeData && timeData.timeFormat ? timeData.timeFormat : "00:01"}</span>}
                  </div>
                  <div className="time-indicator-area" style={{ pointerEvents: "none", left: indicatorPosition <= 100 ? indicatorPosition + "%" : "100%" }}>
                    <div className="time-badge-divider"></div>
                    <div className="time-badge-indicator">{moment("2022-01-01 00:00:00").add(indicatorTimeLabel, timeType).format('HH:mm:ss')}</div>
                  </div>
                  {streamData.type === appConfig.MEDIA_TYPE.RECORD && timeIndicatorDisplay === true &&
                    <div className="mouse-indicator-area"
                      style={{
                        pointerEvents: "none",
                        left:
                          timeIndicator + "%"
                      }}>
                      <div className="time-badge-divider" style={{ pointerEvents: "none" }}></div>
                      <div className="time-badge-indicator">{moment("2022-01-01 00:00:00").add(indicatorTime, 'seconds').format('HH:mm:ss')}</div>
                    </div>
                  }
                  {commentIndicators && commentIndicators.length > 0 && commentIndicators.map((item, index) => {
                    return (
                      <div
                        className="comment-indicator-area"
                        key={index}
                        id={'Indicator_'+index}
                        style={{ left: item.position + "px", zIndex: item.showValue === true ? 10 : 1 }}>
                        {item.hasValue === false ? "" : (item.showValue === true && <span className="comment-time-badge">“ {item.value.slice(0, 10) + '...'}</span>)}
                        <div id={'Indicator_divider_'+index} className="comment-badge-divider"></div>
                        <div
                        id={'Indicator_badge_'+index}
                          onMouseOver={() => ValueHideShow(index, true)}
                          onMouseMove={() => ValueHideShow(index, true)}
                          onMouseLeave={() => ValueHideShow(index, false)}
                          className="comment-badge-indicator">
                          {item.hasValue === true ? (<FaCircle className="has-value" onMouseOver={() => ValueHideShow(index, true)} />) : null}{(item.showValue === true && item.hasValue === true) ? <span>{moment("2022-01-01 00:00:00").add(item.time, 'seconds').format('HH:mm:ss')}</span> : null}</div>
                      </div>
                    )
                  })}
                  <div
                    className={streamData && streamData.type === appConfig.MEDIA_TYPE.STREAM ? "live-time-indicator hover-indicator" : "time-indicator hover-indicator"} style={{ maxWidth: `${100}%`, display: timeLineWidth === 0 ? 'none' : 'block', zIndex: 10 }}
                    onMouseLeave={() => handleMouseLeave()}
                    onMouseOut={() => handleMouseLeave()}
                    onMouseMove={(e) => handleMouseMove(e)}
                    onClick={() => handleMouseClick()}>
                  </div>
                </div>
               
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </div>
  )
}

export default TimeLine
