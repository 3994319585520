const Reducer = (state, action) => {
  switch (action.type) {
    case 'SET_THEME':
      return {
        ...state,
        themes: action.payload
      };
    case 'READ_THEME':
      return {
        ...state,
        themes: state.themes.concat(action.payload)
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload
      };
    case 'SET_AUTHENTICATION':
      return {
        ...state,
        authentication: action.payload
      };
    case 'SOCKET':
      return {
        ...state,
        NETWORK_SOCKET: action.payload
      };
      case 'SOCKET_MESSAGE':
        return {
          ...state,
          MESSAGE: action.payload
        };
    default:
      return state;
  }
};

export default Reducer;