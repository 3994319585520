import { Auth } from 'aws-amplify';
import React, { useEffect, Fragment, useState, forwardRef, useImperativeHandle } from 'react';
import { Button, ButtonGroup, Col, Row, Table } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { BiSave } from "react-icons/bi";
import {MdOutlineCancel} from "react-icons/md"
import ContentLoader from 'react-content-loader'
import { PUT, POST, GET, DELETE } from '../../../utils/api';
import { validateEmail } from '../../../utils/utilities';

const ClientsTable = forwardRef((props, ref) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [newObj, setNewObj] = useState({});
    const [datas, setDatas] = useState([]);
    const [errors, setErrors] = useState([]);
    const [changedFileds, setChangedFileds] = useState([]);

    useEffect(() => {
        fetchClients();
    }, []);

    useImperativeHandle(ref, () => ({
        onAddClicked() {
            setIsAdd(true)
        }
    }))

    const refreshPage = () => {
        setDatas([]);
        setNewObj({});
        setIsAdd(false);
        setChangedFileds([]);
        fetchClients();
    }

    const fetchClients = () => {
        setIsLoading(true);
        let url = `/clients?userType=0`;
        GET('streamClientsREST', url).then(response => {
            if (response && response.result) {
                let result = response.result;
                result.forEach(res => {
                    if (!res.name && res.fullname)
                        res.name = res.fullname;
                });
                setDatas(result);
            }
        }).catch((err) => {
            Swal.fire('Fetch Data Error', 'Oops, Error occurred while fetching data.', 'error');
        }).finally(() => setIsLoading(false))
    }

    const onEditColumn = (id, name) => {
        let result = JSON.parse(JSON.stringify(datas));
        result = result.map((res) => {
            let keys = Object.keys(res);
            keys.forEach(key => {
                if (key.includes('Edit'))
                    delete res[key];
            });

            return res;
        })

        let data = result.find(z => z.id === id);
        if (!data) return;
        data[name + "Edit"] = true;
        setDatas(result);
    }

    const onNameChange = (value, id) => {
        let result = JSON.parse(JSON.stringify(datas));
        let data = result.find(z => z.id === id);
        if (!data) return;
        data.name = value;
        setDatas(result);
        changedRow(id);
    }

    const changedRow = (id) => {
        let result = JSON.parse(JSON.stringify(changedFileds));
        if (!result.find(z => z === id)) {
            result.push(id)
            setChangedFileds(result);
        }
    }

    const onBlur = (id, objName) => {
        let result = JSON.parse(JSON.stringify(datas));
        let data = result.find(z => z.id === id);
        if (!data) return;
        delete data[objName];
        setDatas(result);
    }

    const onNewFiledsChnage = (e) => {
        const target = e.target.name;
        let value = e.target.value

        let result = JSON.parse(JSON.stringify(newObj));
        result[target] = value;
        setNewObj(result);
    }

    const isFormValid = () => {
        let result = true;
        let errors = [];
        if (!newObj) return false;
        if (!newObj.name) {
            result = false;
            errors.push('newNameError');
        }

        setErrors(errors);
        return result;
    }

    const onSaveChange = () => {
        let result = [];
        changedFileds.forEach(id => {
            let findChanges = datas.find(z => z.id === id);
            if (findChanges)
                result.push({
                    id: findChanges.id,
                    name: findChanges.name
                })
        });

        let promises = [];
        if (isAdd && !isFormValid())
            return Swal.fire('Progress failed', 'Please fill all fields.', 'error');

        if (isAdd)
            promises.push(addNew());
        if (result && result.length)
            promises.push(updateRows(result));
        setIsLoading(true);
        const allResults = Promise.all(promises).then(() => {
            if (props.refreshClient) 
                props.refreshClient();

            Swal.fire('Successfully add/update', 'People successfully added/udpated.', 'success');
        }).catch((err) => {
            Swal.fire('Progress failed', 'Oops, Error occurred while adding/updating People.', 'error');
        }).finally(() => {
            refreshPage();
            setIsLoading(false);
        });
    }

    const addNew = () => {
        const params = {
            body: {
                name: newObj.name
            }
        }
        return POST('streamClientsREST', '/clients', params);
    }

    const updateRows = (rows) => {
        const params = { body: { clients: rows } }
        return PUT('streamClientsREST', '/clients/bulk', params);
    }
    const onRowSelcted = (e, id) => {
        let result = JSON.parse(JSON.stringify(datas));
        let data = result.find(z => z.id === id);
        if (!data) return;
        data.selected = !data.selected;
        setDatas(result);
    }

    const onRowSelctedAll = (value) => {
        let result = JSON.parse(JSON.stringify(datas));
        result.forEach(res => {
            res.selected = value;
        });
        setDatas(result);
    }

    const onDeleteSelected = () => {
        if (!datas || !datas.find(z => z.selected)) return;
        let ids = [];
        datas.forEach(dt => {
            if (dt.selected && dt.id)
                ids.push(dt.id)
        });

        Swal.fire({
            title: 'Are you sure?',
            text: "All of your data will be lost (Places, People, Cameras and etc).",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setIsLoading(true)
                const params = { body: { ids } }
                POST('streamClientsREST', '/clients-delete', params).then(() => {
                    refreshPage();
                    Swal.fire('Deleted!', 'Your selected rows has been deleted.', 'success');
                }).catch(() => {
                    Swal.fire('Fetch Data Error', 'Oops, Error occurred while delete people.', 'error');
                }).finally(() => setIsLoading(false))
            }
        })
    }

    return (
        <Fragment>
            <Row>
            <Col md={12}>
                    <ButtonGroup size="sm" style={{ float: "right", marginBottom: 50 }}>
                        <Button disabled={isLoading} onClick={() => {
                            if (datas && datas.every(z => z.selected))
                                onRowSelctedAll(false);
                            else
                                onRowSelctedAll(true);
                        }} variant="light tulu-btn-light" style={{
                            marginRight:
                                datas && datas.find(z => z.selected) ? 20 : 0
                        }}>
                            {datas && datas.every(z=> z.selected) ? 
                                'Deselect All' : 'Select all'}
                        </Button>
                        {datas && datas.find(z => z.selected) ? <Button disabled={isLoading} variant='light tulu-btn-light' onClick={onDeleteSelected}>Delete Selected</Button> : ""}
                        {((changedFileds && changedFileds.length) || isAdd) ?
                            <Fragment>
                                <Button disabled={isLoading} style={{ marginLeft: 20, marginRight: 20 }} variant='warning' onClick={refreshPage}>
                                    {isAdd ? 'Discard' : 'Discard Changes'}
                                </Button>
                                <Button disabled={isLoading} variant='primary tulu-primary' onClick={onSaveChange}>
                                    {isAdd ? 'Save' : 'Save Changes'}
                                </Button>
                            </Fragment>
                            : ""}
                    </ButtonGroup>
                </Col>
                <Col md={12}>
                    <div className='className'>
                        <table className="responsive">
                            <thead>
                                <tr>
                                    <th width="3%"></th>
                                    <th width="3%">#</th>
                                    <th>Client Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isLoading && !isAdd &&
                                    <tr>
                                        <td></td>
                                        <td className='add-icon'>
                                            +
                                        </td>
                                        <td>
                                            <button className='btn btn-link btn-add' onClick={() => setIsAdd(true)}>Add a client</button>
                                        </td>
                                    </tr>}
                                {!isLoading && isAdd &&
                                    <tr className='active'>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <input className={'form-control ' + (errors.find(z => z === 'newNameError') ? "is-invalid" : "")}
                                                placeholder='Enter Full Name' value={newObj.name}
                                                onChange={(e) => onNewFiledsChnage(e)} name='name' />
                                        </td>
                                    </tr>}

                                {isLoading === true &&
                                    <tr style={{ backgroundColor: '#ffffff' }}>
                                        {
                                            [...Array(3)].map((item, index) => {
                                                return (
                                                    <td style={{ backgroundColor: '#ffffff' }} key={(index + 1).toString()}>
                                                        <ContentLoader viewBox="0 0 20 3" style={{ maxHeight: 30 }}>
                                                            <rect x="0" y="0" rx="0" ry="0" width="20" height="3" />
                                                        </ContentLoader>
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                }
                                {isLoading=== false && datas && datas.map((dt, index) => {
                                    return (
                                        <tr key={index + 1}>
                                            <td>
                                                <input type={'checkbox'} checked={dt.selected} onChange={(e) => onRowSelcted(e, dt.id)} />
                                            </td>
                                            <td>
                                                <span className='row-num' onClick={(e) => onRowSelcted(e, dt.id)}>{index + 1}</span>
                                            </td>
                                            <td onClick={() => onEditColumn(dt.id, 'name')}
                                                className={dt.nameEdit ? 'active' : ''}>
                                                {dt.nameEdit ?
                                                    <div className='d-flex align-items-center'>
                                                        <input className='form-control' name='name' placeholder='Name' value={dt.name}
                                                            onChange={(e) => onNameChange(e.target.value, dt.id)}
                                                            onBlur={() => onBlur(dt.id, 'nameEdit')} />
                                                    </div> :
                                                    <span>{dt.name || dt.fullname}</span>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
        </Fragment>
    )
})

export default ClientsTable;