import React, { useEffect, useState } from 'react';
import { Row, Col, Stack, Breadcrumb, Form, Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import moment from 'moment';
import { timeDiff } from '../utils/utilities';
import appConfig from '../../config/app.json';
import config from '../../config/aws.json';
import { POST, GET } from '../utils/api';

const Comment = ({ streamData, handleTimeChange, handleComment, publicRoute }) => {
  let history = useHistory();
  const [commentData, setCommentData] = useState();
  const [durationTime, setDurationTime] = useState('00:00');
  const [isLoading, setIsLoading] = useState(false);
  const [commentLoad, setCommentLoad] = useState(false);

  useEffect(() => {
    streamData.type === appConfig.MEDIA_TYPE.STREAM && setDurationTime(timeDiff(streamData.detail.time_data.start_date));
    setInterval(() => {
      streamData.type === appConfig.MEDIA_TYPE.STREAM && setDurationTime(timeDiff(streamData.detail.time_data.start_date));
    }, 1000);
    getCommentList();
  }, []);

  const getCommentList = () => {
    if (!streamData) return;
    setIsLoading(false)
    const params = "?media_id=" + streamData.id;
    if (publicRoute === false ) {
      GET(config.AWS_FUNCTION.GET_MEDIA_EVENTS, config.AWS_API.GET_MEDIA_EVENTS + params)
      .then(response => {
        if (response && response.data) {
          setCommentData([...response.data]);
          handleComment([...response.data]);
        }
      })
      .catch(err => {
        Swal.fire('Data Loading Error', 'Error in data loading', 'error');
      }).finally(() => {
        setIsLoading(true);
        setCommentLoad(true);
      });
    } else {
      GET(config.AWS_FUNCTION.PUBLIC, config.AWS_API.GET_PUBLIC_MEDIA_EVENTS + params)
      .then(response => {
        if (response && response.data) {
          setCommentData([...response.data]);
          handleComment([...response.data]);
        }
      })
      .catch(err => {
        Swal.fire('Data Loading Error', 'Error in data loading', 'error');
      }).finally(() => {
        setIsLoading(true);
        setCommentLoad(true);
      });
    }

  }

  const handleActionButton = (commentId, value) => {
    if (publicRoute === true) return;
    setIsLoading(true);
    let newComments = commentData;
    newComments && newComments.forEach((item) => {
      if (item.id === commentId) {
        item.showAction = value;
      } else {
        item.showAction = false;
      }
    });
    setCommentData([...newComments]);
    setIsLoading(false);
  };

  const handleContentChanges = (commentId, value) => {
    setIsLoading(true)
    let newComments = commentData;
    newComments.filter((item => item.id === commentId))[0].content = value;
    setCommentData(newComments);
    setIsLoading(false);
  }

  const handleSubmitChanges = (comment) => {
    setIsLoading(true)
    let element = document.querySelector('#comment_' + comment.id);
    if (!comment) return;
    var clientId = localStorage.getItem('owner');
    const params = {
      body: {
        media_id: comment.id,
        content: element.value,
        client_id: clientId
      }
    }
    let freezedCommentData = commentData;
    let lastCommentData = commentData;
    lastCommentData && lastCommentData.forEach((item)=>{
      if (item.id === comment.id) {
        item.content = element.value;
        item.showAction = false;
      }
    });
    setCommentData([...lastCommentData]);
    handleComment([...lastCommentData]);
    POST(config.AWS_FUNCTION.GET_MEDIA_EVENTS, config.AWS_API.UPDATE_MEDIA_EVENT, params)
      .then((response) => {
        getCommentList();
      }).catch((err) => {
        Swal.fire('Data Updating Error', 'Error in comment submitting', 'error');
        setCommentData([...freezedCommentData]);
        handleComment([...freezedCommentData]);
      }).finally(() => {
        setIsLoading(false)
      });
  }

  const handleCommentClick =(comment)=>{
    if (streamData.type === appConfig.MEDIA_TYPE.STREAM) return;
    const secs =  moment(comment.submited_at).diff(moment(streamData.start_time), 'seconds');
    if (secs) 
    handleTimeChange(secs);
  }

  return (
    <div className="video-player-library">
      <div className="video-player_library-header">
        <div className="video-player_user_wrap w-inline-block">
          <Row style={{width: '100%'}}>
            <Col md={12} lg={12} sm={12} xs={12} style={{display: 'flex'}}>
            <div className="user-avatar small">
                <div className="user-initials">{streamData && streamData.people_name.slice(0, 1)}</div>
              </div>
              <div className="video-player_user-info_wrap">
                <div className="div-block-32">
                  <h4 className="location-list_name" onClick={() => openTechnician(streamData.people_id)}>{streamData && streamData.people_name}</h4>
                </div>
                <div className="text-block-11"> {streamData && (streamData.type === appConfig.MEDIA_TYPE.STREAM ?
                  moment(streamData.detail.time_data.start_date).format('hh:mm A') :
                  moment(streamData.start_time).format('hh:mm A') + " - " + moment(streamData.finish_time).format('hh:mm A'))}</div>
              </div>
              <div className="date"> {streamData && (streamData.type === appConfig.MEDIA_TYPE.STREAM ?
                moment(streamData.detail.time_data.start_date).format('MMM DD, YYYY') :
                moment(streamData.start_time).format('MMM DD, YYYY'))}</div>
            </Col>
          </Row>
        </div>

      </div>
      <div className="video-player_library-list">
        <div className="video-player_library_list">
          {commentData && commentData.length > 0 ? commentData.map((comment, index) => {
            if (comment.content === null || comment.content.length === 0) {
              return (
                <div className="video-player_library_item" key={comment.id}>
                  <div className="video-player_item-content">
                    <div className="video-player_item-screenshot" onClick={()=>handleCommentClick(comment)}>
                      <div className="video-plauer_item-thumbnail">
                        <img src={comment.thumbnail || "/images/no-thumbnail.png"} loading="lazy" alt="" className="video-player_item-thumbnail-img" />
                      </div>
                      <div className="time-stamp">{moment(comment.submited_at).format('hh:mm:ss A')}</div>
                    </div>
                    <div className="form-block w-form">
                      <div id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get" className="add_comment" aria-label="Email Form 2">
                        <textarea onClick={() => handleActionButton(comment.id, true)} id={'comment_' + comment.id} placeholder="Make comment" maxLength="5000" className="textarea w-input"></textarea>
                        {comment && comment.showAction && comment.showAction === true ?
                          <div className="div-block-31">
                            <button disabled={isLoading} onClick={() => handleSubmitChanges(comment)} className="video-button_submit-comment video-w-button" >Submit</button>
                            <button onClick={() => handleActionButton(comment.id, false)} className="simple-link-box dark-mode margin_right_1em w-inline-block">
                              <div>Cancel</div>
                            </button>
                          </div> : null}
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
            else {
              return (
                <div className="video-player_library_item" key={comment.id}>
                  <div className="video-player_item-content">
                    <div className="video-player_item-screenshot" onClick={()=>handleCommentClick(comment)}>
                      <div className="video-plauer_item-thumbnail">
                        <img src={comment.thumbnail || "/images/no-thumbnail.png"} loading="lazy" alt="" className="video-player_item-thumbnail-img" />
                      </div>
                      <div className="time-stamp">{moment(comment.submited_at).format('hh:mm:ss A')}</div>
                    </div>
                    <div className="form-block w-form">
                      {comment && (!comment.showAction || comment.showAction === false) && publicRoute === false ?
                        <>
                          <p className="paragraph">{comment.content}</p>
                          <div className="video-player_item-action" onClick={() => handleActionButton(comment.id, true)}>
                            <div className="html-embed-9 w-embed" >
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.762 18.762">
                                <g transform="translate(0 0)">
                                  <g transform="translate(0 3.005)">
                                    <path d="M10.72,90.924a1.659,1.659,0,0,1-.645.4L7.744,92.1a1.645,1.645,0,0,1-.521.085h0a1.649,1.649,0,0,1-1.564-2.171l.777-2.332a1.657,1.657,0,0,1,.4-.645L11.871,82H2.016A2.018,2.018,0,0,0,0,84.015V95.742a2.018,2.018,0,0,0,2.015,2.015H13.742a2.018,2.018,0,0,0,2.015-2.015V85.887Z" transform="translate(0 -82)" fill="currentColor"></path>
                                  </g>
                                  <path d="M183.024,60.152a.549.549,0,0,0-.133.215l-.777,2.332a.55.55,0,0,0,.7.7l2.332-.777a.549.549,0,0,0,.215-.133l6.867-6.867-2.332-2.332Z" transform="translate(-175.413 -51.333)" fill="currentColor"></path>
                                  <path d="M418.491.268a.916.916,0,0,0-1.3,0l-.907.907,2.332,2.332.907-.907a.916.916,0,0,0,0-1.3Z" transform="translate(-401.034 0)" fill="currentColor"></path>
                                </g>
                              </svg>
                            </div>
                            <div>Edit</div>
                          </div>
                        </> : null}
                      {comment && comment.showAction && comment.showAction === true ?
                        <div id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get" className="add_comment" aria-label="Email Form 2">
                          <textarea rows="3" onClick={() => handleActionButton(comment.id, true)} id={'comment_' + comment.id} placeholder="Make comment" maxLength="5000" className="textarea w-input">{comment.content}</textarea>
                          {comment && comment.showAction && comment.showAction === true ?
                            <div className="div-block-31">
                              <button disabled={isLoading} onClick={() => handleSubmitChanges(comment)} className="video-button_submit-comment video-w-button" >{isLoading === true ? "Please wait..." : "Submit"}</button>
                              <button onClick={() => handleActionButton(comment.id, false)} className="simple-link-box dark-mode margin_right_1em w-inline-block">
                                <div>Cancel</div>
                              </button>
                            </div> : null}
                        </div> : null}
                    </div>
                  </div>
                </div>
              )
            }
          }) :
            (commentLoad === false ?
              <div className="video-player_library_item" id="1" key="1">
                <div className="video-player_item-content">
                </div></div>
              : <div className="video-player_library_item" id="1" key="1">
                <div className="video-player_item-content">
                  <p className="paragraph" style={{ width: '100%' }}>There are no events to display</p>
                </div>
              </div>)}
        </div>
      </div>
    </div>
  )
}

export default Comment
