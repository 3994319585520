import React, { useState } from 'react';
import { Col, Navbar, Nav, Stack, Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import PageHeader from './PageHeader';
import { BsList } from "react-icons/bs";
import { Auth } from 'aws-amplify';
import { Animated } from "react-animated-css";

const PublicLayout = ({ children, title, theme, darkMode }) => {
    let history = useHistory();
    const [expanded, setExpanded] = useState(false);

    return (
        <React.Fragment>
            <Container fluid className="app-body dark-mode d-md-flex p-0">
                <Col className={theme === "true" ? "" : (darkMode === "true" ? "full-width-content-page-dark" : "full-width-content-page")}>
                    {children}
                </Col>
            </Container>
        </React.Fragment>
    );
};
export default PublicLayout;