import React, { useEffect, Fragment, useState, forwardRef } from 'react';
import { Button, ButtonGroup, Col, Row, Modal, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import ContentLoader from 'react-content-loader';
import { PUT, POST, GET, DELETE } from '../../../utils/api';
import Select from 'react-select'

const CamerasTable = forwardRef((props, ref) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [newObj, setNewObj] = useState({});
    const [datas, setDatas] = useState([]);
    const [places, setPlaces] = useState([]);
    const [people, setPeople] = useState([]);
    const [changedFileds, setChangedFileds] = useState([]);
    const [configShow, setConfigShow] = useState(false);
    const [cameraConfig, setCameraConfig] = useState();
    const [tempCameraConfig, setTempCameraConfig] = useState();
    const [updateId, setUpdateId] = useState();
    const [newItem, setNewItem] = useState(false);
    useEffect(() => {
        const defaultConfig = {
            "stream": true,
            "offline_record": true,
            "log": true,
            "videorec_button": true,
            "socket": true,
            "4G": false,
            "4G_apn": "",
            "wifi": true,
            "wifi_SSID": "",
            "wifi_password": "",
            "codec": "H264",
            "resolution": "1280x748"
        }
        setCameraConfig(defaultConfig);
        setTempCameraConfig(defaultConfig);
        fetchDevices();
        fetchPlaces();
        fetchPeople();
    }, []);

    useEffect(() => {
        refreshPage();
    }, [props.clientId]);

    const refreshPage = () => {
        setDatas([]);
        setNewObj({});
        setIsAdd(false);
        setChangedFileds([]);
        fetchDevices();
        fetchPlaces();
        fetchPeople()
    }

    const fetchDevices = () => {
        setIsLoading(true);
        let url = `/devices/admin?clientId=` + props.clientId;
        GET('streamDevicesREST', url).then(response => {
            if (response && response.result) {
                response.result.forEach(row => {
                    if (row.place_name && row.place_id)
                        row.place = { label: row.place_name, value: row.place_id }
                    if (row.people_name && row.people_id)
                        row.people = { label: row.people_name, value: row.people_id }
                });
                const result = response.result;
                setDatas(result);
            }
        }).catch(() => {
            Swal.fire('Fetch Data Error', 'Oops, Error occurred while fetching devices.', 'error');
        }).finally(() => setIsLoading(false));
    }

    const fetchPlaces = () => {
        let url = `/places/admin/get?clientId=` + props.clientId;
        GET('streamPlacesREST', url).then(response => {
            if (response && response.result && response.result.length) {
                let result = [];
                response.result.forEach(row => {
                    result.push({ label: row.name, value: row.id });
                });
                setPlaces(result);
            }
        }).catch(() => {
            Swal.fire('Fetch Data Error', 'Oops, Error occurred while fetching places.', 'error');
        })
    }

    const fetchPeople = () => {
        let url = `/people/admin?clientId=` + props.clientId;
        GET('streamPeopleREST', url).then(response => {
            if (response && response.result && response.result.length) {
                let result = [];
                response.result.forEach(row => {
                    result.push({ label: row.fullname, value: row.id });
                });
                setPeople(result);
            }
        }).catch(() => {
            Swal.fire('Fetch Data Error', 'Oops, Error occurred while fetching People.', 'error');
        })
    }

    const onEditColumn = (id, name) => {
        let result = JSON.parse(JSON.stringify(datas));
        result = result.map((res) => {
            let keys = Object.keys(res);
            keys.forEach(key => {
                if (key.includes('Edit'))
                    delete res[key];
            });

            return res;
        })

        let data = result.find(z => z.id === id);
        if (!data) return;
        data[name + "Edit"] = true;
        setDatas(result);
    }

    const changedRow = (id) => {
        let result = JSON.parse(JSON.stringify(changedFileds));
        if (!result.find(z => z === id)) {
            result.push(id)
            setChangedFileds(result);
        }
    }

    const onBlur = (id, objName) => {
        let result = JSON.parse(JSON.stringify(datas));
        let data = result.find(z => z.id === id);
        if (!data) return;
        delete data[objName];
        setDatas(result);
    }

    const onNewFiledsChnage = (e) => {
        const target = e.target.name;
        let value = e.target.value

        let result = JSON.parse(JSON.stringify(newObj));
        result[target] = value;
        setNewObj(result);
    }

    const onSaveDropdownChange = (value, propName) => {
        let result = JSON.parse(JSON.stringify(newObj));
        result[propName] = value;
        setNewObj(result);

    }

    const validateNew = () => {
        if (!props.clientId) return false;
        if (!newObj || !newObj.name || !newObj.place || !newObj.place.value || !newObj.people || !newObj.people.value ||
            !newObj.activationCode || !newObj.cameraId) return false;
        return true;
    }

    const onSaveChange = () => {
        let result = [];
        if (!props.clientId) return;
        changedFileds.forEach(id => {
            let findChanges = datas.find(z => z.id === id);
            if (findChanges)
                result.push({
                    id: findChanges.id,
                    clientId: props.clientId,
                    placeId: findChanges.place && findChanges.place.value ? findChanges.place.value : null,
                    peopleId: findChanges.people && findChanges.people.value ? findChanges.people.value : null,
                    name: findChanges.name,
                    activationCode: findChanges.activation_code,
                    streamData: findChanges.camera_id ? { "name": findChanges.camera_id, "ssid": "000000" } : null,
                    configuration : findChanges.configuration
                })
        });

        let promises = [];
        if (isAdd && !validateNew())
            return Swal.fire('Progress failed', 'Please fill all fields.', 'error');

        if (isAdd)
            promises.push(addNew());
        if (result && result.length)
            promises.push(updateRows(result));
        setIsLoading(true);
        const allResults = Promise.all(promises).then(() => {
            Swal.fire('Successfully add/update', 'Cameras successfully added/udpated.', 'success');
        }).catch(() => {
            Swal.fire('Progress failed', 'Oops, Error occurred while adding/updating cameras.', 'error');
        }).finally(() => {
            refreshPage();
            setIsLoading(false);
        });
    }

    const addNew = () => {
        debugger;
        const params = {
            body: {
                clientId: props.clientId,
                name: newObj.name,
                placeId: newObj.place && newObj.place.value ? newObj.place.value : null,
                peopleId: newObj.people && newObj.people.value ? newObj.people.value : null,
                name: newObj.name,
                activationCode: newObj.activationCode,
                streamData: newObj.cameraId ? { "name": newObj.cameraId, "ssid": "000000" } : null,
                detail: {
                    "time_data": {
                        "start_date": "",
                        "finish_date": ""
                    }
                },
                configuration: tempCameraConfig
            }
        }
        return POST('streamDevicesREST', '/devices', params);
    }

    const updateRows = (rows) => {
        const params = { body: { devices: rows } }
        return PUT('streamDevicesREST', '/devices/bulk', params);
    }

    const onEditDropdownChange = (value, id, propName) => {
        let result = datas;
        let data = result.find(z => z.id === id);
        if (!data) return;
        data[propName] = JSON.parse(JSON.stringify(value));
        changedRow(id);
    }

    const onEditFieldChange = (e, id) => {
        let result = JSON.parse(JSON.stringify(datas));
        let data = result.find(z => z.id === id);
        const value = e.target.value;
        const target = e.target.name;
        if (!data) return;
        data[target] = value;
        setDatas(result);
        changedRow(id);
    }

    const onRowSelcted = (e, id) => {
        let result = JSON.parse(JSON.stringify(datas));
        let data = result.find(z => z.id === id);
        if (!data) return;
        data.selected = !data.selected;
        setDatas(result);
    }

    const onRowSelctedAll = (value) => {
        let result = JSON.parse(JSON.stringify(datas));
        result.forEach(res => {
            res.selected = value;
        });
        setDatas(result);
    }

    const onDeleteSelected = () => {
        if (!datas || !datas.find(z => z.selected)) return;
        let ids = [];
        datas.forEach(dt => {
            if (dt.selected && dt.id)
                ids.push(dt.id)
        });

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setIsLoading(true)
                const params = { body: { ids, clientId: props.clientId } }
                DELETE('streamDevicesREST', '/devices', params).then(() => {
                    refreshPage();
                    Swal.fire('Deleted!', 'Your selected rows has been deleted.', 'success');
                }).catch(() => {
                    Swal.fire('Fetch Data Error', 'Oops, Error occurred while delete people.', 'error');
                }).finally(() => setIsLoading(false))
            }
        })
    }

    const handleConfig = (config) => {
        if (config) {
            setTempCameraConfig({...config.configuration});
            setUpdateId(config.id);
            setNewItem(false);
        } else {
            setNewItem(true);
        }
        setConfigShow(true);
    }

    const handleClose = () => {
        setConfigShow(false);
    }

    const updateConfig = () => {
        let result = JSON.parse(JSON.stringify(datas));
        debugger;
        if (newItem == false) {
            let data = result.find(z => z.id === updateId);
            if (!data) return;
            data["configuration"] = tempCameraConfig;
            data["configuration" + "Edit"] = true;
            setDatas(result);
            changedRow(updateId);
        }
        setConfigShow(false);
    }

    const handleCheckBox = (item) => {
        let currentConfig = tempCameraConfig;
        currentConfig[item] = !currentConfig[item];
        if (item == "4G")
            currentConfig["wifi"] = !currentConfig["wifi"];

        if (item == "wifi")
            currentConfig["4G"] = !currentConfig["4G"];

        setTempCameraConfig({ ...currentConfig });
    }

    const handleDropdown = (item, value) => {
        let currentConfig = tempCameraConfig;
        currentConfig[item] = value;
        setTempCameraConfig({ ...currentConfig });
    }

    return (
        <Fragment>
            <Row>
                <Col md={12}>
                    <ButtonGroup size="sm" style={{ float: "right", marginBottom: 50 }}>
                        <Button disabled={isLoading} onClick={() => {
                            if (datas && datas.every(z => z.selected))
                                onRowSelctedAll(false);
                            else
                                onRowSelctedAll(true);
                        }} variant="light tulu-btn-light" style={{
                            marginRight:
                                datas && datas.find(z => z.selected) ? 20 : 0
                        }}>
                            {datas && datas.every(z => z.selected) ?
                                'Deselect All' : 'Select all'}
                        </Button>
                        {datas && datas.find(z => z.selected) ? <Button disabled={isLoading} variant='light tulu-btn-light' onClick={onDeleteSelected}>Delete Selected</Button> : ""}
                        {((changedFileds && changedFileds.length) || isAdd) ?
                            <Fragment>
                                <Button disabled={isLoading} style={{ marginLeft: 20, marginRight: 20 }} variant='warning' onClick={refreshPage}>
                                    {isAdd ? 'Discard' : 'Discard Changes'}
                                </Button>
                                <Button disabled={isLoading} variant='primary tulu-primary' onClick={onSaveChange}>
                                    {isAdd ? 'Save' : 'Save Changes'}
                                </Button>
                            </Fragment>
                            : ""}
                    </ButtonGroup>
                </Col>
                <Col md={12}>
                    <div className='className'>
                        <table className="responsive">
                            <thead>
                                <tr>
                                    <th width="3%"></th>
                                    <th width="3%">#</th>
                                    <th>Place</th>
                                    <th>People</th>
                                    <th>Name</th>
                                    <th>Camera ID</th>
                                    <th>Activation Code</th>
                                    <th>Setting</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isLoading && !isAdd &&
                                    <tr>
                                        <td></td>
                                        <td className='add-icon'>+</td>
                                        <td>
                                            <button className='btn btn-link btn-add' onClick={() => setIsAdd(true)}>Add a camera</button>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>}
                                {!isLoading && isAdd &&
                                    <tr className='active'>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <Select options={places}
                                                value={newObj.place}
                                                isLoading={isLoading}
                                                isDisabled={isLoading}
                                                className="basic-single"
                                                classNamePrefix="select"
                                                onChange={(e) => onSaveDropdownChange(e, 'place')} />
                                        </td>
                                        <td>
                                            <Select options={people}
                                                value={newObj.people}
                                                isLoading={isLoading}
                                                isDisabled={isLoading}
                                                className="basic-single"
                                                classNamePrefix="select"
                                                onChange={(e) => onSaveDropdownChange(e, 'people')} />
                                        </td>
                                        <td>
                                            <input className='form-control' placeholder='Enter Name' value={newObj.name}
                                                onChange={(e) => onNewFiledsChnage(e)} name='name' />
                                        </td>
                                        <td>
                                            <input className='form-control' placeholder='Enter Camera ID' value={newObj.cameraId}
                                                onChange={(e) => onNewFiledsChnage(e)} name='cameraId' />
                                        </td>
                                        <td>
                                            <input className='form-control' placeholder='Enter Activation Code' value={newObj.activationCode}
                                                onChange={(e) => onNewFiledsChnage(e)} name='activationCode' />
                                        </td>
                                        <td style={{ padding: '15px 20px' }}><Button onClick={() => {handleConfig()}} variant="outline-primary">Change</Button>{' '}</td>
                                    </tr>}
                                {isLoading &&
                                    <tr style={{ backgroundColor: '#ffffff' }}>
                                        {
                                            [...Array(8)].map((item, index) => {
                                                return (<td style={{ backgroundColor: '#ffffff' }} key={(index + 1).toString()}>
                                                    <ContentLoader viewBox="0 0 20 3">
                                                        <rect x="0" y="0" rx="0" ry="0" width="20" height="3" />
                                                    </ContentLoader>
                                                </td>)
                                            })
                                        }
                                    </tr>
                                }
                                {!isLoading && datas && datas.map((dt, index) => {
                                    return (
                                        <tr key={index + 1}>
                                            <td>
                                                <input type={'checkbox'} checked={dt.selected} onChange={(e) => onRowSelcted(e, dt.id)} />
                                            </td>
                                            <td>
                                                <span className='row-num' onClick={(e) => onRowSelcted(e, dt.id)}>{index + 1}</span>
                                            </td>
                                            <td onClick={() => onEditColumn(dt.id, 'place')}
                                                className={dt.placeEdit ? 'active' : ''}>
                                                {dt.placeEdit ?
                                                    <div className='d-flex align-items-center'>
                                                        <Select options={places}
                                                            value={dt.place}
                                                            isLoading={isLoading}
                                                            isDisabled={isLoading}
                                                            className="basic-single w-100"
                                                            classNamePrefix="select"
                                                            onChange={(e) => onEditDropdownChange(e, dt.id, 'place')} />
                                                    </div> :
                                                    <span>{dt.place ? dt.place.label : ""}</span>
                                                }
                                            </td>
                                            <td onClick={() => onEditColumn(dt.id, 'people')}
                                                className={dt.peopleEdit ? 'active' : ''}>
                                                {dt.peopleEdit ?
                                                    <div className='d-flex align-items-center'>
                                                        <Select options={people}
                                                            value={dt.people}
                                                            isLoading={isLoading}
                                                            isDisabled={isLoading}
                                                            className="basic-single w-100"
                                                            classNamePrefix="select"
                                                            onChange={(e) => onEditDropdownChange(e, dt.id, 'people')} />
                                                    </div> :
                                                    <span>{dt.people ? dt.people.label : ""}</span>
                                                }
                                            </td>
                                            <td onClick={() => onEditColumn(dt.id, 'name')}
                                                className={dt.nameEdit ? 'active' : ''}>
                                                {dt.nameEdit ?
                                                    <div className='d-flex align-items-center'>
                                                        <input className='form-control' name='name' placeholder='Name' value={dt.name}
                                                            onChange={(e) => onEditFieldChange(e, dt.id)}
                                                            onBlur={() => onBlur(dt.id, 'nameEdit')} />
                                                    </div> :
                                                    <span>{dt.name}</span>
                                                }
                                            </td>
                                            <td onClick={() => onEditColumn(dt.id, 'camera_id')}
                                                className={dt.camera_idEdit ? 'active' : ''}>
                                                {dt.camera_idEdit ?
                                                    <div className='d-flex align-items-center'>
                                                        <input className='form-control' name='camera_id' placeholder='Camera ID' value={dt.camera_id}
                                                            onChange={(e) => onEditFieldChange(e, dt.id)}
                                                            onBlur={() => onBlur(dt.id, 'camera_idEdit')} />
                                                    </div>
                                                    :
                                                    <span>{dt.camera_id}</span>
                                                }
                                            </td>
                                            <td onClick={() => onEditColumn(dt.id, 'activation_code')}
                                                className={dt.activation_codeEdit ? 'active' : ''}>
                                                {dt.activation_codeEdit ?
                                                    <div className='d-flex align-items-center'>
                                                        <input className='form-control' name='activation_code' placeholder='Activation Code' value={dt.activation_code}
                                                            onChange={(e) => onEditFieldChange(e, dt.id)}
                                                            onBlur={() => onBlur(dt.id, 'activation_codeEdit')} />
                                                    </div>
                                                    :
                                                    <span>{dt.activation_code}</span>
                                                }
                                            </td>
                                            <td><Button onClick={()=>handleConfig(dt)} variant="outline-primary">Change</Button>{' '}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
            <Modal show={configShow} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Change Camera Setting</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {tempCameraConfig &&
                        <Form>
                            <Form.Check
                                type="switch"
                                label={`Auto Live Stream`}
                                id={`stream`}
                                onChange={() => handleCheckBox("stream")}
                                checked={tempCameraConfig && tempCameraConfig["stream"]}
                            />
                            <Form.Check
                                type="switch"
                                label={`Auto Offline Recording`}
                                id={`offline_record`}
                                onChange={() => handleCheckBox("offline_record")}
                                checked={tempCameraConfig && tempCameraConfig["offline_record"]}
                            />
                            <Form.Check
                                type="switch"
                                label={`Auto Logging`}
                                id={`log`}
                                onChange={() => handleCheckBox("log")}
                                checked={tempCameraConfig && tempCameraConfig["log"]}
                            />
                            <Form.Check
                                type="switch"
                                label={`Video Rec Button`}
                                id={`videorec_button`}
                                onChange={() => handleCheckBox("videorec_button")}
                                checked={tempCameraConfig && tempCameraConfig["videorec_button"]}
                            />
                            <Form.Check
                                type="switch"
                                label={`Live Socket Connection`}
                                id={`socket`}
                                onChange={() => handleCheckBox("socket")}
                                checked={tempCameraConfig && tempCameraConfig["socket"]}
                            />
                            <Form.Check
                                type="switch"
                                label={`4G Connection`}
                                id={`4G`}
                                onChange={() => handleCheckBox("4G")}
                                checked={tempCameraConfig && tempCameraConfig["4G"]}
                            />
                            {/* {tempCameraConfig && tempCameraConfig["4G"] === true &&
                        <Form.Label htmlFor="codec">haji 4G</Form.Label> } */}
                            <Form.Check
                                type="switch"
                                label={`Wifi Connection`}
                                id={`wifi`}
                                onChange={() => handleCheckBox("wifi")}
                                checked={tempCameraConfig && tempCameraConfig["wifi"]}
                            />
                            {/* {tempCameraConfig && tempCameraConfig["wifi"] === true &&
                        <Form.Label htmlFor="codec">haji wifi</Form.Label> } */}
                            <br />
                            <Form.Label htmlFor="codec">Video Codec</Form.Label>
                            <Form.Select aria-label="Video Codec" onChange={(e) => handleDropdown("codec", e.target.value)} id={`codec`} defaultValue={tempCameraConfig && tempCameraConfig["code"]}>
                                <option value="h264">H264</option>
                                <option value="h265">H265</option>
                            </Form.Select><br />
                            <Form.Label htmlFor="resolution">Video Resolution</Form.Label>
                            <Form.Select aria-label="Video Codec" onChange={(e) => handleDropdown("codec", e.target.value)} id={`resolution`} defaultValue={tempCameraConfig && tempCameraConfig["resolution"]}>
                                <option value="1280x768">1280x768</option>
                                <option value="640x480">640x480</option>
                            </Form.Select>
                        </Form>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={updateConfig}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
})

export default CamerasTable;