import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Layout from './Layout';
import { Context } from '../utils/state/Store';

const PrivateRoute = ({ component: Component, title, theme, darkMode, ...rest }) => {
    const [globalState, dispatch] = useContext(Context);
    return (
        <Route {...rest} render={props => (
            globalState.authentication ?
                <Layout title={title} theme={theme} darkMode={darkMode}>
                    <Component {...props} />
                </Layout>
                : <Redirect to="/Login" />
        )} />
    );
};

export default PrivateRoute;