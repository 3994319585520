import { Auth } from 'aws-amplify';
import React, { useEffect, Fragment, useState, forwardRef, useImperativeHandle } from 'react';
import { Button, ButtonGroup, Col, Row, Table } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { BiSave } from "react-icons/bi";
import { MdOutlineCancel } from "react-icons/md";
import ContentLoader from 'react-content-loader';
import { PUT, POST, GET, DELETE } from '../../../utils/api';
import { validateEmail } from '../../../utils/utilities';

const UsersTable = forwardRef((props, ref) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [newObj, setNewObj] = useState({});
    const [datas, setDatas] = useState([]);
    const [errors, setErrors] = useState([]);
    const [changedFileds, setChangedFileds] = useState([]);

    useEffect(() => {
        fetchUsers();
    }, []);

    useImperativeHandle(ref, () => ({
        onAddClicked() {
            setIsAdd(true)
        }
    }))

    useEffect(() => {
        refreshPage();
    }, [props.clientId]);

    const refreshPage = () => {
        setDatas([]);
        setNewObj({});
        setIsAdd(false);
        setChangedFileds([]);
        fetchUsers();
    }

    const fetchUsers = () => {
        setIsLoading(true);
        let url = `/users?clientId=` + props.clientId;
        GET('streamUsersREST', url).then(response => {
            if (response && response.result) {
                const result = response.result;
                setDatas(result);
            }
        }).catch((err) => {
            console.error("Failed to fetching users -> ", err);
            Swal.fire('Fetch Data Error', 'Oops, Error occurred while fetching users.', 'error');
        }).finally(() => setIsLoading(false))
    }

    const onEditColumn = (id, name) => {
        let result = JSON.parse(JSON.stringify(datas));
        result = result.map((res) => {
            let keys = Object.keys(res);
            keys.forEach(key => {
                if (key.includes('Edit'))
                    delete res[key];
            });

            return res;
        })

        let data = result.find(z => z.id === id);
        if (!data) return;
        data[name + "Edit"] = true;
        setDatas(result);
    }

    const onFullnameChange = (value, id) => {
        let result = JSON.parse(JSON.stringify(datas));
        let data = result.find(z => z.id === id);
        if (!data) return;
        data.fullname = value;
        setDatas(result);
        changedRow(id);
    }

    const onEmailChange = (value, id) => {
        let result = JSON.parse(JSON.stringify(datas));
        let data = result.find(z => z.id === id);
        if (!data) return;
        data.email = value;
        if (!validateEmail(data.email))
            data.emailError = true;
        else
            data.emailError = false;
        setDatas(result);
        changedRow(id);
    }

    const onPhoneChange = (value, id) => {
        let result = JSON.parse(JSON.stringify(datas));
        let data = result.find(z => z.id === id);
        if (!data) return;
        data.phone = value;
        setDatas(result);
        changedRow(id);
    }

    const changedRow = (id) => {
        let result = JSON.parse(JSON.stringify(changedFileds));
        if (!result.find(z => z === id)) {
            result.push(id)
            setChangedFileds(result);
        }
    }

    const onBlur = (id, objName) => {
        let result = JSON.parse(JSON.stringify(datas));
        let data = result.find(z => z.id === id);
        if (!data) return;
        delete data[objName];
        setDatas(result);
    }

    const onNewFiledsChnage = (e) => {
        const target = e.target.name;
        let value = e.target.value

        let result = JSON.parse(JSON.stringify(newObj));
        result[target] = value;
        removeError(target);
        setNewObj(result);
    }

    const removeError = (name) => {
        if (!name) return;
        if (!errors) return;
        let errorsRes = JSON.parse(JSON.stringify(errors));
        switch(name) {
            case 'fullname':
                errorsRes = errors.filter(z => z !== 'newFullnameError');
                break;
            case 'email':
                errorsRes = errors.filter(z => z !== 'newEmailError');
                errorsRes = errors.filter(z => z !== 'newEmailFormatError');
                break;
            case 'phone':
                errorsRes = errors.filter(z => z !== 'newPhoneError');
                break;
            case 'username':
                errorsRes = errors.filter(z => z !== 'newUsernameError');
                break;
            case 'password':
                errorsRes = errors.filter(z => z !== 'newPasswordError');
                errorsRes = errors.filter(z => z !== 'newPasswordFormatError');
                break;
            default:
                return
        }

        setErrors(errorsRes);
    }

    const isFormValid = () => {
        let result = true;
        let errors = [];
        if (!newObj) return false;
        if (!newObj.phone) {
            result = false;
            errors.push('newPhoneError');
        }
        if (!newObj.email) {
            result = false;
            errors.push('newEmailError');
        }
        if (!newObj.username) {
            result = false;
            errors.push('newUsernameError');
        }
        if (!newObj.fullname) {
            result = false;
            errors.push('newFullnameError');
        }

        if (!newObj.password) {
            result = false;
            errors.push('newPasswordError');
        }

        if (newObj.password && newObj.password.length && newObj.password.length <= 8) {
            result = false;
            errors.push('newPasswordFormatError');
        }

        if (!validateEmail(newObj.email)) {
            result = false;
            errors.push('newEmailFormatError');
        }

        setErrors(errors);
        return result;
    }

    const onSaveChangeClick = () => {
        if (!props.clientId || isLoading) return;
        if (isAdd && !isFormValid()) return Swal.fire('Progress failed', 'Please fill all fields.', 'error');
        let result = [];
        changedFileds.forEach(id => {
            let findChanges = datas.find(z => z.id === id);
            if (findChanges)
                result.push({
                    id: findChanges.id,
                    fullname: findChanges.fullname,
                    email: findChanges.email,
                    phone: findChanges.phone,
                    username: findChanges.username
                })
        });
        setIsLoading(true);

        return Promise.resolve().then(() => {
            if (isAdd)
                return checkUnique();
        }).then(() => {
            if (isAdd)
                return addNew();
        }).then(() => {
            if (result && result.length)
                return updateRows(result);
        }).then(() => {
            refreshPage();
            Swal.fire('Successfully updated', 'Client successfully updated.', 'success');
        }).catch((err) => {
            setIsLoading(false);
            if (err && err.message && err.message.includes('Email is not unique')) {
                setErrors(['newEmailError']);
                return Swal.fire('Email already exist', 'Oops, Error occurred while adding Client.', 'error');
            }
            if (err && err.message && err.message.includes('Phone is not unique')) {
                setErrors(['newPhoneError']);
                return Swal.fire('Phone already exist', 'Oops, Error occurred while adding Client.', 'error');
            }
            if (err && err.message && err.message.includes('Username is not unique')) {
                setErrors(['newUsernameError']);
                return Swal.fire('Username already exist', 'Oops, Error occurred while adding Client.', 'error');
            }
            Swal.fire('Add Data Error', 'Oops, Error occurred while adding User.', 'error');
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const checkUnique = () => {
        const params = {
            body: {
                phone: newObj.phone,
                email: newObj.email,
                fullname: newObj.fullname,
                username: newObj.username,
                password: newObj.password,
                clientId: props.clientId
            }
        }
        return POST('streamUsersREST', '/users/unique', params);
    }

    const addNew = () => {
        const params = {
            body: {
                phone: newObj.phone,
                email: newObj.email,
                fullname: newObj.fullname,
                username: newObj.username,
                password: newObj.password,
                clientId: props.clientId
            }
        }
        return POST('streamUsersREST', '/users', params);
    }

    const updateRows = (rows) => {
        const params = { body: { clients: rows } }
        return PUT('streamUsersREST', '/users/bulk', params);
    }

    const onRowSelcted = (e, id) => {
        let result = JSON.parse(JSON.stringify(datas));
        let data = result.find(z => z.id === id);
        if (!data) return;
        data.selected = !data.selected;
        setDatas(result);
    }

    const onRowSelctedAll = (value) => {
        let result = JSON.parse(JSON.stringify(datas));
        result.forEach(res => {
            res.selected = value;
        });
        setDatas(result);
    }

    const onDeleteSelected = () => {
        if (!datas || !datas.find(z => z.selected)) return;
        let ids = [];
        datas.forEach(dt => {
            if (dt.selected && dt.id)
                ids.push(dt.id)
        });

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setIsLoading(true)
                const params = { body: { ids, clientId: props.clientId } }
                DELETE('streamUsersREST', '/users', params).then(() => {
                    refreshPage();
                    Swal.fire('Deleted!', 'Your selected rows has been deleted.', 'success');
                }).catch(() => {
                    Swal.fire('Fetch Data Error', 'Oops, Error occurred while fetching places.', 'error');
                }).finally(() => setIsLoading(false))
            }
        })
    }

    return (
        <Fragment>
            <Row>
                <Col md={12}>
                    <ButtonGroup size="sm" style={{ float: "right", marginBottom: 50 }}>
                        <Button disabled={isLoading} onClick={() => {
                            if (datas && datas.every(z => z.selected))
                                onRowSelctedAll(false);
                            else
                                onRowSelctedAll(true);
                        }} variant="light tulu-btn-light" style={{
                            marginRight:
                                datas && datas.find(z => z.selected) ? 20 : 0
                        }}>
                            {datas && datas.every(z => z.selected) ?
                                'Deselect All' : 'Select all'}
                        </Button>
                        {datas && datas.find(z => z.selected) ? <Button disabled={isLoading} variant='light tulu-btn-light' onClick={onDeleteSelected}>Delete Selected</Button> : ""}
                        {((changedFileds && changedFileds.length) || isAdd) ?
                            <Fragment>
                                <Button disabled={isLoading} style={{ marginLeft: 20, marginRight: 20 }} variant='warning' onClick={refreshPage}>Discard Changes</Button>
                                <Button disabled={isLoading} variant='primary tulu-primary' onClick={onSaveChangeClick}>Save Changes</Button>
                            </Fragment>
                            : ""}
                    </ButtonGroup>
                </Col>
                <Col md={12}>
                    <div className='className'>
                        <table className="responsive">
                            <thead>
                                <tr>
                                    <th width="3%"></th>
                                    <th width="3%">#</th>
                                    <th>Full Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Username </th>
                                    {isAdd && <th>Password</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {!isLoading && !isAdd &&
                                    <tr>
                                        <td></td>
                                        <td className='add-icon'>
                                            +
                                        </td>
                                        <td>
                                            <button className='btn btn-link btn-add' onClick={() => setIsAdd(true)}>Add a user</button>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>

                                }
                                {!isLoading && isAdd &&
                                    <tr className='active'>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <input className={'form-control ' + (errors.find(z => z === 'newFullnameError') ? "is-invalid" : "")}
                                                placeholder='Enter Full Name' value={newObj.fullname}
                                                onChange={(e) => onNewFiledsChnage(e)} name='fullname' />
                                        </td>
                                        <td>
                                            <input className={'form-control ' + (errors.find(z => z === 'newEmailError' || z === 'newEmailFormatError') ? "is-invalid" : "")}
                                                placeholder='Enter Email Address' value={newObj.email}
                                                onChange={(e) => onNewFiledsChnage(e)} name='email' />
                                        </td>
                                        <td>
                                            <input className={'form-control ' + (errors.find(z => z === 'newPhoneError') ? "is-invalid" : "")}
                                                placeholder='Enter Phone Number' value={newObj.phone}
                                                onChange={(e) => onNewFiledsChnage(e)} name='phone' />
                                        </td>
                                        <td>
                                            <input className={'form-control ' + (errors.find(z => z === 'newUsernameError') ? "is-invalid" : "")}
                                                placeholder='Enter Username' value={newObj.username}
                                                onChange={(e) => onNewFiledsChnage(e)} name='username' />
                                        </td>
                                        <td>
                                            <input className={'form-control ' + (errors.find(z => z === 'newPasswordError' || z === 'newPasswordFormatError') ? "is-invalid" : "")}
                                                placeholder='Enter Password' value={newObj.password}
                                                onChange={(e) => onNewFiledsChnage(e)} name='password' />
                                        </td>
                                    </tr>}
                                {isLoading &&
                                    <tr style={{ backgroundColor: '#ffffff' }}>
                                        {
                                            [...Array(6)].map((item, index) => {
                                                return (
                                                    <td style={{ backgroundColor: '#ffffff' }} key={(index + 1).toString()}>
                                                        <ContentLoader viewBox="0 0 20 3">
                                                            <rect x="0" y="0" rx="0" ry="0" width="20" height="3" />
                                                        </ContentLoader>
                                                    </td>)
                                            })
                                        }
                                    </tr>
                                }
                                {!isLoading && datas && datas.map((dt, index) => {
                                    return (
                                        <tr key={index + 1}>
                                            <td>
                                                <input type={'checkbox'} checked={dt.selected} onChange={(e) => onRowSelcted(e, dt.id)} />
                                            </td>
                                            <td>
                                                <span className='row-num' onClick={(e) => onRowSelcted(e, dt.id)}>{index + 1}</span>
                                            </td>
                                            <td onClick={() => onEditColumn(dt.id, 'fullname')}
                                                className={dt.fullnameEdit ? 'active' : ''}>
                                                {dt.fullnameEdit ?
                                                    <div className='d-flex align-items-center'>
                                                        <input className='form-control' name='fullname' placeholder='Name' value={dt.fullname}
                                                            onChange={(e) => onFullnameChange(e.target.value, dt.id)}
                                                            onBlur={() => onBlur(dt.id, 'fullnameEdit')} />
                                                    </div> :
                                                    <span>{dt.fullname}</span>
                                                }
                                            </td>
                                            <td onClick={() => onEditColumn(dt.id, 'email')}
                                                className={dt.emailEdit ? 'active' : ''}>
                                                {dt.emailEdit ?
                                                    <div className='d-flex align-items-center'>
                                                        <input className={'form-control' + (dt.emailError ? " is-invalid" : "")} name='email' placeholder='Email' value={dt.email}
                                                            onChange={(e) => onEmailChange(e.target.value, dt.id)}
                                                            onBlur={() => onBlur(dt.id, 'emailEdit')} />
                                                    </div>
                                                    :
                                                    <span>{dt.email}</span>
                                                }
                                            </td>
                                            <td onClick={() => onEditColumn(dt.id, 'phone')}
                                                className={dt.phoneEdit ? 'active' : ''}>
                                                {dt.phoneEdit ?
                                                    <div className='d-flex align-items-center'>
                                                        <input className='form-control' name='phone' placeholder='Phone' value={dt.phone}
                                                            onChange={(e) => onPhoneChange(e.target.value, dt.id)}
                                                            onBlur={() => onBlur(dt.id, 'phoneEdit')} />
                                                    </div>
                                                    :
                                                    <span>{dt.phone}</span>
                                                }
                                            </td>
                                            <td onClick={() => onEditColumn(dt.id, 'username')}>
                                                <span>{dt.username}</span>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
        </Fragment>
    )
})

export default UsersTable;