import React, { useState } from 'react';
import { Col, Navbar, Nav, Stack, Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import PageHeader from './PageHeader';
import { BsList } from "react-icons/bs";
import { Auth } from 'aws-amplify';
import { Animated } from "react-animated-css";

const Layout = ({ children, title, theme, darkMode }) => {
    let history = useHistory();
    const [expanded, setExpanded] = useState(false);
    const handleLogout = async () => {
        let loadingDiv = document.getElementById('main-live-loading');
        if (loadingDiv) loadingDiv.style = "display:block";
        localStorage.removeItem("accountData");
        localStorage.removeItem("owner");
        await Auth.signOut();
        history.push("/login");
    }
    return (
        <React.Fragment>
            <Container fluid className="app-body dark-mode d-md-flex p-0">
                <Navbar bg="dark" collapseOnSelect={true} fixed="top" variant="dark" expand="lg" className="d-sm-block d-md-block d-lg-none mobile-nav">
                    <Navbar.Brand onClick={() => history.push("/home")}>
                        <img
                            src="/images/Tulu_logo.svg"
                            height="25"
                            className="brand brand-dark"
                            alt="Tulu Logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className={"w-nav tulu-nav-active" + (expanded ? " active" : "")}
                        onClick={() => setExpanded(expanded ? false : true)}>
                        <BsList className='toggle-icon-mobile' />
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Animated animationIn="slideInDown" animationOut="slideOutUp" animationInDuration={250} animationOutDuration={250} isVisible={expanded}>
                                <Nav.Link className="mobile-link"
                                    eventKey="0"
                                    onClick={() => {
                                        setExpanded(false);
                                        history.push("/home");
                                    }}>
                                    <svg className="nav-link-icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 17.151"><path d="M10.2,20.564a.857.857,0,0,1-.583-.231,7.951,7.951,0,0,1,0-11.533.857.857,0,0,1,1.165,1.251,6.255,6.255,0,0,0,0,9.031.857.857,0,0,1-.583,1.482Zm-3.427,2.3a.857.857,0,0,0,0-1.208,9.793,9.793,0,0,1,0-14.172A.87.87,0,1,0,5.563,6.229a11.516,11.516,0,0,0,0,16.674A.857.857,0,0,0,6.78,22.86Zm11.593-2.528a7.951,7.951,0,0,0,0-11.533.858.858,0,1,0-1.174,1.251,6.229,6.229,0,0,1,0,9.031.858.858,0,0,0,1.174,1.251Zm4.044,2.57a11.49,11.49,0,0,0,0-16.674A.857.857,0,0,0,21.243,7.48a9.793,9.793,0,0,1,0,14.172A.855.855,0,1,0,22.408,22.9Zm-6.7-8.337a1.714,1.714,0,1,0-1.714,1.714A1.714,1.714,0,0,0,15.708,14.566Z" transform="translate(-1.992 -5.985)"></path></svg>
                                    Home</Nav.Link>
                                <Nav.Link className="mobile-link"
                                    eventKey="1"
                                    onClick={() => {
                                        setExpanded(false);
                                        history.push("/places");
                                    }}>
                                    <svg className="nav-link-icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 19.86 18.048"><g transform="translate(4.8 -8)"><path d="M31.889,181.207H34.1a.91.91,0,0,0,.922-.9v-3.774h4.363v3.774a.91.91,0,0,0,.922.9H42.52a2.935,2.935,0,0,0,1.124-.221,2.884,2.884,0,0,0,.918-.6,2.8,2.8,0,0,0,.619-.895,2.737,2.737,0,0,0,.227-1.1V173H44v5.391a1.4,1.4,0,0,1-.115.559,1.435,1.435,0,0,1-.317.457,1.474,1.474,0,0,1-.469.309,1.5,1.5,0,0,1-.574.112H40.8v-3.295a1.4,1.4,0,0,0-1.413-1.378H35.023a1.4,1.4,0,0,0-1.413,1.378v3.295H31.889a1.5,1.5,0,0,1-.574-.112,1.473,1.473,0,0,1-.469-.309,1.433,1.433,0,0,1-.317-.457,1.4,1.4,0,0,1-.115-.559V173H29v5.391a2.737,2.737,0,0,0,.227,1.1,2.8,2.8,0,0,0,.619.895,2.884,2.884,0,0,0,.918.6A2.935,2.935,0,0,0,31.889,181.207Z" transform="translate(-32.075 -155.159)" fillRule="evenodd"></path><path d="M2.109,17.332l-1.8,4.543a1.358,1.358,0,0,0-.105.649,3.121,3.121,0,0,0,3.137,2.72h0a3.2,3.2,0,0,0,2.262-.925,3.2,3.2,0,0,0,2.262.925h0a3.2,3.2,0,0,0,2.262-.925,3.2,3.2,0,0,0,2.262.925h0a3.2,3.2,0,0,0,2.262-.925,3.2,3.2,0,0,0,2.262.925h0a3.12,3.12,0,0,0,3.138-2.73,1.358,1.358,0,0,0-.1-.631l-1.743-4.551A3.669,3.669,0,0,0,14.774,15H5.546A3.669,3.669,0,0,0,2.109,17.332Zm12.666-.954H5.546a2.263,2.263,0,0,0-2.12,1.438L1.732,21.861a1.141,1.141,0,0,0-.1.679,1.737,1.737,0,0,0,1.71,1.339h0a1.772,1.772,0,0,0,1.19-.453,1.892,1.892,0,0,1,.782-.485,1.214,1.214,0,0,1,.58,0,1.892,1.892,0,0,1,.782.485,1.772,1.772,0,0,0,1.19.453h0a1.772,1.772,0,0,0,1.19-.453,1.893,1.893,0,0,1,.783-.485,1.215,1.215,0,0,1,.579,0,1.893,1.893,0,0,1,.783.485,1.772,1.772,0,0,0,1.19.453h0a1.772,1.772,0,0,0,1.19-.453,1.892,1.892,0,0,1,.783-.485,1.213,1.213,0,0,1,.579,0,1.892,1.892,0,0,1,.783.485,1.772,1.772,0,0,0,1.19.453h0a1.736,1.736,0,0,0,1.713-1.35,1.141,1.141,0,0,0-.094-.66l-1.641-4.054A2.262,2.262,0,0,0,14.774,16.378Z" transform="translate(-5 -7)" fillRule="evenodd"></path></g></svg>
                                    Places</Nav.Link>
                                <Nav.Link className="mobile-link"
                                    eventKey="2"
                                    onClick={() => {
                                        setExpanded(false);
                                        history.push("/people");
                                    }}>
                                    <svg className="nav-link-icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 17.743 16.586"><g transform="translate(0 -11)"><path d="M191.046,12.256a2.261,2.261,0,0,0-1.343.439,5.211,5.211,0,0,0-.778-.986,3.526,3.526,0,1,1,.917,6.133,5.154,5.154,0,0,0,.456-1.171,2.271,2.271,0,1,0,.748-4.416Z" transform="translate(-178.611)"></path><path d="M166.716,156.347l.141.007a5.2,5.2,0,0,1,.983.16h.02c.038,0,.089,0,.274,0H171.9c.185,0,.236,0,.274,0a2.02,2.02,0,0,1,1.929,1.929c0,.038,0,.089,0,.274,0,.052,0,.084,0,.108s0,.029,0,.029a.655.655,0,0,1-.625.626h-2.2a5.164,5.164,0,0,1,.361,1.256h1.7c.1,0,.15,0,.192,0a1.911,1.911,0,0,0,1.824-1.824c0-.042,0-.092,0-.192,0-.171,0-.257,0-.329a3.275,3.275,0,0,0-3.127-3.127c-.072,0-.158,0-.329,0h-3.781A5.207,5.207,0,0,1,166.716,156.347Z" transform="translate(-157.615 -136.38)"></path><path d="M8.535,191.427h-3.8c-.36,0-.479,0-.571.007a3.112,3.112,0,0,0-2.9,2.9c-.006.092-.007.211-.007.571,0,.077,0,.125,0,.161s0,.043,0,.043a.655.655,0,0,0,.611.611H11.4a.655.655,0,0,0,.611-.611s0-.008,0-.043,0-.084,0-.161c0-.36,0-.479-.007-.571a3.112,3.112,0,0,0-2.9-2.9C9.014,191.427,8.9,191.427,8.535,191.427ZM.009,194.251C0,194.395,0,194.565,0,194.9c0,.149,0,.223,0,.286a1.911,1.911,0,0,0,1.781,1.781c.063,0,.137,0,.286,0H11.2c.149,0,.223,0,.286,0a1.911,1.911,0,0,0,1.781-1.781c0-.063,0-.137,0-.286,0-.34,0-.51-.009-.653a4.367,4.367,0,0,0-4.071-4.071c-.144-.009-.313-.009-.653-.009h-3.8c-.34,0-.51,0-.653.009A4.367,4.367,0,0,0,.009,194.251Z" transform="translate(0 -169.39)" fillRule="evenodd"></path><path d="M46.447,18.505a3.125,3.125,0,1,0-3.125-3.125A3.125,3.125,0,0,0,46.447,18.505Zm0,1.256a4.38,4.38,0,1,0-4.38-4.38A4.38,4.38,0,0,0,46.447,19.761Z" transform="translate(-39.77)" fillRule="evenodd"></path></g></svg>
                                    People</Nav.Link>
                                <Nav.Link className="mobile-link"
                                    eventKey="3"
                                    onClick={() => {
                                        setExpanded(false);
                                        history.push("/manage");
                                    }}>Manage</Nav.Link>
                                <Nav.Link className="mobile-link"
                                    eventKey="4"
                                    onClick={async () => {
                                        await Auth.signOut();
                                        localStorage.removeItem("accountData");
                                        localStorage.removeItem("owner");
                                        setExpanded(false);
                                        history.push("/login");
                                    }}>Log Out</Nav.Link>
                            </Animated>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                {darkMode === "true" ? "" : <Col className={darkMode === "true" ? "d-none d-lg-block left-nav-dark" : "d-none d-lg-block left-nav"}>
                    <Stack gap={3} className={darkMode === "true" ? "d-none d-lg-block left-nav-dark flex-between" : "d-none d-lg-block left-nav flex-between"}>
                        <div className="navbar-wrap">
                            <a href="/home" aria-current="page" className={darkMode === "true" ? "brand w-nav-brand w--current image-link brand-dark" : "brand w-nav-brand w--current image-link"}>
                                <img src="/images/Tulu_logo.svg" alt="" className="image-2" />
                            </a>
                            {darkMode === "true" ?
                                "" :
                                <Nav className="me-auto nav-menu w-nav-menu">
                                    <a onClick={() => history.push("/home")} className={'d-flex align-items-center ' + (title === "Home" ? "nav-link w-nav-link w--current" : "nav-link w-nav-link")}>
                                        <div className='nav-link-icon-div'><svg className="nav-link-icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 17.151"><path d="M10.2,20.564a.857.857,0,0,1-.583-.231,7.951,7.951,0,0,1,0-11.533.857.857,0,0,1,1.165,1.251,6.255,6.255,0,0,0,0,9.031.857.857,0,0,1-.583,1.482Zm-3.427,2.3a.857.857,0,0,0,0-1.208,9.793,9.793,0,0,1,0-14.172A.87.87,0,1,0,5.563,6.229a11.516,11.516,0,0,0,0,16.674A.857.857,0,0,0,6.78,22.86Zm11.593-2.528a7.951,7.951,0,0,0,0-11.533.858.858,0,1,0-1.174,1.251,6.229,6.229,0,0,1,0,9.031.858.858,0,0,0,1.174,1.251Zm4.044,2.57a11.49,11.49,0,0,0,0-16.674A.857.857,0,0,0,21.243,7.48a9.793,9.793,0,0,1,0,14.172A.855.855,0,1,0,22.408,22.9Zm-6.7-8.337a1.714,1.714,0,1,0-1.714,1.714A1.714,1.714,0,0,0,15.708,14.566Z" transform="translate(-1.992 -5.985)"></path></svg></div>
                                        Home
                                    </a>
                                    <a onClick={() => history.push("/places")} className={'d-flex align-items-center ' + (title === "Places" ? "nav-link w-nav-link w--current" : "nav-link w-nav-link")}>
                                        <div className='nav-link-icon-div'><svg className="nav-link-icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 19.86 18.048"><g transform="translate(4.8 -8)"><path d="M31.889,181.207H34.1a.91.91,0,0,0,.922-.9v-3.774h4.363v3.774a.91.91,0,0,0,.922.9H42.52a2.935,2.935,0,0,0,1.124-.221,2.884,2.884,0,0,0,.918-.6,2.8,2.8,0,0,0,.619-.895,2.737,2.737,0,0,0,.227-1.1V173H44v5.391a1.4,1.4,0,0,1-.115.559,1.435,1.435,0,0,1-.317.457,1.474,1.474,0,0,1-.469.309,1.5,1.5,0,0,1-.574.112H40.8v-3.295a1.4,1.4,0,0,0-1.413-1.378H35.023a1.4,1.4,0,0,0-1.413,1.378v3.295H31.889a1.5,1.5,0,0,1-.574-.112,1.473,1.473,0,0,1-.469-.309,1.433,1.433,0,0,1-.317-.457,1.4,1.4,0,0,1-.115-.559V173H29v5.391a2.737,2.737,0,0,0,.227,1.1,2.8,2.8,0,0,0,.619.895,2.884,2.884,0,0,0,.918.6A2.935,2.935,0,0,0,31.889,181.207Z" transform="translate(-32.075 -155.159)" fillRule="evenodd"></path><path d="M2.109,17.332l-1.8,4.543a1.358,1.358,0,0,0-.105.649,3.121,3.121,0,0,0,3.137,2.72h0a3.2,3.2,0,0,0,2.262-.925,3.2,3.2,0,0,0,2.262.925h0a3.2,3.2,0,0,0,2.262-.925,3.2,3.2,0,0,0,2.262.925h0a3.2,3.2,0,0,0,2.262-.925,3.2,3.2,0,0,0,2.262.925h0a3.12,3.12,0,0,0,3.138-2.73,1.358,1.358,0,0,0-.1-.631l-1.743-4.551A3.669,3.669,0,0,0,14.774,15H5.546A3.669,3.669,0,0,0,2.109,17.332Zm12.666-.954H5.546a2.263,2.263,0,0,0-2.12,1.438L1.732,21.861a1.141,1.141,0,0,0-.1.679,1.737,1.737,0,0,0,1.71,1.339h0a1.772,1.772,0,0,0,1.19-.453,1.892,1.892,0,0,1,.782-.485,1.214,1.214,0,0,1,.58,0,1.892,1.892,0,0,1,.782.485,1.772,1.772,0,0,0,1.19.453h0a1.772,1.772,0,0,0,1.19-.453,1.893,1.893,0,0,1,.783-.485,1.215,1.215,0,0,1,.579,0,1.893,1.893,0,0,1,.783.485,1.772,1.772,0,0,0,1.19.453h0a1.772,1.772,0,0,0,1.19-.453,1.892,1.892,0,0,1,.783-.485,1.213,1.213,0,0,1,.579,0,1.892,1.892,0,0,1,.783.485,1.772,1.772,0,0,0,1.19.453h0a1.736,1.736,0,0,0,1.713-1.35,1.141,1.141,0,0,0-.094-.66l-1.641-4.054A2.262,2.262,0,0,0,14.774,16.378Z" transform="translate(-5 -7)" fillRule="evenodd"></path></g></svg></div>
                                        Places</a>
                                    <a onClick={() => history.push("/people")} className={'d-flex align-items-center ' + (title === "People" ? "nav-link w-nav-link w--current" : "nav-link w-nav-link")}>
                                        <div className='nav-link-icon-div'><svg className="nav-link-icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 17.743 16.586"><g transform="translate(0 -11)"><path d="M191.046,12.256a2.261,2.261,0,0,0-1.343.439,5.211,5.211,0,0,0-.778-.986,3.526,3.526,0,1,1,.917,6.133,5.154,5.154,0,0,0,.456-1.171,2.271,2.271,0,1,0,.748-4.416Z" transform="translate(-178.611)"></path><path d="M166.716,156.347l.141.007a5.2,5.2,0,0,1,.983.16h.02c.038,0,.089,0,.274,0H171.9c.185,0,.236,0,.274,0a2.02,2.02,0,0,1,1.929,1.929c0,.038,0,.089,0,.274,0,.052,0,.084,0,.108s0,.029,0,.029a.655.655,0,0,1-.625.626h-2.2a5.164,5.164,0,0,1,.361,1.256h1.7c.1,0,.15,0,.192,0a1.911,1.911,0,0,0,1.824-1.824c0-.042,0-.092,0-.192,0-.171,0-.257,0-.329a3.275,3.275,0,0,0-3.127-3.127c-.072,0-.158,0-.329,0h-3.781A5.207,5.207,0,0,1,166.716,156.347Z" transform="translate(-157.615 -136.38)"></path><path d="M8.535,191.427h-3.8c-.36,0-.479,0-.571.007a3.112,3.112,0,0,0-2.9,2.9c-.006.092-.007.211-.007.571,0,.077,0,.125,0,.161s0,.043,0,.043a.655.655,0,0,0,.611.611H11.4a.655.655,0,0,0,.611-.611s0-.008,0-.043,0-.084,0-.161c0-.36,0-.479-.007-.571a3.112,3.112,0,0,0-2.9-2.9C9.014,191.427,8.9,191.427,8.535,191.427ZM.009,194.251C0,194.395,0,194.565,0,194.9c0,.149,0,.223,0,.286a1.911,1.911,0,0,0,1.781,1.781c.063,0,.137,0,.286,0H11.2c.149,0,.223,0,.286,0a1.911,1.911,0,0,0,1.781-1.781c0-.063,0-.137,0-.286,0-.34,0-.51-.009-.653a4.367,4.367,0,0,0-4.071-4.071c-.144-.009-.313-.009-.653-.009h-3.8c-.34,0-.51,0-.653.009A4.367,4.367,0,0,0,.009,194.251Z" transform="translate(0 -169.39)" fillRule="evenodd"></path><path d="M46.447,18.505a3.125,3.125,0,1,0-3.125-3.125A3.125,3.125,0,0,0,46.447,18.505Zm0,1.256a4.38,4.38,0,1,0-4.38-4.38A4.38,4.38,0,0,0,46.447,19.761Z" transform="translate(-39.77)" fillRule="evenodd"></path></g></svg></div>
                                        People</a>
                                    <div className='tulu-logout'>
                                        <a onClick={async () => {
                                            history.push("/manage");
                                        }} className={'d-flex align-items-center ' + (title === "Home" ? "nav-link w-nav-link w--current" : "nav-link w-nav-link")}>
                                            Manage
                                        </a>
                                        <a onClick={async () => handleLogout()} className={'d-flex align-items-center ' + (title === "Home" ? "nav-link w-nav-link w--current" : "nav-link w-nav-link")}>
                                            Log Out
                                        </a>
                                    </div>
                                </Nav>}
                        </div>
                    </Stack>
                </Col>}
                <Col className={theme === "true" ? "content-page" : (darkMode === "true" ? "full-width-content-page-dark" : "full-width-content-page")}>
                    {theme === "true" && <PageHeader Title={title} />}
                    {children}
                </Col>
            </Container>
        </React.Fragment>
    );
};
export default Layout;