import React, { useEffect, Fragment, useState, useMemo, useCallback } from 'react'
import Image from 'react-bootstrap/Image'
import { Row, Col, ListGroup, Form, Container, Breadcrumb } from 'react-bootstrap';
import { MdKeyboardBackspace } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import Map, { FullscreenControl, Marker, NavigationControl, Popup } from 'react-map-gl';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import ContentLoader from "react-content-loader"
import MapPin from '../../components/MapPin';
import config from '../../config';
import aws from '../../../config/aws.json';
import Details from '../../components/Details';
import { POST, GET } from '../../utils/api';

const People = (props) => {
  let history = useHistory();
  const [datas, setDatas] = useState([]);
  const [search, setSearch] = useState("");
  const [mapWidth, setMapWidth] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [popupInfo, setPopupInfo] = useState(null);
  const [mapHeight, setMapHeight] = useState(null);
  const [searching, setSearching] = useState(null);
  const [accountData, setAccountData] = useState(null);
  const [dataSelected, setDataSelected] = useState(null);
  const [isDetailVisible, setDetailVisible] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [peopleState, setPeopleState] = useState(false);

  const mapDiv = useCallback(node => {
    if (node !== null) {
      setMapHeight(node.getBoundingClientRect().height);
      setMapWidth(node.getBoundingClientRect().width);
    }
  }, []);

  useEffect(() => {
    document.title = 'People';
    if (props && props.match && props.match.params && props.match.params.id) {
      const PeopleId = props.match.params.id;
      setPeopleState(false);
      setShowSkeleton(true);
      setDetailVisible(true);
      fetchDataById(PeopleId);
    } else {
      fetchData();
    }
  }, []);

  useEffect(() => {
    setShowSkeleton(false);
    setDetailVisible(false);
    fetchData();
  }, [history.location]);

  const fetchDataById = (PeopleId) => {
    if (!PeopleId) history.goBack();
    let pvAccountData;
    try {
      pvAccountData = JSON.parse(localStorage.getItem("accountData"));
      setAccountData(pvAccountData);
    } catch (err) { }

    let url = `/people?clientId=${pvAccountData ? pvAccountData.id : ""}`;

    GET(aws.AWS_FUNCTION.GET_DEVICE_STREAM, url).then(response => {
      if (response && response.result) {
        const result = response.result;
        let final = [];
        result.forEach(res => {
            final.push(res);
        });

        const filteredData = final.filter(data => data.id === PeopleId);
        setDataSelected(filteredData[0]);
        setDetailVisible(true);
        setShowSkeleton(false);
      }
    }).catch((err) => {
      console.log(err)
      Swal.fire('Fetch Data Error', 'Oops, Error occurred while fetching data.', 'error');
    });
  }

  const postSerch = () => {
    if (searching)
      setSearching(clearTimeout(searching));
    setSearching(setTimeout(() => {
      fetchData();
    }, 500))
  }

  const fetchData = () => {
    setLoading(true);
    let pvAccountData;
    try {
      pvAccountData = JSON.parse(localStorage.getItem("accountData"));
      setAccountData(pvAccountData);
    } catch (err) { }

    let url = `/people?clientId=${pvAccountData ? pvAccountData.id : ""}`;
    if (search)
      url += `&search=${search}`;
    GET(aws.AWS_FUNCTION.GET_DEVICE_STREAM, url).then(response => {
      if (response && response.result) {
        const result = response.result;
        let final = [];
        result.forEach(res => {
          final.push(res);
        });
        setDatas(final);
      }
    }).catch(() => {
      Swal.fire('Fetch Data Error', 'Oops, Error occurred while fetching data.', 'error');
    }).finally(() => {
      setLoading(false);
    });
  }

  const onUserClick = (data) => {
    if (!data) return;
    setPeopleState(true);
    setDataSelected(data);
    setDetailVisible(true);
    document.title = data.fullname;
    window.history.replaceState(null, data.fullname, `/people/${data.id}`);
  }

  const onUserBackClick = () => {
    if (props && props.match && props.match.params && props.match.params.id && peopleState === false) {
      history.goBack();
    }
    if (peopleState === true) {
      history.push({
        pathname: '/people'
      })
    }
    setDataSelected(null);
    setDetailVisible(false);
  }

  const noImageBox = (data) => {
    if (!data) return;
    let ttl = '';
    let colorClass = data.detail && data.detail.color ? data.detail.color : "purple";

    if (data.fullname) {
      let matches = data.fullname.match(/\b(\w)/g);
      matches = matches.slice(0, 2);
      ttl = matches.join('');
    }

    return (
      <div className={'avatar ' + colorClass}>
        {ttl ? ttl.toUpperCase() : ""}
      </div>
    )
  }

  const getColorClass = () => {
    const randomNumber = Math.floor(Math.random() * 5) + 1;
    let colorClass = '';
    switch (randomNumber) {
      case 1:
        colorClass = "pink";
        break;
      case 2:
        colorClass = "yellow";
        break;
      case 3:
        colorClass = "green";
        break;
      case 4:
        colorClass = "blue";
        break;
      case 5:
        colorClass = "purple";
        break;
      default:
        colorClass = "purple"
    }

    return colorClass;
  }

  if (!isDetailVisible) {
    return (
      <Fragment>
        <div className='tulu-component-places'>
          <div className='p-side-menu outerdiv'>
            <div className='location-list_search-wrap'>
              <FiSearch className='custom-icon' />
              <Form.Control type="text"
                onChange={(e) => {
                  setSearch(e.target.value)
                  postSerch();
                }}
                value={search}
                placeholder="Type to Search" className='search-input' autoComplete="off"
                onFocus={() => setDatas([])}
                onBlur={() => {
                  if (!search)
                    fetchData();
                }} />
            </div>
            <div className='innerdiv'>
              <ListGroup as="ol" variant="flush" className='items-list'>
                {datas && datas.length ? datas.map((data) => {
                  return (
                    <ListGroup.Item as="li" className='item' onClick={() => onUserClick(data)} key={data.id}>
                      {data.thumbnailUrl ?
                        <Image className='avatar' thumbnail={true} src={'/images/video-preview-thumbnail.png'}></Image>
                        :
                        noImageBox(data)
                      }
                      <div className='details'>
                        <h4 className='title'>{data.fullname}</h4>
                      </div>
                    </ListGroup.Item>
                  )
                }) :
                  <Fragment>
                    {!isLoading &&
                      <div className='no-result'>
                        No Results
                      </div>}
                  </Fragment>}
                {isLoading ?
                  [0, 1, 2, 3, 4, 5, 6].map((skel) => {
                    return (
                      <div style={{ paddingLeft: "2.14rem" }} key={skel}>
                        <ContentLoader
                          speed={3}
                          width={300}
                          height={120}
                          viewBox="0 0 300 120"
                          backgroundColor="#f3f3f3"
                          foregroundColor="#ecebeb">
                          <rect x="6" y="20" rx="0" ry="0" width="80" height="80" />
                          <rect x="94" y="20" rx="0" ry="0" width="138" height="20" />
                          <rect x="94" y="50" rx="0" ry="0" width="200" height="50" />
                        </ContentLoader>
                      </div>
                    )
                  }) : ""
                }
              </ListGroup>
            </div>
          </div>
          <div className='map-side hide-on-mobile'>
            {!isLoading && datas ?
              <Map
                initialViewState={{
                  latitude: datas[0] && datas[0].gps && JSON.parse(datas[0].gps).coordinates[1] ? JSON.parse(datas[0].gps).coordinates[1] : 40.759011,
                  longitude: datas[0] && datas[0].gps && JSON.parse(datas[0].gps).coordinates[0] ? JSON.parse(datas[0].gps).coordinates[0]  : -73.9844722,
                  zoom: 10
                }}
                attributionControl={false}
                style={{ width: "100%", height: "100vh" }}
                mapStyle="mapbox://styles/mapbox/streets-v9"
                mapboxAccessToken={config.MAPBOX_TOKEN}
              >
                <FullscreenControl position="top-left" />
                <NavigationControl position="top-left" showCompass={true} />

                {datas.map((data, index) => {
                  if (data && data.gps && JSON.parse(data.gps))
                    return ((
                      <Marker
                        key={`marker-${index}`}
                        longitude={JSON.parse(data.gps).coordinates[0]}
                        latitude={JSON.parse(data.gps).coordinates[1]}
                        anchor="top"
                      >
                        <MapPin onClick={() => setPopupInfo({
                          name: data.fullname,
                          lat: JSON.parse(data.gps).coordinates[1],
                          long: JSON.parse(data.gps).coordinates[0]
                        })} />
                      </Marker>
                    ))
                })}

                {popupInfo && (
                  <Popup
                    anchor="bottom"
                    longitude={Number(popupInfo.long)}
                    latitude={Number(popupInfo.lat)}
                    closeButton={true}
                    closeOnClick={false}
                    onClose={() => setPopupInfo(null)}
                  >
                    <div style={{ minWidth: 100, padding: 20, fontWeight: "600" }}>
                      {popupInfo.name}
                    </div>
                  </Popup>
                )}

              </Map>
              :
              <ContentLoader
                speed={3}
                width={mapWidth}
                height={mapHeight}
                viewBox={`0 0 ${mapWidth} ${mapHeight}`}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="0" rx="0" ry="0" width={mapWidth} height={mapHeight} />
              </ContentLoader>}
          </div>
        </div>
      </Fragment>
    )
  } else {
    if (showSkeleton) {
      return (
        <Fragment>
          <Col className="page-header-wrap hide-on-desktop custom-back" style={{ paddingTop: 0 }}>
            <Breadcrumb className="page-header" style={{ marginTop: "1.5rem" }} onClick={() => onUserBackClick()}>
              <MdKeyboardBackspace className="back-icon" style={{ marginLeft: "1rem" }} />
              <Col className="breadcrumb-title">Go Back</Col>
            </Breadcrumb>
          </Col>
          <Container fluid >
            <Col className="page-header-wrap padding-special custom-back hide-on-mobile">
              <Breadcrumb className="page-header" style={{ marginTop: "1.5rem" }} onClick={() => onUserBackClick()}>
                <MdKeyboardBackspace className="back-icon" style={{ marginLeft: "1rem" }} />
                <Col className="breadcrumb-title">Go Back</Col>
              </Breadcrumb>
            </Col>
            <Col className='tulu-details'>
              <Container fluid >
                <Col className="page-loading-wrap custom-back hide-on-mobile">
                  <ContentLoader
                    key={1}
                    speed={3}
                    width="100%"
                    height={"80vh"}
                    viewBox="0 0 100% 100vh"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  >
                    <rect x="0" y="0" rx="2" ry="2" width="128" height="128" />
                    <rect x="140" y="20" rx="2" ry="2" width="100" height="15" />
                    <rect x="140" y="50" rx="2" ry="2" width="300" height="15" />
                    <rect x="140" y="85" rx="2" ry="2" width="150" height="10" />
                    <rect x="0" y="180" rx="2" ry="2" width="100%" height="350" />
                  </ContentLoader>
                </Col>
                <Col className="custom-back d-md-none">
                  <ContentLoader
                    key={1}
                    speed={3}
                    width="100%"
                    height={"80vh"}
                    viewBox="0 0 100% 100vh"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  >
                    <rect x="30%" y="0" rx="2" ry="2" width="128" height="128" />
                    <rect x="30%" y="145" rx="2" ry="2" width="128" height="15" />
                    <rect x="0" y="200" rx="2" ry="2" width="100" height="15" />
                    <rect x="0" y="225" rx="2" ry="2" width="100%" height="15" />
                    <rect x="0" y="250" rx="2" ry="2" width="150" height="10" />
                    <rect x="0" y="275" rx="2" ry="2" width="100%" height="200" />
                  </ContentLoader>
                </Col>
              </Container>
            </Col>
          </Container>
        </Fragment>
      )
    } else {
      return (
        <Details isPoeple={true} data={dataSelected} accountData={accountData} onUserBackClick={() => onUserBackClick()} />
      )
    }
  }
}

export default People;
