import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Breadcrumb, Col } from 'react-bootstrap';

const PageHeader = ({Title}) => (
    <Breadcrumb className="page-header">
      <Col className="breadcrumb-title">{Title ? Title : "Untitled"}</Col>
    </Breadcrumb>
)

export default PageHeader
