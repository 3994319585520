import { API } from 'aws-amplify';

export const GET = (apiName, path, params = {}) => {
  return API.get(apiName, path, params)
    .then(responseHandler)
    .catch(errorHandler);
}


export const POST = (apiName, path, params = {}) => {
  return API.post(apiName, path, params)
    .then(responseHandler)
    .catch(errorHandler);
}

export const PUT = (apiName, path, params = {}) => {
  return API.put(apiName, path, params)
    .then(responseHandler)
    .catch(errorHandler);
}

export const DELETE = (apiName, path, params = {}) => {
  return API.del(apiName, path, params)
    .then(responseHandler)
    .catch(errorHandler);
}

const responseHandler = (response) => {
  if (response) {
    return response
  } else {
    throw (new Error('error in response'));
  }
}
const errorHandler = (err) => {
  // if (err && err.message && err.message.startsWith('Request failed with status code 40')) {
  //   window.location = '/activate?return=' + window.location.pathname;
  // }
  throw ({ error: err, message: err && err.response ? JSON.stringify(err.response.data) : err });
}