import React, { Fragment, useEffect, useState } from 'react';
import { Breadcrumb, Col, Container, Image, Row } from 'react-bootstrap';
import { BsBuilding, BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail, MdKeyboardBackspace } from "react-icons/md";
import ContentLoader from "react-content-loader";
import { useHistory } from 'react-router-dom';
import Map, { FullscreenControl, Marker, NavigationControl, Popup } from 'react-map-gl';
import Stream from './Stream';
import MapPin from './MapPin';
import config from '../config';
import Swal from 'sweetalert2';
import { GET, POST } from '../utils/api';
import RecentActivity from './RecentActivity';

const Details = (props) => {
  let history = useHistory();
  const [streams, setStreams] = useState([]);
  const [popupInfo, setPopupInfo] = useState(false);
  const [dataLoad, setDataLoad] = useState(false);
  const [relatedData, setRelatedData]= useState(null);
  const [itemId, setItemId] = useState();

  useEffect(() => {
    if (!props.isPoeple) {
      document.title = 'Place';
      fetchRecentActivity(props.data && props.data.id ? props.data.id : null);
      setItemId(props.data.id);
    } else {
      document.title = 'People';
      fetchPeopleRecentActivity(props.data && props.data.id ? props.data.id : null);
      setItemId(props.data.id);
    }
  }, []);

  const fetchRecentActivity = async (placeId) => {
    if (!placeId) return;
    let url = `/places/${placeId}/activity`;
    GET('streamPlacesREST', url).then(response => {
      if (response && response.result)
        setRelatedData(response.people);
        setDataLoad(true);
    }).catch((err) => {
      console.log(err)
      Swal.fire('Fetch Data Error', 'Oops, Error occurred while fetching recent activity.', 'error');
    });
  }

  const noImageBox = (data, title) => {
    let ttl = '';
    let colorClass = '';

    if (title) {
      let matches = title.match(/\b(\w)/g);
      matches = matches.slice(0, 2);
      ttl = matches.join('');
    }
    colorClass = data.detail && data.detail.color ? data.detail.color : "purple";

    return (
      <div className={'no-image ' + colorClass}>{ttl.toUpperCase()}</div>
    )
  }

  const getColorClass = () => {
    const randomNumber = Math.floor(Math.random() * 5) + 1;
    let colorClass = '';
    switch (randomNumber) {
      case 1:
        colorClass = "pink";
        break;
      case 2:
        colorClass = "yellow";
        break;
      case 3:
        colorClass = "green";
        break;
      case 4:
        colorClass = "blue";
        break;
      case 5:
        colorClass = "purple";
        break;
      default:
        colorClass = "purple"
    }

    return colorClass;
  }

  const fetchPeopleRecentActivity = async (peopleId) => {
    if (!peopleId) return;
    let url = `/people/${peopleId}/activity`;
    GET('streamPeopleREST', url).then(response => {
      if (response && response.result)
        setRelatedData(response.place);
        setDataLoad(true);
    }).catch(() => {
      Swal.fire('Fetch Data Error', 'Oops, Error occurred while fetching recent activity.', 'error');
    });
  }

  const getSkeleton = () => {
    return [...Array(4)].map((skel) => {
      return (
        <ContentLoader
          key={skel}
          speed={3}
          viewBox="0 0 400 400"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          className="video-card-video-wrap"
        >
          <rect x="0" y="0" rx="2" ry="2" width="400" height="260" />
          <rect x="0" y="274" rx="2" ry="2" width="300" height="14" />
          <rect x="0" y="298" rx="2" ry="2" width="220" height="10" />
          <rect x="0" y="318" rx="2" ry="2" width="220" height="10" />
        </ContentLoader>
      )
    });
  }

  const handleOpenPeople = (peopleId) => {
    if (!peopleId) return;
    history.push({
      pathname: `/people/${peopleId}`
    });
  }

  const handleOpenPlace = (placeId) => {
    if (!placeId) return;
    history.push({
      pathname: `/places/${placeId}`
    });
  }

  return (
    <Fragment>
      <Col className="page-header-wrap hide-on-desktop custom-back" style={{ paddingTop: 0 }}>
        <Breadcrumb className="page-header" style={{ marginTop: "1.5rem" }} onClick={() => props.onUserBackClick()}>
          <MdKeyboardBackspace className="back-icon" style={{ marginLeft: "1rem" }} />
          <Col className="breadcrumb-title">Go Back</Col>
        </Breadcrumb>
      </Col>
      <Container fluid >
        <Col className="page-header-wrap padding-special custom-back hide-on-mobile">
          <Breadcrumb className="page-header" style={{ marginTop: "1.5rem" }} onClick={() => props.onUserBackClick()}>
            <MdKeyboardBackspace className="back-icon" style={{ marginLeft: "1rem" }} />
            <Col className="breadcrumb-title">Go Back</Col>
          </Breadcrumb>
        </Col>
        <Col className='tulu-details padding-special'>
          <Col className='tulu-page-header'>
            <Col className='image-box'>
              {!props.isPoeple ?
                <Image src={props.data && props.data.thumbnailUrl ? props.data.thumbnailUrl : '/icons/Lark20220316-060040.png'} className={'page-image' + (props.isPoeple ? ' radius-circle' : '')} />
                :
                <Fragment>
                  {props.data && props.data.thumbnailUrl ?
                    <Image src={props.data.thumbnailUrl} className={'page-image radius-circle'} />
                    :
                    noImageBox(props.data, props.data ? props.data.fullname : "")
                  }
                </Fragment>}

            </Col>
            {!props.isPoeple ?
              <Col className={'details-infos' + (props.isPoeple ? ' mt-4' : '')}>
                <div className='tulu-text-center header-title'>{props.data ? props.data.name : ""}</div>
                <div className='dt-info'>
                  <div className='info-no-wrap'><img className='marker-icon' src={'/icons/620e3cd9935d47ddec9d17e8_map.svg'} />{props.data && props.data.address ? props.data.address.address : ""}</div>
                  <div className='info-no-wrap'><img className='buidling-icon' src={'/icons/620e3cd97e3b161ad92bc36e_building-3.svg'} />{props.data ? props.data.contact_name : ""}</div>
                  <div className='info-no-wrap'><img className='buidling-icon' src={'/icons/620e3cd9ba6120a431ca438f_phone-call-2.svg'} />{props.data && props.data.detail ? props.data.detail.contact_phone : ""}</div>
                </div>
                {relatedData && relatedData.length > 0 ? <div className='dt-info'>
                  <div className='info-no-wrap'><img className='buidling-icon' src={'/icons/users.svg'} />
                    {
                      relatedData && relatedData.map((item, i) => {
                        return (
                          <>
                            <div className="people-item" onClick={() => handleOpenPeople(item.id)}>{item.fullname}</div>
                            {i < relatedData.length ? "" : ","}
                          </>
                        )
                      })
                    }
                  </div>
                </div> :
                  (dataLoad === false ?
                    <div className='dt-info'>
                      <ContentLoader viewBox="0 0 400 10">
                        <rect x="0" y="0" width="100" height="10" />
                      </ContentLoader>
                    </div> : <div></div>) }
              </Col>
              :
              <Col className={'details-infos' + (props.isPoeple ? ' mt-4' : '')}>
                <div className='tulu-text-center header-title'>{props.data.fullname}</div>
                <div className='dt-info'>
                  <div className='info-no-wrap'><img className='marker-icon' src={'/icons/6214ce5c5b872a15e0cc5ef9_envelope.svg'} />{props.data ? <a className='phone' href={'mailto:' + props.data.email}>{props.data.email}</a> : ""}</div>
                  <div className='info-no-wrap'><img className='buidling-icon' src={'/icons/620e3cd9ba6120a431ca438f_phone-call-2.svg'} />{props.data ? <a className='phone' href={'tel:' + props.data.phone}>{props.data.phone}</a> : ""}</div>
                </div>
                {relatedData && relatedData.length > 0 ? <div className='dt-info'>
                  <div className='info-no-wrap'>
                    <img className='buidling-icon' src={'/icons/620e3cd97e3b161ad92bc36e_building-3.svg'} />
                    {
                      relatedData && relatedData.map((item, i) => {
                        return (
                          <>
                            <div className="people-item" onClick={() => handleOpenPlace(item.id)}>{item.name}</div>
                            {i < relatedData.length ? "" : ","}
                          </>
                        )
                      })
                    }
                  </div>
                </div> : 
                (dataLoad === false ?
                    <div className='dt-info'>
                      <ContentLoader viewBox="0 0 400 10">
                        <rect x="0" y="0" width="100" height="10" />
                      </ContentLoader>
                    </div> : <div></div>)}
              </Col>
            }
          </Col>
          <Row>
            <Col style={{ marginTop: "2.5rem" }}>
              <div className="map-size">
                <Map
                  initialViewState={{
                    latitude: props.data && props.data.gps && JSON.parse(props.data.gps).coordinates[1] ? JSON.parse(props.data.gps).coordinates[1] : 40.70250302012432,
                    longitude: props.data && props.data.gps && JSON.parse(props.data.gps).coordinates[0] ? JSON.parse(props.data.gps).coordinates[0] : -74.01385281370332,
                    zoom: 15
                  }}
                  attributionControl={false}
                  style={{ width: "100%", height: "100%" }}
                  mapStyle="mapbox://styles/mapbox/streets-v9"
                  mapboxAccessToken={config.MAPBOX_TOKEN}>
                  <FullscreenControl />
                  <NavigationControl position={'top-right'} showCompass={true} />
                  <Marker
                    longitude={props.data && props.data.gps && JSON.parse(props.data.gps).coordinates[0] ? JSON.parse(props.data.gps).coordinates[0] : -74.01385281370332}
                    latitude={props.data && props.data.gps && JSON.parse(props.data.gps).coordinates[1] ? JSON.parse(props.data.gps).coordinates[1] : -74.01385281370332}
                    color="red" anchor="bottom" >
                    <MapPin onClick={() => {
                      if (props && props.data)
                        setPopupInfo(true);
                    }} />
                  </Marker>

                  {popupInfo && (
                    <Popup
                      anchor="top"
                      longitude={Number(props.data && props.data.gps && JSON.parse(props.data.gps).coordinates[0] ? JSON.parse(props.data.gps).coordinates[0] : -74.01385281370332)}
                      latitude={Number(props.data && props.data.gps && JSON.parse(props.data.gps).coordinates[1] ? JSON.parse(props.data.gps).coordinates[1] : -74.01385281370332)}
                      closeOnClick={true}
                      onClose={() => setPopupInfo(false)}>
                      <div style={{ minWidth: 100, padding: 20 }}>
                        {!props.isPoeple ?
                          <Fragment>{props.data ? props.data.name : ""}</Fragment>
                          :
                          <Fragment>{props.data ? props.data.fullname : ""}</Fragment>}
                        {props.isPoeple ? "Person Name: " : "Place Name: "}{props.accountData ? props.accountData.fullname : ""}
                      </div>
                    </Popup>
                  )}
                </Map>
              </div>
            </Col>
          </Row>
          <Row>
            <Col >
              <Col className='page-header'>Recent Activity</Col>
              <RecentActivity type={!props.isPoeple ? 1 : 2} id={props.data.id} />
            </Col>
          </Row>
        </Col>
      </Container>
    </Fragment>
  );
};

export default Details;