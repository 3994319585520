import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Form, Badge } from 'react-bootstrap';
import { BsArrowsAngleExpand } from "react-icons/bs";
import {FiLink} from 'react-icons/fi';
import {FaRedo} from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import lottieWeb from 'lottie-web';
import Swal from 'sweetalert2';
import VideoJS from './Video';
import { POST, GET } from '../utils/api';
import config from '../../config/aws.json';

const Media = ({ mediaData, keyIndex }) => {
  let history = useHistory();
  const [expand, setExpand] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [reqLoading, setReqLoading] = useState(false);

  useEffect(() => {
    lottieWeb.loadAnimation({
      container: document.getElementById('live_' + keyIndex),
      path: '/documents/Live.json',
      renderer: 'svg',
      loop: true,
      autoplay: true,
      name: "Demo Animation",
    });

    if (mediaData && mediaData.detail && mediaData.detail.thumbnail)
      setThumbnail(mediaData.detail.thumbnail);
    else
      GET(config.AWS_FUNCTION.MEDIA, config.AWS_API.GET_VIDEO_SNAPSHOT + '?id=' + mediaData.id).then(response => {
        if (!response || !response.data || !response.data.detail) return;
        setThumbnail(response.data.detail.thumbnail);
      }).catch((_) => { });
  }, []);

  const openVideoPreview = (MediaData) => {
    if (!MediaData) return;
    history.push({
      pathname: '/video-preview/' + MediaData.id,
      data: MediaData,
      withId: MediaData.id
    })
  }

  const openLocation = (PlaceId) => {
    if (!PlaceId) return;

    history.push({
      pathname: `/places/${PlaceId}`
    });
  }

  const openTechnician = (TechnicianId) => {
    if (!TechnicianId) return;
    history.push({
      pathname: `/people/${TechnicianId}`
    });
  }

  const handleWebhookPost = (media) => {
    if (!media) return;
    setReqLoading(true);
    const sendData = {
      body: {
        id: media.id,
        people_id: media.people_id,
        detail: media.detail,
      }
    }
    POST(config.AWS_FUNCTION.MEDIA, '/media/sendRequest', sendData).then((res) => {
      mediaData.detail.status = 1;
    }).catch((err) => {
      Swal.fire('Webhook Error', 'Oops, Error occurred while sending legacy dashboard webhook.', 'error');
    }).finally(() => {
      setReqLoading(false);
    });
  }

  return (
    mediaData ?
      <Col className="mb-2">
        <Card className="video-card-video-wrap">
          <div style={{ position: 'relative' }}>
            {reqLoading === false && mediaData.detail && mediaData.detail.status && mediaData.detail.status == 1 &&
              <Badge pill bg="success" className="success-badge"><FiLink className="success-icon" />Success</Badge>}
            {reqLoading === false && mediaData.detail && mediaData.detail.status && mediaData.detail.status == 2 &&
              <>
              <Badge pill bg="danger" className="failed-badge" onClick={() => handleWebhookPost(mediaData)}><FiLink className="failed-icon" />Failed</Badge>
              <Badge pill bg="danger" className="redo-badge" onClick={() => handleWebhookPost(mediaData)}><FaRedo className="redo-icon" /></Badge>
              </>}
            {reqLoading === true && <Badge pill bg="primary" className="failed-badge"><FiLink className="failed-icon" />Sending...</Badge> }
            <Card.Img variant="top" src={thumbnail || '/images/video-not-working.png'} className="card-img" height="180px" onMouseEnter={() => setExpand(true)} onMouseLeave={() => setExpand(false)} onClick={() => openVideoPreview(mediaData)}/>
            {/* {mediaData && !thumbnail &&
            <div onMouseEnter={() => setExpand(true)} onClick={() => openVideoPreview(mediaData)} onMouseLeave={() => setExpand(false)}>
              <VideoJS streamData={mediaData} key={keyIndex} keyIndex={keyIndex} type="thumbnail" className="card-img" />
            </div>} */}
            {expand &&
              <Col md={12} sm={12} xs={12} className="card-img-expand" onMouseEnter={() => setExpand(true)} onMouseLeave={() => setExpand(false)} onClick={() => openVideoPreview(mediaData)}>
                {mediaData.status < 3 ?
                  <>
                    <BsArrowsAngleExpand className="card-expand-icon" />
                    <Form.Label className="card-expand-label">Expand</Form.Label>
                  </> :
                  <Form.Label className="card-expand-label">View Record</Form.Label>}
              </Col>
            }
          </div>
          <Card.Body className="video-card-time-wrap" onClick={() => openVideoPreview(mediaData)}>
          <Row className="item-row">
              <div className="video-card-duration" style={{ width: 'fit-content'}}>
                <Form.Label className="record">
                  {moment(mediaData.finish_time).diff(mediaData.start_time, "minutes") > 0 ? moment(mediaData.finish_time).diff(mediaData.start_time, "minutes") + " min" : moment(mediaData.finish_time).diff(mediaData.start_time, "seconds") + " sec"}
                </Form.Label>
              </div>
              <div className="video-card-times" style={{ width: 'fit-content',  maxWidth: '66%'}}>
                <Form.Label className="time-label">
                  {moment(mediaData.start_time).format('hh:mm A') + " - " + moment(mediaData.finish_time).format('hh:mm A')}
                </Form.Label>
              </div>
            </Row>
            {/* <Row className="item-row">
              <Col md="4" sm="4" xs="4" className="video-card-duration">
                <Form.Label className="record">
                  {moment(mediaData.finish_time).diff(mediaData.start_time, "minutes") > 0 ? moment(mediaData.finish_time).diff(mediaData.start_time, "minutes") + " min" : moment(mediaData.finish_time).diff(mediaData.start_time, "seconds") + " sec"}
                </Form.Label>
              </Col>
              <Col md="8" sm="8" xs="8" className="video-card-times">
                <Form.Label className="time-label">
                  {moment(mediaData.start_time).format('hh:mm A') + " - " + moment(mediaData.finish_time).format('hh:mm A')}
                </Form.Label>
              </Col>
            </Row> */}
          </Card.Body>
          <Card.Footer className="Video-card-footer">
            <Form.Label className="video-card-name-wrap video-card-name-link" onClick={() => openLocation(mediaData.place_id)}>{mediaData.place_name && mediaData.place_name.length > 0 ? mediaData.place_name : "Unknown"}</Form.Label>
            <Form.Label className="video-card-person" onClick={() => openTechnician(mediaData.people_id)}>{mediaData.people_name}</Form.Label>
            <Form.Label className="video-card-date">{moment(mediaData.start_time).format('MMM DD, dddd')}</Form.Label>
          </Card.Footer>
        </Card>
      </Col> : <></>
  )
}

export default Media
