/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "streamPlacesREST",
            "endpoint": "https://a5keml9eo0.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "streamDevicesREST",
            "endpoint": "https://9ry97qfkvg.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "streamClientsREST",
            "endpoint": "https://r7krr9zdue.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "streamActivateREST",
            "endpoint": "https://olks6pr69d.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "streamPeopleREST",
            "endpoint": "https://dofgwzmpid.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "streamWebHookREST",
            "endpoint": "https://mrqeoy72w5.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "streamMediaREST",
            "endpoint": "https://yg01amp7zc.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "streamUsersREST",
            "endpoint": "https://5o5maft53l.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "streamPublicREST",
            "endpoint": "https://m2m79s5yri.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:1e434068-7065-4a89-aa1a-d67f1863043b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_tWPmCe87d",
    "aws_user_pools_web_client_id": "5ne1qkbkk028c4860o3lauq73d",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "streammedias125000-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
