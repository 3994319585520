import React, { useEffect, useState, useRef, Fragment } from 'react'
import { Row, Col, Stack, Navbar, Nav, Breadcrumb, Form, Modal, Button, Dropdown, FormControl } from 'react-bootstrap';
import { HiMenuAlt4 } from 'react-icons/hi';
import { AiOutlineDelete } from 'react-icons/ai';
import { BsArrowLeft } from 'react-icons/bs';
import { RiShareBoxLine } from 'react-icons/ri';
import { useParams, useHistory } from 'react-router-dom';
import Map, { FullscreenControl, Marker, NavigationControl, Source, Layer } from 'react-map-gl';
import ReactTooltip from "react-tooltip";
import Swal from 'sweetalert2';
import { Animated } from "react-animated-css";
import Comment from '../../components/Comment';
import VideoJS from '../../components/Video';
import TimeLine from '../../components/TimeLine';
import "../../styles/shared/_videoPreview.scss";
import { timeDiff } from '../../utils/utilities';
import appConfig from '../../../config/app.json';
import config from '../../../config/aws.json';
import { POST, GET, DELETE } from '../../utils/api';

const VideoPreview = (props) => {
  let history = useHistory();
  const { id, withId } = useParams();
  const ref = useRef(null);
  const playerRef = React.useRef(null);
  const tooltip = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState('');
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [cssLoaded, setCssLoaded] = useState(false);
  const [streamData, setStreamData] = useState(null);
  const [durationTime, setDurationTime] = useState('00:00');
  const [videoWidth, setVideoWidth] = useState(0);
  const [selectedLocName, setSelectedLocName] = useState('');
  const [selectedLocId, setSelectedLocId] = useState();
  const [places, setPlaces] = useState();
  const [locEdit, setLocEdit] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState();
  const [commentData, setCommentData] = useState();
  const [gpsLogs, setGPSLogs] = useState([]);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [timeChanged, setTimeChanged] = useState();
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(true);
  const [deleted, setDeleted] = useState(false);
  const [shareURL, setShareURL] = useState();
  const [shareShow, setShareShow] = useState(false);
  const [publicRoute, setPublicRoute] = useState(false);
  const [publicId, setPublicId] = useState();
  const [showTooltip, setShowToolTip] = useState(false);

  useEffect(() => {
    document.title = 'Video Preview';
  });

  useEffect(() => {
    let loadingDiv = document.getElementById('main-live-loading');
    if (loadingDiv) loadingDiv.style = "display:block";
    if (cssLoaded === false) {
      setCssLoaded(true);
      import('../../styles/shared/_videoPreview.scss');
    }

    if (id) {
      setPublicId(id);
      setPublicRoute(true);
      fetchMediaData(id);
      isLoaded();
      return;
    }

    if (withId) {
      setPublicId(withId);
      setPublicRoute(false);
      fetchMediaById(withId);
      isLoaded();
      return;
    }

    if (props.location.data) {
      setStreamData(props.location.data);
      setSelectedLocName(props.location.data.place_name);
      props.location.data.type === appConfig.MEDIA_TYPE.STREAM && setDurationTime(timeDiff(props.location.data.detail.time_data.start_date));
      fetchData(props.location.data);
      isLoaded();
    } else {
      isLoaded();
      history.push("/home")
    }
  }, []);

  const isLoaded = () => {
    let loadingDiv = document.getElementById('main-live-loading');
    if (loadingDiv) loadingDiv.style = "display:none";
  }

  useEffect(() => {
    setVideoWidth(ref.current.offsetWidth);
  });

  const fetchMediaData = (id) => {
    let url = `/public/media?id=${id}`;
    return GET(config.AWS_FUNCTION.PUBLIC, url).then((res) => {
      if (res && res.stream) {
        setStreamData(res.stream);
        setSelectedLocName(res.stream.place_name);
        fetchData(res.stream);
      } else {
        history.push("/login");
      }
    }).catch((err) => {
      Swal.fire('Fetch Data Error', 'Oops, Error occurred while fetching data.', 'error');
      history.push("/login")
    });
  }

  const fetchMediaById = (id) => {
    let url = `/media/get-data?id=${id}`;
    return GET(config.AWS_FUNCTION.MEDIA, url).then((res) => {
      if (res && res.stream) {
        setStreamData(res.stream);
        setSelectedLocName(res.stream.place_name);
        fetchData(res.stream);
      } else {
       history.push("/login");
      }
    }).catch((err) => {
      Swal.fire('Fetch Data Error', 'Oops, Error occurred while fetching data.', 'error');
      history.push("/login")
    });
  }

  const fetchData = (data) => {
    let pvAccountData;
    try {
      pvAccountData = JSON.parse(localStorage.getItem("accountData"));
      setAccountData(pvAccountData);
    } catch (err) { }

    if (!pvAccountData || !pvAccountData.id) return;
    setLoading(true);

    Promise.all([
      fetchPlaces(pvAccountData.id),
      fetchGPSlogs(data)
    ]).catch((err) => {
      console.log(err)
      Swal.fire('Fetch Data Error', 'Oops, Error occurred while fetching data.', 'error');
    }).finally(() => {
      setLoading(false);
    });

  }

  const fetchPlaces = (accountid)=>{
    let url = `/places?owner=${accountid}`;

    return GET('streamPlacesREST', url).then(response => {
      if (response && response.result) {
        const result = response.result;
        let final = [];
        result.forEach(res => {
          final.push(res);
        });
        setPlaces(final);
      }
    })
  }

  const fetchGPSlogs = (data) => {
     const params = `/${data.id}`;

    return GET(config.AWS_FUNCTION.GET_MEDIA_EVENTS, config.AWS_API.GET_MEDIA_GPS_LOGS + params).then(response => {
      if (response && response.data) {
        const result = response.data;
        let final = [];
        result.forEach(res => {
          res.gps = JSON.parse(res.gps);
          final.push(res);
        });
        setGPSLogs(final);
        if (final && final[0]) {
          setLat(final[0].gps.coordinates[1]);
          setLong(final[0].gps.coordinates[0]);
          getGPSCoordinates();
        }
      }
    })
  }

  const handleDeleteClick = () => {
    setDeleteLoading(true);
    setDeleted(false);
    let streamId = streamData && streamData.id;
    if (!streamId) return;
    let url = `/media?mediaId=${streamId}`;

    DELETE('streamMediaREST', url)
      .then(response => {
        if (response && response.result)
          setDeleted(true);
        Swal.fire('Media', 'Delete Successfully', 'success');
        setTimeout(() => {
          history.push({
            pathname: `/`
          });
        }, 1500);
      }).catch((err) => {
        console.log(err)
        Swal.fire('Data Deleting Error', 'Error in data deleting', 'error');
      }).finally(() => {
        setDeleteLoading(false);
        setModalShow(false);
      });
  };

  const openLocation = (PlaceId) => {
    if (!PlaceId) return;

    history.push({
      pathname: `/places/${PlaceId}`
    });
  }

  const openTechnician = (TechnicianId) => {
    if (!TechnicianId) return;
    history.push({pathname: `/people/${TechnicianId}`});
  }

  const goToPreviousPath = (e) => {
    history.push('/home');
  }

  const handleLocationChange = (placeId, placeName, place) => {
    setSelectedLocName(placeName);
    setSelectedLocId(placeId);
    setSelectedPlace(place)
  }

  const handleSubmitLocation = () => {
    if (!selectedLocId) return;
    setLoading(true);
    if (streamData.type === appConfig.MEDIA_TYPE.STREAM) {
      const params = {
        body: {
          deviceId: streamData.id,
          placeId: selectedLocId
        }
      }
      let newStreamData = streamData;
      let lastStreamData = streamData;
      newStreamData.place_id = selectedLocId;
      newStreamData.place_name = selectedLocName;
      newStreamData.address = selectedPlace.address;
      setStreamData(newStreamData);
      setLoading(false);
      setLocEdit(false);
      POST(config.AWS_FUNCTION.GET_MEDIA_EVENTS, '/devices/placeUpdate', params)
        .then((response) => {
          let newStreamData = streamData;
          newStreamData.place_id = selectedLocId;
          newStreamData.place_name = selectedLocName;
          newStreamData.address = selectedPlace.address;
          setStreamData([...newStreamData]);
        }).catch((err) => {
          Swal.fire('Data Loading Error', 'Error in data loading', 'error');
          setStreamData([...lastStreamData]);
        }).finally(() => {
          setLoading(false);
          setLocEdit(false);
        });
    }

    if (streamData.type === appConfig.MEDIA_TYPE.RECORD) {
      const params = {
        body: {
          mediaId: streamData.id,
          placeId: selectedLocId
        }
      }
      let newStreamData = streamData;
      let lastStreamData = streamData;
      newStreamData.place_id = selectedLocId;
      newStreamData.place_name = selectedLocName;
      newStreamData.address = selectedPlace.address;
      setStreamData(newStreamData);
      setLoading(false);
      setLocEdit(false);
      POST(config.AWS_FUNCTION.PLACE_MEDIA_UPDATE, '/places/mediaUpdate', params)
        .then((response) => {
          let newStreamData = streamData;
          newStreamData.place_id = selectedLocId;
          newStreamData.place_name = selectedLocName;
          newStreamData.address = selectedPlace.address;
          setStreamData([...newStreamData]);
          setLocEdit(false);
        }).catch((err) => {
          Swal.fire('Data Loading Error', 'Error in data loading', 'error');
          setStreamData([...lastStreamData]);
        }).finally(() => {
          setLoading(false);
          setLocEdit(false);
        });
    }

  }

  const handleCommentLoad=(data)=>{
    const newData = data;
    setCommentData([...newData]);
  }

  const getGPSCoordinates = () => {
    if (!gpsLogs) return;

    let coordinates = [];

    gpsLogs.forEach((log) => {
      coordinates.push(log.gps.coordinates);
    });
    return {
      type: "Feature",
      properties: {},
      geometry: {
        type: "LineString",
        coordinates: coordinates
      }
    };
  }

  const handleTimeChange = (time) => {
    if (!time) return;
    setTimeChanged(time);
  }

  const handleSideMenuClick = (e) => {
    setSideMenuOpen(!sideMenuOpen);
  }

  const shareMedia = (id) => {
    setShareShow(true);
    if (!id) return;
    var clientId = localStorage.getItem('owner');
    const params = {
      body: {
        mediaId: id,
        clientId: clientId
      }
    };

    POST(config.AWS_FUNCTION.MEDIA, "/media/short-url", params)
      .then((response) => {
        if (response && response.url) {
          setShareURL(window.location.origin + "/media/" + response.url);
        } else {
          setShareShow(false);
        }
      })
      .catch((err) => {
        Swal.fire("Media Sharing Error", "Error in media sharing", "error");
        console.log("err: ", err);
        setShareShow(false);
      });
  }

  const handleCopy = (shareURL) => {
    navigator.clipboard.writeText(shareURL);
    setShowToolTip(true);
  }

  return (
    <Fragment>
      <Animated className="navbar-wrap video-navbar-wrap" animationIn="slideInLeft" animationOut="slideOutLeft" animationInDuration={350} animationOutDuration={350} isVisible={sideMenuOpen}>
        {sideMenuOpen === true ?
          <Fragment>
            <div aria-current="page" className={"brand w-nav-brand w--current image-link brand-dark"}>
              <HiMenuAlt4 className="page-header-back-icon" onClick={handleSideMenuClick} />
              <img src="/images/tulu_web_logo.svg" alt="" className="image-2" />
            </div>
            <Nav className="me-auto nav-menu w-nav-menu">
              <a onClick={() => history.push("/home")} className={'d-flex align-items-center nav-link w-nav-link'}>
                <div className='nav-link-icon-div'><svg className="nav-link-icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 17.151"><path d="M10.2,20.564a.857.857,0,0,1-.583-.231,7.951,7.951,0,0,1,0-11.533.857.857,0,0,1,1.165,1.251,6.255,6.255,0,0,0,0,9.031.857.857,0,0,1-.583,1.482Zm-3.427,2.3a.857.857,0,0,0,0-1.208,9.793,9.793,0,0,1,0-14.172A.87.87,0,1,0,5.563,6.229a11.516,11.516,0,0,0,0,16.674A.857.857,0,0,0,6.78,22.86Zm11.593-2.528a7.951,7.951,0,0,0,0-11.533.858.858,0,1,0-1.174,1.251,6.229,6.229,0,0,1,0,9.031.858.858,0,0,0,1.174,1.251Zm4.044,2.57a11.49,11.49,0,0,0,0-16.674A.857.857,0,0,0,21.243,7.48a9.793,9.793,0,0,1,0,14.172A.855.855,0,1,0,22.408,22.9Zm-6.7-8.337a1.714,1.714,0,1,0-1.714,1.714A1.714,1.714,0,0,0,15.708,14.566Z" transform="translate(-1.992 -5.985)"></path></svg></div>
                Home
              </a>
              <a onClick={() => history.push("/places")} className={'d-flex align-items-center nav-link w-nav-link'}>
                <div className='nav-link-icon-div'><svg className="nav-link-icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 19.86 18.048"><g transform="translate(4.8 -8)"><path d="M31.889,181.207H34.1a.91.91,0,0,0,.922-.9v-3.774h4.363v3.774a.91.91,0,0,0,.922.9H42.52a2.935,2.935,0,0,0,1.124-.221,2.884,2.884,0,0,0,.918-.6,2.8,2.8,0,0,0,.619-.895,2.737,2.737,0,0,0,.227-1.1V173H44v5.391a1.4,1.4,0,0,1-.115.559,1.435,1.435,0,0,1-.317.457,1.474,1.474,0,0,1-.469.309,1.5,1.5,0,0,1-.574.112H40.8v-3.295a1.4,1.4,0,0,0-1.413-1.378H35.023a1.4,1.4,0,0,0-1.413,1.378v3.295H31.889a1.5,1.5,0,0,1-.574-.112,1.473,1.473,0,0,1-.469-.309,1.433,1.433,0,0,1-.317-.457,1.4,1.4,0,0,1-.115-.559V173H29v5.391a2.737,2.737,0,0,0,.227,1.1,2.8,2.8,0,0,0,.619.895,2.884,2.884,0,0,0,.918.6A2.935,2.935,0,0,0,31.889,181.207Z" transform="translate(-32.075 -155.159)" fillRule="evenodd"></path><path d="M2.109,17.332l-1.8,4.543a1.358,1.358,0,0,0-.105.649,3.121,3.121,0,0,0,3.137,2.72h0a3.2,3.2,0,0,0,2.262-.925,3.2,3.2,0,0,0,2.262.925h0a3.2,3.2,0,0,0,2.262-.925,3.2,3.2,0,0,0,2.262.925h0a3.2,3.2,0,0,0,2.262-.925,3.2,3.2,0,0,0,2.262.925h0a3.12,3.12,0,0,0,3.138-2.73,1.358,1.358,0,0,0-.1-.631l-1.743-4.551A3.669,3.669,0,0,0,14.774,15H5.546A3.669,3.669,0,0,0,2.109,17.332Zm12.666-.954H5.546a2.263,2.263,0,0,0-2.12,1.438L1.732,21.861a1.141,1.141,0,0,0-.1.679,1.737,1.737,0,0,0,1.71,1.339h0a1.772,1.772,0,0,0,1.19-.453,1.892,1.892,0,0,1,.782-.485,1.214,1.214,0,0,1,.58,0,1.892,1.892,0,0,1,.782.485,1.772,1.772,0,0,0,1.19.453h0a1.772,1.772,0,0,0,1.19-.453,1.893,1.893,0,0,1,.783-.485,1.215,1.215,0,0,1,.579,0,1.893,1.893,0,0,1,.783.485,1.772,1.772,0,0,0,1.19.453h0a1.772,1.772,0,0,0,1.19-.453,1.892,1.892,0,0,1,.783-.485,1.213,1.213,0,0,1,.579,0,1.892,1.892,0,0,1,.783.485,1.772,1.772,0,0,0,1.19.453h0a1.736,1.736,0,0,0,1.713-1.35,1.141,1.141,0,0,0-.094-.66l-1.641-4.054A2.262,2.262,0,0,0,14.774,16.378Z" transform="translate(-5 -7)" fillRule="evenodd"></path></g></svg></div>
                Places</a>
              <a onClick={() => history.push("/people")} className={'d-flex align-items-center nav-link w-nav-link'}>
                <div className='nav-link-icon-div'><svg className="nav-link-icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 17.743 16.586"><g transform="translate(0 -11)"><path d="M191.046,12.256a2.261,2.261,0,0,0-1.343.439,5.211,5.211,0,0,0-.778-.986,3.526,3.526,0,1,1,.917,6.133,5.154,5.154,0,0,0,.456-1.171,2.271,2.271,0,1,0,.748-4.416Z" transform="translate(-178.611)"></path><path d="M166.716,156.347l.141.007a5.2,5.2,0,0,1,.983.16h.02c.038,0,.089,0,.274,0H171.9c.185,0,.236,0,.274,0a2.02,2.02,0,0,1,1.929,1.929c0,.038,0,.089,0,.274,0,.052,0,.084,0,.108s0,.029,0,.029a.655.655,0,0,1-.625.626h-2.2a5.164,5.164,0,0,1,.361,1.256h1.7c.1,0,.15,0,.192,0a1.911,1.911,0,0,0,1.824-1.824c0-.042,0-.092,0-.192,0-.171,0-.257,0-.329a3.275,3.275,0,0,0-3.127-3.127c-.072,0-.158,0-.329,0h-3.781A5.207,5.207,0,0,1,166.716,156.347Z" transform="translate(-157.615 -136.38)"></path><path d="M8.535,191.427h-3.8c-.36,0-.479,0-.571.007a3.112,3.112,0,0,0-2.9,2.9c-.006.092-.007.211-.007.571,0,.077,0,.125,0,.161s0,.043,0,.043a.655.655,0,0,0,.611.611H11.4a.655.655,0,0,0,.611-.611s0-.008,0-.043,0-.084,0-.161c0-.36,0-.479-.007-.571a3.112,3.112,0,0,0-2.9-2.9C9.014,191.427,8.9,191.427,8.535,191.427ZM.009,194.251C0,194.395,0,194.565,0,194.9c0,.149,0,.223,0,.286a1.911,1.911,0,0,0,1.781,1.781c.063,0,.137,0,.286,0H11.2c.149,0,.223,0,.286,0a1.911,1.911,0,0,0,1.781-1.781c0-.063,0-.137,0-.286,0-.34,0-.51-.009-.653a4.367,4.367,0,0,0-4.071-4.071c-.144-.009-.313-.009-.653-.009h-3.8c-.34,0-.51,0-.653.009A4.367,4.367,0,0,0,.009,194.251Z" transform="translate(0 -169.39)" fillRule="evenodd"></path><path d="M46.447,18.505a3.125,3.125,0,1,0-3.125-3.125A3.125,3.125,0,0,0,46.447,18.505Zm0,1.256a4.38,4.38,0,1,0-4.38-4.38A4.38,4.38,0,0,0,46.447,19.761Z" transform="translate(-39.77)" fillRule="evenodd"></path></g></svg></div>
                People</a>
              <div className='tulu-logout'>
                <a onClick={async () => {
                  history.push("/manage");
                }} className={'d-flex align-items-center nav-link w-nav-link'}>
                  Manage
                </a>
                <a onClick={async () => handleLogout()} className={'d-flex align-items-center nav-link w-nav-link'}>
                  Log Out
                </a>
              </div>
            </Nav>
          </Fragment> : null}
      </Animated>
      <Row className="page-header-wrap-dark">
        <Breadcrumb className="page-header video-page-header">
          {publicRoute === false ?
            <>
              <div className="action-area">
                <HiMenuAlt4 className="page-header-back-icon" onClick={handleSideMenuClick} />
                <div style={{ width: '4rem', marginRight: '1rem' }}>
                  <img src="/images/tulu_web_logo.svg" />
                </div>
              </div>
              <AiOutlineDelete className="page-header-delete-icon" onClick={() => setModalShow(true)} />
              {shareShow === false ?
                <>
                  <RiShareBoxLine data-tip="Create share link" ref={tooltip} className="page-header-share-icon" onClick={() => shareMedia(streamData.id)} />
                  <ReactTooltip place="left" type="dark" effect="solid" />
                </>
                :
                (shareURL ? <div style={{ display: 'flex' }} class="function_wrap">
                  <div onClick={() => handleCopy(shareURL)} data-tip data-for="copyTip" class="share-url">{shareURL}</div>
                  <ReactTooltip id="copyTip" place="left" type="dark" effect="solid">
                    {showTooltip === true ? "Copied!" : "Copy"}
                  </ReactTooltip>
                  <div class="share-image">
                    <img src="/images/attach.svg" />
                  </div>
                </div> :
                  <div style={{ display: 'flex' }} class="function_wrap">
                    <div class="share-url">Generating a link...</div>
                    <img src="/images/ajax-loader.gif" className="loading-animation" />
                  </div>)}
            </> :
            <div className="action-area" style={{ position: 'relative', left: 0 }}>
              <div style={{ width: '4rem', marginRight: '1rem' }}>
                <img src="/images/tulu_web_logo.svg" />
              </div>
            </div>
          }
        </Breadcrumb>
      </Row>
      <Row className="video-player-wrap">
        <Col lg={9} md={9} sm={12} xs={12} className="video-player" ref={ref}>
          {publicRoute === false && <div className="back-area">
            <BsArrowLeft className="page-header-back-icon back-icon" onClick={goToPreviousPath} />
            <span className="back-title" onClick={goToPreviousPath}>Back</span>
          </div>}
          {streamData && <VideoJS streamData={streamData} key={streamData.id} keyIndex={streamData.id} videoWidth={videoWidth} commentData={commentData} commentTimeChange={timeChanged} />}
        </Col>
        <Col lg={3} md={3} sm={12} xs={12} className="video-player-library-area">
          {streamData && streamData.id && <Comment streamData={streamData} handleTimeChange={(time) => handleTimeChange(time)} handleComment={(data) => handleCommentLoad(data)} publicRoute={publicRoute} />}
        </Col>
      </Row>
      {publicRoute === false && <Row>
        <Col md={12} lg={12} sm={12} xs={12}>
          <div className="header-back darkmode">
            <div className="text-block-4">Location information</div>
          </div>
          <Row>
            <Stack className="video-player-location-wrap">
              <Stack className="video-player-location-info">
                <div href="location-page.html" className="video-player-user-wrap video-player-user-wrap-address">
                  <div className="video-player-location-image">
                    <img src="/images/video-preview-thumbnail.png" loading="lazy" alt="" className="location-list-image" />
                  </div>
                  <div className="video-player-user-info-wrap">
                    <div className="location-indicator">
                      {locEdit === false ?
                        <div className="loc-action-area">
                          <h4 className="location-list-name" onClick={() => openLocation(streamData.place_id)}>{streamData && streamData.place_name && streamData.place_name.length > 0 ? streamData.place_name : "Unknown"}</h4>
                          <div className="loc_item-action" onClick={() => setLocEdit(true)}>
                            <div className="html-embed-9 w-embed" >
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.762 18.762">
                                <g transform="translate(0 0)">
                                  <g transform="translate(0 3.005)">
                                    <path d="M10.72,90.924a1.659,1.659,0,0,1-.645.4L7.744,92.1a1.645,1.645,0,0,1-.521.085h0a1.649,1.649,0,0,1-1.564-2.171l.777-2.332a1.657,1.657,0,0,1,.4-.645L11.871,82H2.016A2.018,2.018,0,0,0,0,84.015V95.742a2.018,2.018,0,0,0,2.015,2.015H13.742a2.018,2.018,0,0,0,2.015-2.015V85.887Z" transform="translate(0 -82)" fill="currentColor"></path>
                                  </g>
                                  <path d="M183.024,60.152a.549.549,0,0,0-.133.215l-.777,2.332a.55.55,0,0,0,.7.7l2.332-.777a.549.549,0,0,0,.215-.133l6.867-6.867-2.332-2.332Z" transform="translate(-175.413 -51.333)" fill="currentColor"></path>
                                  <path d="M418.491.268a.916.916,0,0,0-1.3,0l-.907.907,2.332,2.332.907-.907a.916.916,0,0,0,0-1.3Z" transform="translate(-401.034 0)" fill="currentColor"></path>
                                </g>
                              </svg>
                            </div>
                            <div className="loc-edit"></div>
                          </div>
                        </div> : <div className="loc-action-area">
                          <Dropdown className="d-inline" variant="dark">
                            <Dropdown.Toggle className="drop-down-toggle" id="dropdown-autoclose-true">
                              {selectedLocName && selectedLocName.length > 0 ? selectedLocName : "Select location"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {places && places.map((place, index) => {
                                return (
                                  <Dropdown.Item key={index} onClick={() => handleLocationChange(place.id, place.name, place)} className="drop-down-item">{place.name}</Dropdown.Item>
                                )
                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                          <div className="div-block-31" style={{ marginLeft: '1rem' }}>
                            <button disabled={isLoading} className="simple-link-box dark-mode margin_right_1em w-inline-block" style={{ fontWeight: 600, color: '#fff' }} onClick={() => isLoading === true ? null : handleSubmitLocation()}>
                              Confirm
                            </button>
                            <button className="simple-link-box dark-mode margin_right_1em w-inline-block" onClick={() => setLocEdit(false)}>
                              <div>Cancel</div>
                            </button>
                          </div>
                        </div>}
                    </div>
                    <div className="video-preview-location-info">{streamData && streamData.address && streamData.address.address && streamData.address.address.length > 0 ? streamData.address.address : ""}</div>
                  </div>
                </div>
              </Stack>
            </Stack>
            <Stack className="video-player-map">
              <div className="div-block-8">
                <div className="html-embed-6 w-embed w-iframe">
                  {(lat && long) ?
                    <Map
                      id={1}
                      key={"map-view_new"}
                      initialViewState={{
                        latitude: lat,
                        longitude: long,
                        zoom: 16
                      }}
                      attributionControl={false}
                      style={{ width: "100%", height: "20rem" }}
                      mapStyle="mapbox://styles/mapbox/dark-v9"
                      mapboxAccessToken={appConfig.GOOGLE_MAP.KEY}
                    >
                      <FullscreenControl />
                      <Source id="polylineLayer" type="geojson" data={getGPSCoordinates()}>
                        <Layer
                          strokeWidth={4}
                          type="line"
                          paint={{
                            "line-color": "#007eff",
                            "line-width": 4
                          }}
                          layout={{
                            "line-join": "round",
                            "line-cap": "round"
                          }}
                        />
                      </Source>
                      {/* <Marker longitude={long} latitude={lat} color="red" anchor="top" >
                      </Marker> */}
                    </Map> :
                    ((streamData) && <Map
                      key={"map-view_" + lat ? 1 : null}
                      initialViewState={{
                        latitude: streamData.address.lat,
                        longitude: streamData.address.long,
                        zoom: 10
                      }}
                      attributionControl={false}
                      style={{ width: "100%", height: "20rem" }}
                      mapStyle="mapbox://styles/mapbox/dark-v9"
                      mapboxAccessToken={appConfig.GOOGLE_MAP.KEY}
                    >
                      <FullscreenControl />
                      <Marker longitude={streamData.address.long} latitude={streamData.address.lat} color="red" anchor="top" >
                      </Marker>
                    </Map>)}
                </div>
              </div>
            </Stack>
          </Row>
        </Col>
      </Row>}
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        dialogClassName="activate-content-modal">
        <Modal.Header>
          <Modal.Title className="activate-content-modal-title" id="contained-modal-title-vcenter">
            <AiOutlineDelete className="page-modal-delete-icon" />
            Delete Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.</p>
          {deleted === true ?
            <div class="w-form-done" style={{ display: 'block' }}>
              <div>Thank you! Your submission has been received!</div>
            </div> :
            <div className="pop-up_confirm w-form">
              <div className="pop-up_delete">
                <Form.Label for="name">Confirm by typing <span className="text-span-10">DELETE</span> below.</Form.Label>
                <Form.Control type="text"
                  className="w-input activate-content-pinCode"
                  onChange={(e) => setDeleteConfirm(e.target.value)}
                  value={deleteConfirm}
                />
                <Button
                  variant="danger"
                  onClick={() => !isLoading ? handleDeleteClick() : null}
                  className="delete-button"
                  disabled={((deleteConfirm === "DELETE") ? false : true)}
                >
                  {deleteLoading === false ? "Delete Forever" : "Deleting..."}
                </Button>
              </div>
            </div>}
        </Modal.Body>
        <Modal.Footer className="text-center">
          <Button className="activate-content-modal-close" onClick={() => setModalShow(false)} variant="light">Close</Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
}

export default VideoPreview;
