import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2';

const ForgetPassword = () => {
    let history = useHistory();
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [invitationLoading, setInvitationLoading] = useState(false);
    const [email, setEmail] = useState('');
    useEffect(() => {
        document.title = 'ForgetPassword';
    }, []);

    
    const onSubmitClick = async () => {
        if (isEmailSent || invitationLoading) return;

        if (!email)
            return Swal.fire('Failed to reset', 'Please enter your username.', 'error');

        try {
            setInvitationLoading(true);
            await Auth.forgotPassword(email);
            setInvitationLoading(false);
            Swal.fire({
                title: 'Forget password request sent',
                text: "Please, Check your email!",
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK!'
            }).then(() => {
                history.push('/reset-password/' + email);
            })
        } catch (err) {
            setInvitationLoading(false);
            Swal.fire('Failed to reset', 'Oops, something went wrong! Please, try again!', 'error');
        }
    }

    return (
        <Container fluid className='tulu-login-page'>
            <Row>
                <Col md={12} className='login-page'>
                    <div className='custom-navbar'>
                        <div className='top-nav-wrap'>
                            <a className='w-nav-brand' href='/'>
                                <img src='/images/620eb3644f56c0aabe694e09_Tulu_logo.svg' className='image-brand' />
                            </a>
                        </div>
                    </div>

                    <div className='login-body'>
                        <div className='body-box'>
                            <div className='title'>Reset your password</div>
                            {!isEmailSent ? 
                                <Form>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Control type="email" placeholder="Username" className='tulu-input' 
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)} />
                                    </Form.Group>
                                    <Button variant="primary" type="button" className='login-btn' disabled={invitationLoading}
                                    onClick={onSubmitClick}>
                                        {invitationLoading ? 'Waiting...' : 'Send instructions'}
                                    </Button>
                                </Form>
                                :
                                <div className='green-message'>We've sent you an email which you can use to change your password. Check your spam folder if you haven't received it after a few minutes.</div>
                            }
                            
                            <div className='forget-password-link'  onClick={() => history.push("/login")}>Back to Login page.</div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default ForgetPassword;